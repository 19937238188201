.mejs__time-marker, .mejs__time-marker:hover {
  background-color: theme(colors.vidgreen) !important;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  color: theme(colors.white);
  display: inline-block;
  font-size: theme(fontSize.2xs);
  font-weight: bold;
  height: 13px;
  padding-bottom: 0px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  top: -2px;
  width: 13px;
  border-radius: 999px;
  line-height: 13px;
  z-index: 999;
}

.mejs-no-play-button .mejs__overlay-button {
  display: none !important;
}

.mediaelement-player-container.mediaelement-player-container--audio-only .mejs__overlay-button {
  display: none !important;
}

.audio-only-canvas {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  /*z-index: 12; !* make clickable *!*/
  opacity: 1;
  text-align: center;
  color: theme(colors.white);
  z-index: 2;
  @apply pointer-events-none;
}

.audio-only-canvas {
  background-color: transparent !important;
}

.audio-only-canvas > div {
  height: 100%; width: 100%;
  display: flex; justify-content: center; align-items: center;
  flex-direction: column;
  background-color: transparent !important;
}

.audio-only-canvas .headphones {
  font-size: 170px;
  font-family: FontAwesome;
  color: theme(colors.white) !important;
  background-color: transparent !important;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.audio-only-canvas .headphones * {
  color: theme(colors.white) !important;
  background-color: transparent !important;
}
.audio-only-canvas .audioonly {
  color: theme(colors.white) !important;
  background-color: transparent !important;
  @apply text-3xl font-bold p-3;
}

@media (max-width : 668px) {
  .audio-only-canvas .headphones {
    font-size: 120px;
  }

  .audio-only-canvas .audioonly {
    @apply text-xl; 
  }
  
  #hdfvr-content {
    .audio-only-canvas > div {
      width: 100%;
      height: 100%;
      margin: auto;
    }

    .audio-only-canvas .headphones {
      display:none;
    }
    .audio-only-canvas .audioonly {
      @apply text-xl;
    }
  }
}

@media (max-width : 500px) {
  .audio-only-canvas .headphones {
    font-size: 70px;
  }

  .audio-only-canvas .audioonly {
    @apply text-xl; 
  }
}

.media-player-container {
  width: 100%; padding-top: 20px; padding-bottom: 30px; margin-bottom: 0px; text-align: center;
}

.media-player-container>div.mobile {
  margin: auto;
}

.rounded-video-container {
  .mejs__controls {
    @apply rounded-b-lg;
  }

  .mejs__mediaelement {
    @apply rounded-lg;
  }
}

.media-player-container>div.desktop {
  margin: auto;
}

.ratings-panel-field-answer .mejs__container {
  max-width: 880px;
}
@media (max-width : 668px) {
  .media-player-container.-header>div.desktop {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.company-audio-files-player-container .mejs__container {
  max-width: 300px;
}

#live-interviews-list .mejs__container {
  max-width: 420px;
}

.mejs__captions-layer .mejs__captions-position {
  padding: 20px;
  z-index: 99999;
}

.mejs__captions-text, .mejs__captions-text * {
  background: rgba(20, 20, 20, 0.8);
  box-shadow: 5px 0 0 rgba(20,20,20,0.8), -5px 0 0  rgba(20,20,20,0.8);
}

.mejs__captions-layer {
  font-size: theme(fontSize.lg);
}

.aspectRatioSizer {
  display: grid;
}
.aspectRatioSizer > * {
  grid-area: 1 / 1 / 2 / 2;
}

#async-test-video .OT_publisher, #recorder-inhouse-video {
  border-radius: 15px;
}

video.mediaelementplayer{
  max-width: 100% !important;
}
