.vidtab {
  float: left; background-color: white; border: 1px solid theme(colors.neutral.300); border-bottom: 1px solid white; height: 26px; margin-left: 10px; padding-bottom: 10px; margin-top: 10px; border-top: 3px solid theme(colors.green.700);
}

.vidtab-inactive {
  float: left; border: 1px solid theme(colors.neutral.300); height: 26px; margin-left: 5px; padding-bottom: 10px; margin-top: 12px;
}

.vidtab .vidtab-inner {
  float: left; font-weight: bold; font-size: theme(fontSize.sm); border-bottom: 1px solid white; height: 25px; padding-left: 10px; padding-top: 6px; padding-right: 15px;
}

.vidtab-inactive .vidtab-inner {
  float: left; border-top: 2px solid white; font-weight: bold; font-size: theme(fontSize.sm); border-bottom: 1px solid white; height: 25px; padding-left: 10px; padding-top: 4px; padding-bottom: 4px; padding-right: 15px;
}

.vidtab-inner img {
  float: left; padding-right: 10px;
}

.vidtab-inner p {
  padding-top: 2px; float: right;
}

.vidtab-inactive .vidtab-inner {
  color: theme(colors.neutral.600);
}

.vidtab .vidtab-inner p {
  color: theme(colors.neutral.700);
  font-weight: bold;
}

.vidtabs-container {
  height: 50px; border-bottom: 1px solid theme(colors.neutral.300); position: relative;
}

.vidtabs-content {
  position: absolute; top: 1px;
}

.vidtabs-content {
  width: 100%;
}

.main-tabs-outter-content {
  position: absolute;
  width: 100%;
  top: 1px;

  .main-tabs-prepend-content-container {
    @apply absolute top-0 z-10 left-0 box-border;
  }

  .main-tabs-append-content-container {
    @apply absolute top-0 z-10 right-0 box-border;
  }

  .vidtab-button {
    left: 3px; top: 13px; z-index: 1002;
  }
}

.main-tabs-container, .sub-tabs-container {
  float: left;
  margin-left: 40px;
  height: 50px;
  width: 100%;
  overflow: hidden;
}

.main-tabs-container .tab, .sub-tabs-container .tab {
  float: left;
}

.main-tabs-content, .sub-tabs-container {
  width: 100%;
}

.sub-tabs-container .tab-active a {
  color: theme(colors.black);
}

.sub-tabs-video-editor {
  width: 640px; margin-left: 20px;
}

.backgrounded-tabs-container {
  @apply bg-neutral-100 p-0 border border-neutral-200;

  .main-tabs-container .tab-active, .sub-tabs-container .tab-active {
    @apply border-b border-neutral-200;
  }

  .btn-secondary-v3 {
    @apply bg-neutral-100;
  }
}

.main-tabs-container .tab-active, .sub-tabs-container .tab-active {
  background-color: transparent;
  height: 26px;

  margin-top: 10px;
  margin-left: 5px;
  padding-bottom: 10px;

  border: 1px solid theme(colors.neutral.300);
  border-bottom: 1px solid white;
  border-top: 3px solid theme(colors.green.700);
}

.main-tabs-container .tab-active, .sub-tabs-container .tab-active {

}

.vidcruiter-custom-design .main-tabs-container .tab-active, .vidcruiter-custom-design .vidtabs-container .tab-active {
  border-top: 3px solid theme(colors.neutral.700);
}

.vidcruiter-custom-design .vidtabs-container .tab-inactive {
  border-top: 1px solid transparent;
}


.main-tabs-container .tab-active div, .sub-tabs-container .tab-active div {
  height: 25px;
  float: left;
  font-weight: bold;
  padding-right: 11px;
  padding-left: 11px;
  padding-top: 7px;
  border-bottom: 1px solid white;
  border-bottom-color: transparent;
  @apply text-xs;
}

.v3 .main-tabs-container .tab-active div, .sub-tabs-container .tab-active div {
  @apply text-xs;
}

.main-tabs-container .tab-inactive, .sub-tabs-container .tab-inactive {
  height: 26px;
  padding-bottom: 10px;
  margin-top: 12px;
  margin-left: 5px;
  border: 1px solid theme(colors.white);
  border-color: transparent;
  border-bottom: 1px solid theme(colors.neutral.300);
}

.main-tabs-container .tab-inactive:hover, .sub-tabs-container .tab-inactive:hover {
  border: 1px solid theme(colors.neutral.300);
  border-bottom: 1px solid theme(colors.neutral.300);
}

.main-tabs-container .tab-inactive div, .sub-tabs-container .tab-inactive div {
  float: left;
  font-weight: bold;
  color: theme(colors.neutral.600);
  border-bottom: 1px solid theme(colors.white);
  border-bottom-color: transparent;
  height: 25px;
  padding-left: 11px;
  padding-top: 8px;
  padding-bottom: 2px;
  padding-right: 11px;
  background: transparent;
  @apply text-xs;
}

.v3 .main-tabs-container .tab-inactive div, .sub-tabs-container .tab-inactive div {
  @apply text-xs;
}

.tab-first {
  margin-left: 20px !important;
}

.main-tabs-container .tab-inactive div:hover, .sub-tabs-container .tab-inactive div:hover {
  cursor: pointer;
  color: theme(colors.black);
  background-color: theme(colors.white);
}


.tabs-stacked .open > a:hover {
  border-color: theme(colors.neutral.400);
}
.tabbable {
}
.tabbable:before,
.tabbable:after {
  display: table;
  content: "";
  line-height: 0;
}
.tabbable:after {
  clear: both;
}
.tab-content {
  overflow: auto;
  @apply text-sm;
}
.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}
.tab-content > .active,
.pill-content > .active {
  display: block;
}
.tabs-below > .nav-tabs {
  border-top: 1px solid theme(colors.neutral.300);
}
.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}
.tabs-below > .nav-tabs > li > a {
  border-radius: 0 0 4px 4px;
}
.tabs-below > .nav-tabs > li > a:hover {
  border-bottom-color: transparent;
  border-top-color: theme(colors.neutral.300);
}
.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover {
  border-color: transparent theme(colors.neutral.300) theme(colors.neutral.300) theme(colors.neutral.300);
}
.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}
.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}
.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid theme(colors.neutral.300);
}
.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  border-radius: 4px 0 0 4px;
}
.tabs-left > .nav-tabs > li > a:hover {
  border-color: theme(colors.neutral.200) theme(colors.neutral.300) theme(colors.neutral.200) theme(colors.neutral.200);
}
.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover {
  border-color: theme(colors.neutral.300) transparent theme(colors.neutral.300) theme(colors.neutral.300);
}
.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid theme(colors.neutral.300);
}
.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  border-radius: 0 4px 4px 0;
}
.tabs-right > .nav-tabs > li > a:hover {
  border-color: theme(colors.neutral.200) theme(colors.neutral.200) theme(colors.neutral.200) theme(colors.neutral.300);
}
.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover {
  border-color: theme(colors.neutral.300) theme(colors.neutral.300) theme(colors.neutral.300) transparent;
}
.tab-v3 {
  outline: none;
}
.tab-notice {
  margin: 0px; padding: 5px 10px; font-size: theme(fontSize.xs); text-align: center; border-bottom: 1px solid theme(colors.neutral.100); font-style: italic;
}

/* Flat tabs */
.main-tabs-container.-flat .tab-active, .sub-tabs-container.-flat .tab-active {
}

.main-tabs-container.-flat .tab-inactive div, .sub-tabs-container.-flat .tab-inactive div {
  background-image: none;
}

.main-tabs-container.-flat .tab-inactive div:hover, .sub-tabs-container.-flat .tab-inactive div:hover {
  background: theme(colors.neutral.100);
  border-top: 2px solid theme(colors.neutral.100);
}

.main-tabs-container.-flat .tab-inactive, .sub-tabs-container.-flat .tab-inactive {

}

.main-tabs-container.-flat .tab-inactive:hover, .sub-tabs-container.-flat .tab-inactive:hover {
  border: 1px solid theme(colors.neutral.300);
  color: theme(colors.black);
}

.main-tabs-container.-large .tab-inactive div, .sub-tabs-container.-large .tab-inactive div, .main-tabs-container.-large .tab-active div, .sub-tabs-container.-large .tab-active div {
  font-size: theme(fontSize.base);
}

.main-tabs-content.-first-tab-visible, .main-tabs-content.-less-left-margin {
  margin-left: -15px !important;
}

.main-tabs-arrow .vidtab-button a.btn, .main-tabs-arrow .vidtab-button a.btn:hover {
  border-color: transparent;
  background-color: transparent;
}

.main-tabs-arrow.active .vidtab-button a.btn {
  border-color: theme(colors.neutral.200);
  background-color: transparent;
}

.main-tabs-arrow.active .vidtab-button a.btn:hover, .main-tabs-arrow.active .vidtab-button a.btn:active {
  border-color: theme(colors.neutral.400);
  background-color: theme(colors.white) !important;
}

/* Tab radio buttons */
.nav-tabs li.radio-tab .radio-tab-indicator { font-size: theme(fontSize.xl); float: left; margin-right: 8px; }
.nav-tabs li.radio-tab .radio-tab-indicator.-active { color: theme(colors.vidgreen); }
.nav-tabs li.radio-tab .radio-tab-indicator.-inactive { color: theme(colors.neutral.200); }

.nav-tabs li.radio-tab .radio-tab-indicator.-active {display: none;}
.nav-tabs li.radio-tab .radio-tab-indicator.-inactive {display: initial;}

.nav-tabs li.radio-tab.active .radio-tab-indicator.-active {display: block;}
.nav-tabs li.radio-tab.active .radio-tab-indicator.-inactive {display: none;}

.nav-tabs li.radio-tab:hover .radio-tab-indicator.-inactive { color: theme(colors.vidgreen); }
