:root {
  --color-radio-buttons: theme(colors.vidgreen);
}

.vidcruiter-form .vidcruiter-field .vidcruiter-input label.styled-radio-container, .styled-radio-container {
  font-size: theme(fontSize.sm);
  padding-top: 3px;

  &:hover {
    color: theme(colors.black);
  }

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input[type="radio"]:focus ~ .styled-radio-checkmark {
    /* //uncomment this to add an outline when selected
    //outline: 2px solid blue; */
  }

  .styled-radio-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid theme(colors.neutral.300);
  }

  &:hover input[type="radio"] ~ .styled-radio-checkmark {
    background-color: transparent;
    border: 2px solid var(--color-radio-buttons);
  }

  input[type="radio"]:checked ~ .styled-radio-checkmark {
    background-color: var(--color-radio-buttons);
    border: 2px solid var(--color-radio-buttons);
  }

  .styled-radio-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input[type="radio"]:checked ~ .styled-radio-checkmark:after {
    display: block;
  }

  .styled-radio-checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color-radio-buttons);
  }
}