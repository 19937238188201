.template-picker-menu {
  width: 40%;
  min-width: 40%;
  border-right: 1px solid theme(colors.neutral.300);
  overflow-y: auto;
  padding: 8px 12px;
}

.template-interface-container,
.template-groups-interface-container {
  width: 100%;
  min-height: 420px;
  max-height: calc(96vh - 43px);
  display: flex;
  flex-direction: column;
}