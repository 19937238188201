.sort-by {
	width: 200px;
}

.sort-by ul {
	margin: 0;
	padding: 0;
	margin-top: 5px;
	list-style-type: none;
}

.sort-by ul li {
	padding-top: 2px;
	padding-bottom: 2px;
}

.sort-by ul li .extra {
	display: none;
	margin-top: 5px;
	margin-bottom: 5px;	
}

.sort-by ul li.active {
	background-color: theme(colors.neutral.50);
	padding: 10px;
	border-radius: 5px;
}

.sort-by ul li.active .extra {
	display: block;
}

.sort-by ul li:hover {

}

.sort-by ul li a {
	font-weight: bold;
	font-size: theme(fontSize.sm);
	outline: none;
	color: theme(colors.neutral.900);		

}

.sort-by ul li.active a {
	font-size: theme(fontSize.base);
	color: theme(colors.sky.800);

}

.sort-by ul li.active label input {
	margin-right: 5px;
}

.sort-by ul li.active label {
	font-size: theme(fontSize.sm);
	color: theme(colors.neutral.400);
	margin-bottom: 0px;
	font-weight: bold;
}

.sort-by ul li.active label:hover {
	color: theme(colors.neutral.900);
}

.sort-by ul li.active label.checked {
	color: theme(colors.sky.800);
}

.filter-by-optimized .select2-container-multi .select2-choices .select2-search-choice {
	background-image: none;
	background-color: theme(colors.neutral.50);
	box-shadow: none;
	width: 360px;
	border: 1px solid theme(colors.neutral.300);
	border-radius: 0px;
}