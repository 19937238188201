.person-status {
  border-radius: 5px;
  background-color: theme(colors.sky.600);
  color: theme(colors.white);
  width: 100%;
  box-sizing: border-box;
  @apply mb-6;

  &.-readonly {
  }


  &.-neutral {
    background-color: theme(colors.neutral.200);
    color: theme(colors.neutral.700);

    h6 { color: theme(colors.neutral.700); }
  }

  h6 {
    text-transform: uppercase;
    color: theme(colors.white);
    padding: 2px 8px;
    width: 100%;
    @apply text-2xs tracking-wider;
  }

  p.person-status-label {
    font-weight: bold;
    margin-bottom: 0px;
    padding: 4px 8px;
    flex-grow: 1;
    @apply text-sm;
  }

  .person-status-label-container {
    display: flex; align-items: center;
    @apply my-0.5;
  }

  .person-status-header-container {
    background-color: rgba(0,0,0,0.09); border-radius: 5px 5px 0px 0px;
    @apply my-0.5;
  }
}
