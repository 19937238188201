/* Dividers */

.divider-v3 { background-image: url('v3_divider.png'); width: 2px; height: 100%; }
.divider-v3-header {
  @apply text-sm text-neutral-700 uppercase my-2.5 text-left font-bold;
}
.divider-v3-hr { border: 0; height: 0; border-top: 1px solid theme(colors.neutral.300); border-bottom: 1px solid theme(colors.neutral.300); margin-top: 0px; }
.divider-v3-hr.-dotted { border-bottom-style: dotted; border-top-style: dotted; }
.divider-v3-hr.-small { border-bottom: none; }

.divider-v3-labelled-hr {
  display:flex; width: 100%; justify-content: center; align-items: center;

  h2 { font-size: theme(fontSize.xs);
    padding: 2px;
    color: theme(colors.neutral.600);
    text-align: left;
    white-space: nowrap;
    padding-left: 0px;
    margin-right: 25px; }

  hr { flex-grow: 1; border-top: 1px solid theme(colors.neutral.200); border-bottom: 1px solid theme(colors.white); }
}
