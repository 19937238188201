.fr-element {
  ol, ul {
    padding-left: 0;
    list-style-position: inside;
  }
}
.vidcruiter-form .vidcruiter-field .vidcruiter-input .fr-popup .fr-input-line input[type="text"] {
  width: 100%;
  padding: 4px 12px;
  font-size: 14px;
}
.vidcruiter-form .vidcruiter-field .vidcruiter-input .fr-popup .fr-checkbox input {
  padding: 0;
}
.vidcruiter-form .vidcruiter-field .vidcruiter-input .fr-popup .fr-checkbox-line label {
  display: inline;
}
