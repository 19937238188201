.list-filtering-options {
  @apply text-xs;
}

.list-filtering-options .filtering-label {
  font-weight: bold;
  margin-right: 5px;
  @apply text-neutral-400;
}

.list-filtering-options .filtering-option {
  @apply text-xs bold text-neutral-700;
}

.list-filtering-options .filtering-option span.caret {
  border-top-color: theme(colors.neutral.600); margin-top: 8px; margin-left: 5px;
}

.filtering-divider {
  border-right: 1px solid theme(colors.neutral.300); margin-top: 2px; height: 15px; margin-left: 20px; margin-right: 20px;
}