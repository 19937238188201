.mce-content-body .vidhighlight {
  background-color: yellow !important;
}

.-readonly .tag-list-item {
  color: theme(colors.neutral.700);
}

.tag-list-item-container {
  display:flex; flex-direction: column; align-items: flex-end;

  .tag-list-item {
    float: initial;
    @apply mb-2 flex items-center;
  }
}

.tag-list-item {
  float: left;
  background-color: theme(colors.white);
  color: theme(colors.sky.600);
  font-size: theme(fontSize.sm);
  @apply px-2 py-1 mr-1.5 mb-1.5;
}

.tag-list-item .left {
  float: left; padding: 2px; font-weight: bold;
}

.tag-list-item .right {
  float: left; margin-left: 5px; padding: 0px; color: white;
}

.tag-list-item .tag-list-item-delete {
  font-size: theme(fontSize.base);
  margin-left: 5px;
  padding: 1px;
  font-weight: bold;
  color: theme(colors.neutral.400);
}

.tag-list-item .tag-list-item-delete:hover {
  color: theme(colors.neutral.700);
  text-decoration: none;
}

.tag-list-item .right a {
  text-shadow: none;
  color: theme(colors.slate.200);
  opacity: 1;
  font-size: theme(fontSize.sm);
}

.tag-list-item .right a:hover {
  color: white;
}

.multiple-position-badge, .privacy-policy-badge {
  position: relative; margin-top: 5px;
}

.multiple-position-badge div, .privacy-policy-badge div {
  border: 1px solid theme(colors.neutral.300); padding: 10px; padding-top: 0px; padding-bottom: 0px; font-size: theme(fontSize.2xs); font-weight: bold; background-color: white; color: theme(colors.neutral.700);
}

.privacy-policy-badge div {

}

.multiple-position-badge:hover a {
  text-decoration: none;
}

.multiple-position-badge div:hover, .privacy-policy-badge div:hover {
  background-color: theme(colors.slate.200);
  border: 1px solid theme(colors.blue.200);
  color: theme(colors.sky.800);
}

.multiple-position-badge ul {
  margin-top: 5px;
}

.label,
.badge {
  display: inline-block;
  color: theme(colors.white);
  vertical-align: baseline;
  white-space: nowrap;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: theme(colors.neutral.400);
  @apply py-0.5 px-1 text-xs font-bold;
}
.label {
  @apply rounded;
}
.badge {
  padding-left: 9px;
  padding-right: 9px;
  border-radius: 9px;
}
.badge-multiline {
  display: inline-block;      
  text-align: left;    
  white-space: normal; 
}
.label:empty,
.badge:empty {
  display: none;
}
a.label:hover,
a.badge:hover {
  color: theme(colors.white);
  text-decoration: none;
  cursor: pointer;
}
.label-important,
.badge-important {
  background-color: theme(colors.red.700);
}
.label-important[href],
.badge-important[href] {
  background-color: theme(colors.red.800);
}
.label-warning,
.badge-warning {
  background-color: theme(colors.amber.500);
}
.label-warning[href],
.badge-warning[href] {
  background-color: theme(colors.amber.600);
}
.label-success,
.badge-success {
  background-color: theme(colors.vidgreen);
}
.label-success[href],
.badge-success[href] {
  background-color: theme(colors.emerald.800);
}
.label-info,
.badge-info {
  background-color: theme(colors.sky.600);
}
.label-info[href],
.badge-info[href] {
  background-color: theme(colors.sky.700);
}
.label-inverse,
.badge-inverse {
  background-color: theme(colors.neutral.700);
}
.label-inverse[href],
.badge-inverse[href] {
  background-color: theme(colors.neutral.950);
}
.btn .label,
.btn .badge {
  position: relative;
  top: -1px;
}
.btn-mini .label,
.btn-mini .badge {
  top: 0;
}


.badge-lightgreen {
  padding-left: 7px;
  padding-right: 7px;
  background-color: theme(colors.green.500);
  color: theme(colors.white);
  text-shadow: none;
  font-weight: bold;
  @apply text-2xs;
}
.badge-lightblue {
  padding-left: 7px;
  padding-right: 7px;
  background-color: theme(colors.blue.100);
  color: theme(colors.neutral.500);
  text-shadow: none;
  font-weight: bold;
  @apply text-2xs;
}
.badge-white, .badge-red {
  padding-left: 7px;
  padding-right: 7px;
  background-color: theme(colors.white);
  color: theme(colors.neutral.700);
  border: 1px solid theme(colors.neutral.300);
  text-shadow: none;
  font-weight: bold;
  display: inline-block;
  max-width: 100%;
  @apply text-2xs truncate box-border;
}

a.badge-white:hover {
  background-color: theme(colors.neutral.200);
  text-decoration: none;
  color: theme(colors.neutral.700);
}
.badge-red, .badge-red:hover {
  background-color: theme(colors.red.50);
  color: theme(colors.red.950) !important;
  border-color: theme(colors.red.50);
}
.badge-gray, .badge-lightgray {
  background-color: theme(colors.neutral.200);
  color: theme(colors.neutral.600);
  border: 1px solid theme(colors.neutral.300);
  text-shadow: none;
  @apply px-2 text-2xs bold;
}
.badge-lightgray {
  background-color: theme(colors.neutral.200);
}

a.badge-lightgray:hover {
  color: theme(colors.neutral.900);
}

.label-lightblue {
  background-color: theme(colors.blue.100);
  color: theme(colors.neutral.500);
  text-shadow: none;
}

.label-lightred {
  background: none repeat scroll 0 0 theme(colors.red.100);
  color: theme(colors.red.800);
  text-shadow: none;
}

.label-darkred {
  background: none repeat scroll 0 0 theme(colors.red.500);
  color: theme(colors.white);
  text-shadow: none;
}

.label-large {
  font-size: theme(fontSize.base);
}

.label-white {
  background-color: theme(colors.white);
  color: theme(colors.neutral.500);
  border: 1px solid theme(colors.neutral.300);
  text-shadow: none;
  text-transform: none;
}

.label-selected {
  color: green;
}


.label-unselected {
  color: theme(colors.neutral.300);
}

.label-unselected:hover {
  color: theme(colors.neutral.400);
}

.btn .label-end {
    background-color: inherit;
    border-left: 1px solid theme(colors.neutral.300);
    border-radius: 0;
    border-right: none;
    color: theme(colors.neutral.500);
    font-size: theme(fontSize.xs);
    font-style: italic;
    font-weight: normal;
    line-height: 15px;
    margin-left: 5px;
    padding: 0 4px 0 10px;
    text-shadow: none;
  }


a .badge-gray:hover, a.badge-gray:hover {
  background-color: theme(colors.neutral.300);
  text-decoration: none;
  color: black;
}
a .badge-white:hover {
  background-color: theme(colors.neutral.200);
  text-decoration: none;
}
.badge-small {
  padding-left: 7px;
  padding-right: 7px;
  font-size: theme(fontSize.2xs);
  font-weight: bold;
}
.badge-v3 {
  padding-left: 7px;
  padding-right: 7px;
  background-color: theme(colors.white);
  color: theme(colors.neutral.700);
  border: 1px solid theme(colors.neutral.300);
  text-shadow: none;
  font-weight: bold;
  display:inline-block;
  text-overflow: ellipsis;
  overflow:hidden;
  max-width:100%;
  @apply text-2xs;
}
.badge-v3-red, .badge-v3-green {
  color: theme(colors.white);
  background-color: theme(colors.red.700);
  border: none;
  font-weight: normal;
  font-size: theme(fontSize.2xs);
}
.badge-v3-green {
  background-color: theme(colors.green.600);
}
.badge-noborder {
  border: none;
}

.label-yellow {
    background: none repeat scroll 0 0 theme(colors.amber.300);
    border: 1px solid theme(colors.amber.400);
    box-shadow: none;
    color: theme(colors.amber.950);
    font-size: theme(fontSize.xs);
    font-weight: normal;
    line-height: 23px;
    margin-left: 0px;
    padding: 1px 8px;
    position: relative;
    text-shadow: none;
}

.label-light-red {
    background: none repeat scroll 0 0 theme(colors.red.100);
    border: 1px solid theme(colors.red.200);
    box-shadow: none;
    color: theme(colors.red.800);
    font-size: theme(fontSize.xs);
    font-weight: normal;
    line-height: 23px;
    margin-left: 0px;
    padding: 1px 8px;
    position: relative;
    text-shadow: 0 1px 1px theme(colors.white);
    top: -2px;
}

.label-light-red-small {
  background: none repeat scroll 0 0 theme(colors.red.100);
  border: 1px solid theme(colors.red.200);
  box-shadow: none;
  color: theme(colors.red.800);
  font-size: theme(fontSize.xs);
  font-weight: normal;
  line-height: 23px;
  margin-left: 0px;
  padding: 1px 8px;
  position: relative;
  text-shadow: 0 1px 1px theme(colors.white);
  max-width: 130px;
}

.label-dark-red {
    background: none repeat scroll 0 0 theme(colors.red.500);
    border: 1px solid theme(colors.red.600);
    box-shadow: none;
    color: theme(colors.white);
    font-size: theme(fontSize.xs);
    font-weight: normal;
    line-height: 23px;
    margin-left: 0px;
    padding: 1px 8px;
    position: relative;
    top: -2px;
}

.label-white-v3 {
    background: none repeat scroll 0 0 theme(colors.neutral.100);
    border: 1px solid theme(colors.neutral.300);
    box-shadow: none;
    color: theme(colors.neutral.700);
    font-size: theme(fontSize.xs);
    font-weight: normal;
    line-height: 23px;
    margin-left: 0px;
    padding: 1px 8px;
    position: relative;
    text-shadow: 0 1px 1px theme(colors.white);
    top: -2px;
}

.v3-tag {
    margin-bottom: 10px; 
    margin-right: 5px; 
    border: 1px solid theme(colors.neutral.300); 
    border-top: 2px solid theme(colors.neutral.300); 
    font-size: theme(fontSize.xs); 
    font-weight: bold; 
    padding-left: 5px; 
    padding-right: 5px; 
    padding-bottom: 0px; 
    padding-top: 1px; 
    display: inline-block; 
    word-wrap: normal; 
    color: theme(colors.neutral.500); 
    background-color: white;
}

.v3-tag:hover {
    background-color: white;
}

.label-user-type {
  font-size: theme(fontSize.2xs); line-height: 8px; margin-bottom: 5px; margin-top: 0px;
}

.label-user-type-1 { background-color: theme(colors.neutral.600); }
.label-user-type-2 { background-color: theme(colors.purple.600); }
.label-user-type-3 { background-color: #a45251; } /* TODO: No TW colour match */
/*.label-user-type-4 { background-color: #d88840; }*/
.label-user-type-5 { background-color: theme(colors.amber.600); }

.label-small {
  font-size: theme(fontSize.xs);
  padding: 1px 5px;
  line-height: 14px;
 }
