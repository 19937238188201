.alert {
  background-color: theme(colors.orange.50);
  border: 1px solid theme(colors.orange.100);
  @apply mb-5 py-2 pr-8 pl-4 text-sm rounded;
}
.alert,
.alert h4 {
  color: theme(colors.yellow.600);
}
.alert h4 {
  margin: 0;
}
.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: 20px;
}
.alert-success {
  background-color: theme(colors.green.100);
  border-color: theme(colors.green.200);
  color: theme(colors.green.800);
}
.alert-success h4 {
  color: theme(colors.green.800);
}
.alert-danger,
.alert-error {
  background-color: theme(colors.red.100);
  border-color: theme(colors.red.200);
  color: theme(colors.red.800);
}
.alert-danger h4,
.alert-error h4,
.alert-text {
  color: theme(colors.red.800);
}
.alert-info {
  background-color: theme(colors.sky.100);
  border-color: theme(colors.sky.200);
  color: theme(colors.sky.800);
}
.alert-info h4 {
  color: theme(colors.sky.800);
}
.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}
.alert-block p + p {
  margin-top: 5px;
}

.warning-box {
  z-index: 8000;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
  color: theme(colors.white);
  background: theme(colors.rose.500);
  font-size: 1.5em;
  padding: 10px 20px 10px 20px;
  text-shadow: 0 -1px theme(colors.red.600), 0 1px theme(colors.red.400);
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
}

.alert-v3 { background-color: theme(colors.neutral.200); color: theme(colors.yellow.700); border: none; border-radius: 2px; padding-top: 5px; padding-bottom: 5px; text-shadow:none; font-size: theme(fontSize.base); padding-left: 10px; }
.alert-v3-support { background-color: theme(colors.amber.50); border: 2px solid theme(colors.amber.100); border-radius: 0px; padding: 4px; margin-bottom: 5px }
.alert-v3-small { font-size: theme(fontSize.sm); padding-top: 2px; padding-bottom: 2px; padding-left: 10px; }
.alert-v3-mini { font-size: theme(fontSize.xs); padding-top: 2px; padding-bottom: 2px; padding-left: 10px; }
.alert-v3-small .close { font-size: theme(fontSize.xl); }
.alert-v3-mini .close { font-size: theme(fontSize.lg); }
.alert-v3-green { background-color: theme(colors.green.50); color: theme(colors.green.800); border-color: theme(colors.green.200); }
.alert-v3-white {  background-color: theme(colors.white); color: theme(colors.black); border-color: theme(colors.white); }
.alert-v3-red { background-color: theme(colors.red.50); color: theme(colors.red.800); border-color: theme(colors.red.200); }
.alert-v3-blue { background-color: theme(colors.blue.50); color: theme(colors.blue.800); border-color: theme(colors.blue.200); }
.alert-v3-row { border-top: none; margin: auto; text-align: center; padding-top: 0px; padding-bottom: 0px; padding-right: 10px; padding-left: 10px; border-radius: 0px 0px 4px 4px; }

.alert-agreement {
  background-color: theme(colors.white); color: black; padding: 0px; border: 1px solid theme(colors.stone.100) !important; text-align: left; margin-top: 30px; font-size: theme(fontSize.sm);
}

.alert .alert-icon {
  width: 36px;
}

.alert .alert-icon img {
  width: 36px; height: 36px;
}

.alert-agreement-content {
  padding: 10px; padding-left: 25px; border-left: 1px solid theme(colors.neutral.200);
}

.alert-agreement-content h2 {
  font-size: theme(fontSize.lg); color: theme(colors.neutral.800);
}

.info-chip {
  @apply inline-block pt-1 pb-1 pl-1.5 pr-1.5 text-sm font-semibold bg-zinc-900 text-white rounded;
}

.alert-pill {
  margin: auto;
  display: inline-block;
  border-radius: 99px; margin-bottom: 20px;
  background-color: theme(colors.white);
  font-size: theme(fontSize.base);
  box-sizing: border-box;
  padding: 5px 5px;

  .alert-pill-icon {
    color: theme(colors.slate.600);
    font-size: theme(fontSize.base);
  }

  a {
    font-size: theme(fontSize.sm);
    background-color: theme(colors.slate.600);
    color: theme(colors.white);
    border-radius: 99px;
    padding: 2px 10px;
    box-sizing: border-box;
  }

  a:hover {
    background-color: theme(colors.neutral.700);
    text-decoration: none;
  }

  &.-active {
    background-color: theme(colors.vidgreen);
    color: theme(colors.white);

    .alert-pill-icon {
      color: theme(colors.white);
    }

    a {
      background-color: theme(colors.white);
      color: theme(colors.vidgreen);
    }

    a:hover {
      background-color: theme(colors.neutral.200);
    }
  }
}
