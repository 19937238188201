.draggable-30 {
  cursor: move;
}

.droppable-30 {
  background-color: inherit;
}

.droppable-30-active {
  border: 1px dashed theme(colors.neutral.300);
  border-radius: 4px;
}

.droppable-30-hover {
  border: 1px solid theme(colors.blue.100);
  background-color: theme(colors.blue.100);
}