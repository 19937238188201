.nav {
  margin-left: 0;
  margin-bottom: 20px;
  list-style: none;
}
.nav > li > a {
  display: block;
}
.nav > li > a:hover {
  text-decoration: none;
  background-color: theme(colors.neutral.200);
}
.nav > li > a > img {
  max-width: none;
}
.nav > .pull-right {
  float: right;
}
.nav-header {
  display: block;
  padding: 3px 15px;
  font-size: theme(fontSize.xs);
  font-weight: bold;
  line-height: 20px;
  color: theme(colors.neutral.700);
  text-transform: uppercase;
}
.nav li + .nav-header {
  margin-top: 9px;
}
.nav-list {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
}
.nav-list > li > a {
  margin-left: -15px;
  margin-right: -15px;
}
.nav-list > li > a {
  padding: 3px 15px;
}
.nav-list > .active > a,
.nav-list > .active > a:hover {
  color: theme(colors.white);
  background-color: theme(colors.sky.600);
}
.nav-list [class^="icon-"],
.nav-list [class*=" icon-"] {
  margin-right: 2px;
}
.nav-list .divider {
  height: 1px;
  margin: 9px 1px;
  overflow: hidden;
  background-color: theme(colors.neutral.200);
  border-bottom: 1px solid theme(colors.white);
}
.nav-tabs,
.nav-pills {
}
.nav-tabs:before,
.nav-pills:before,
.nav-tabs:after,
.nav-pills:after {
  display: table;
  content: "";
  line-height: 0;
}
.nav-tabs:after,
.nav-pills:after {
  clear: both;
}
.nav-tabs > li,
.nav-pills > li {
  float: left;
}
.nav-tabs > li > a,
.nav-pills > li > a {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: 2px;
  line-height: 14px;
}
.nav-tabs {
  border-bottom: 1px solid theme(colors.neutral.300);
}
.nav-tabs > li {
  margin-bottom: -1px;
  @apply text-sm;
}
.nav-tabs > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 20px;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}
.nav-tabs > li > a:hover {
  border-color: theme(colors.neutral.200) theme(colors.neutral.200) theme(colors.neutral.300);
}
.nav-tabs > .active > a,
.nav-tabs > .active > a:hover {
  color: theme(colors.neutral.600);
  background-color: theme(colors.white);
  border: 1px solid theme(colors.neutral.300);
  border-bottom-color: transparent;
  cursor: default;
}
.nav-pills > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 5px;
}
.nav-pills > .active > a,
.nav-pills > .active > a:hover {
  color: theme(colors.white);
  background-color: theme(colors.sky.600);
}
.nav-stacked > li {
  float: none;
}
.nav-stacked > li > a {
  margin-right: 0;
}
.nav-tabs.nav-stacked {
  border-bottom: 0;
}
.nav-tabs.nav-stacked > li > a {
  border: 1px solid theme(colors.neutral.300);
  border-radius: 0;
}
.nav-tabs.nav-stacked > li:first-child > a {
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.nav-tabs.nav-stacked > li:last-child > a {
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.nav-tabs.nav-stacked > li > a:hover {
  border-color: theme(colors.neutral.300);
  z-index: 2;
}
.nav-pills.nav-stacked > li > a {
  margin-bottom: 3px;
}
.nav-pills.nav-stacked > li:last-child > a {
  margin-bottom: 1px;
}
.nav-tabs .dropdown-menu {
  border-radius: 0 0 6px 6px;
}
.nav-pills .dropdown-menu {
  border-radius: 6px;
}
.nav .dropdown-toggle .caret {
  border-top-color: theme(colors.sky.600);
  border-bottom-color: theme(colors.sky.600);
  margin-top: 6px;
}
.nav .dropdown-toggle:hover .caret {
  border-top-color: theme(colors.sky.800);
  border-bottom-color: theme(colors.sky.800);
}
/* move down carets for tabs */
.nav-tabs .dropdown-toggle .caret {
  margin-top: 8px;
}
.nav .active .dropdown-toggle .caret {
  border-top-color: theme(colors.white);
  border-bottom-color: theme(colors.white);
}
.nav-tabs .active .dropdown-toggle .caret {
  border-top-color: theme(colors.neutral.600);
  border-bottom-color: theme(colors.neutral.600);
}
.nav > .dropdown.active > a:hover {
  cursor: pointer;
}
.nav-tabs .open .dropdown-toggle,
.nav-pills .open .dropdown-toggle,
.nav > li.dropdown.open.active > a:hover {
  color: theme(colors.white);
  background-color: theme(colors.neutral.400);
  border-color: theme(colors.neutral.400);
}
.nav li.dropdown.open .caret,
.nav li.dropdown.open.active .caret,
.nav li.dropdown.open a:hover .caret {
  border-top-color: theme(colors.white);
  border-bottom-color: theme(colors.white);
  opacity: 1;
}

.nav > .disabled > a {
  color: theme(colors.neutral.400);
}
.nav > .disabled > a:hover {
  text-decoration: none;
  background-color: transparent;
  cursor: default;
}
.navbar {
  overflow: visible;
  margin-bottom: 20px;
}
.navbar-inner {
  min-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: theme(colors.neutral.50);
  background-image: linear-gradient(to bottom, theme(colors.white), theme(colors.neutral.100));
  background-repeat: repeat-x;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
}
.navbar-inner:before,
.navbar-inner:after {
  display: table;
  content: "";
  line-height: 0;
}
.navbar-inner:after {
  clear: both;
}
.navbar .container {
  width: auto;
}
.nav-collapse.collapse {
  height: auto;
  overflow: visible;
}
.navbar .brand {
  float: left;
  display: block;
  padding: 10px 20px 10px;
  margin-left: -20px;
  font-size: theme(fontSize.xl);
  font-weight: 200;
  color: theme(colors.neutral.500);
}
.navbar .brand:hover {
  text-decoration: none;
}
.navbar-text {
  margin-bottom: 0;
  line-height: 40px;
  color: theme(colors.neutral.500);
}
.navbar-link {
  color: theme(colors.neutral.500);
}
.navbar-link:hover {
  color: theme(colors.neutral.700);
}
.navbar .divider-vertical {
  height: 40px;
  margin: 0 9px;
  border-left: 1px solid theme(colors.neutral.100);
  border-right: 1px solid theme(colors.white);
}
.navbar .btn,
.navbar .btn-group {
  margin-top: 5px;
}
.navbar .btn-group .btn,
.navbar .input-prepend .btn,
.navbar .input-append .btn {
  margin-top: 0;
}
.navbar-form {
  margin-bottom: 0;
}
.navbar-form:before,
.navbar-form:after {
  display: table;
  content: "";
  line-height: 0;
}
.navbar-form:after {
  clear: both;
}
.navbar-form input,
.navbar-form select,
.navbar-form .radio,
.navbar-form .checkbox {
  margin-top: 5px;
}
.navbar-form input,
.navbar-form select,
.navbar-form .btn {
  display: inline-block;
  margin-bottom: 0;
}
.navbar-form input[type="image"],
.navbar-form input[type="checkbox"],
.navbar-form input[type="radio"] {
  margin-top: 3px;
}
.navbar-form .input-append,
.navbar-form .input-prepend {
  margin-top: 5px;
  white-space: nowrap;
}
.navbar-form .input-append input,
.navbar-form .input-prepend input {
  margin-top: 0;
}
.navbar-search {
  position: relative;
  float: left;
  margin-top: 5px;
  margin-bottom: 0;
}
.navbar-search .search-query {
  margin-bottom: 0;
  padding: 4px 14px;
  font-family: theme(fontFamily.sans);
  font-size: theme(fontSize.sm);
  font-weight: normal;
  line-height: 1;
  border-radius: 15px;
}
.navbar-static-top {
  position: static;
  margin-bottom: 0;
}
.navbar-static-top .navbar-inner {
  border-radius: 0;
}
.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  margin-bottom: 0;
}
.navbar-fixed-top .navbar-inner,
.navbar-static-top .navbar-inner {
  border-width: 0 0 1px;
}
.navbar-fixed-bottom .navbar-inner {
  border-width: 1px 0 0;
}
.navbar-fixed-top .navbar-inner,
.navbar-fixed-bottom .navbar-inner {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}
.navbar-static-top .container,
.navbar-fixed-top .container,
.navbar-fixed-bottom .container {
  width: 940px;
}
.navbar-fixed-top {
  top: 0;
}
.navbar-fixed-top .navbar-inner,
.navbar-static-top .navbar-inner {
  box-shadow: 0 1px 10px rgba(0,0,0,.1);
}
.navbar-fixed-bottom {
  bottom: 0;
}
.navbar-fixed-bottom .navbar-inner {
  box-shadow: 0 -1px 10px rgba(0,0,0,.1);
}
.navbar .nav {
  position: relative;
  left: 0;
  display: block;
  float: left;
  margin: 0 10px 0 0;
}
.navbar .nav.pull-right {
  float: right;
  margin-right: 0;
}
.navbar .nav > li {
  float: left;
}
.navbar .nav > li > a {
  float: none;
  padding: 10px 5px 10px;
  color: theme(colors.neutral.500);
  text-decoration: none;
}
.navbar .nav .dropdown-toggle .caret {
  margin-top: 8px;
}
.navbar .nav > li > a:focus,
.navbar .nav > li > a:hover {
  background-color: transparent;
  color: theme(colors.neutral.700);
  text-decoration: none;
}
.navbar .nav > .active > a,
.navbar .nav > .active > a:hover,
.navbar .nav > .active > a:focus {
  color: theme(colors.neutral.600);
  text-decoration: none;
  background-color: theme(colors.neutral.200);
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
}
.navbar .btn-navbar {
  display: none;
  float: right;
  padding: 7px 10px;
  margin-left: 5px;
  margin-right: 5px;
  color: theme(colors.white);
  background-color: theme(colors.neutral.200);
  background-image: linear-gradient(to bottom, theme(colors.neutral.100), theme(colors.neutral.200));
  background-repeat: repeat-x;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
}
.navbar .btn-navbar:hover,
.navbar .btn-navbar:active,
.navbar .btn-navbar.active,
.navbar .btn-navbar.disabled,
.navbar .btn-navbar[disabled] {
  color: theme(colors.white);
  background-color: theme(colors.neutral.200);
}
.navbar .btn-navbar:active,
.navbar .btn-navbar.active {
}
.navbar .btn-navbar .icon-bar {
  display: block;
  width: 18px;
  height: 2px;
  background-color: theme(colors.neutral.100);
  border-radius: 1px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
}
.btn-navbar .icon-bar + .icon-bar {
  margin-top: 3px;
}
.navbar .nav > li > .dropdown-menu:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid theme(colors.neutral.300);
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 9px;
}
.navbar .nav > li > .dropdown-menu:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid theme(colors.white);
  position: absolute;
  top: -6px;
  left: 10px;
}
.navbar-fixed-bottom .nav > li > .dropdown-menu:before {
  border-top: 7px solid theme(colors.neutral.300);
  border-top-color: rgba(0, 0, 0, 0.2);
  border-bottom: 0;
  bottom: -7px;
  top: auto;
}
.navbar-fixed-bottom .nav > li > .dropdown-menu:after {
  border-top: 6px solid theme(colors.white);
  border-bottom: 0;
  bottom: -6px;
  top: auto;
}
.navbar .nav li.dropdown > a:hover .caret {
  border-top-color: theme(colors.neutral.600);
  border-bottom-color: theme(colors.neutral.600);
}
.navbar .nav li.dropdown.open > .dropdown-toggle,
.navbar .nav li.dropdown.active > .dropdown-toggle,
.navbar .nav li.dropdown.open.active > .dropdown-toggle {
  background-color: theme(colors.neutral.200);
  color: theme(colors.neutral.600);
}
.navbar .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: theme(colors.neutral.500);
  border-bottom-color: theme(colors.neutral.500);
}
.navbar .nav li.dropdown.open > .dropdown-toggle .caret,
.navbar .nav li.dropdown.active > .dropdown-toggle .caret,
.navbar .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: theme(colors.neutral.600);
  border-bottom-color: theme(colors.neutral.600);
}
.navbar .pull-right > li > .dropdown-menu,
.navbar .nav > li > .dropdown-menu.pull-right {
  left: auto;
  right: 0;
}
.navbar .pull-right > li > .dropdown-menu:before,
.navbar .nav > li > .dropdown-menu.pull-right:before {
  left: auto;
  right: 12px;
}
.navbar .pull-right > li > .dropdown-menu:after,
.navbar .nav > li > .dropdown-menu.pull-right:after {
  left: auto;
  right: 13px;
}
.navbar .pull-right > li > .dropdown-menu .dropdown-menu,
.navbar .nav > li > .dropdown-menu.pull-right .dropdown-menu {
  left: auto;
  right: 100%;
  margin-left: 0;
  margin-right: -1px;
  border-radius: 6px 0 6px 6px;
}

.navbar-search .search-query {
  border-radius: 4px 4px 4px 4px;
}

.navbar-inner {
  border-radius: 0px 0px 0px 0px;
  border-bottom: 1px solid theme(colors.neutral.300);
  background-image: url('white_header_bg.jpg');
  background-repeat: repeat-x;
  height: 44px;
  width: 100%;
}
.navbar .nav > li {
  height: 41px;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
}
.navbar .nav > li.divider {
  background: linear-gradient(to bottom, rgb(198,198,198) 0%, rgb(231,231,231) 100%);
  width: 1px;
  height: 41px;
  padding-left: 0px;
  padding-right: 0px;
}
.navbar .nav > li.divider-hover:hover {
  background: linear-gradient(to bottom, rgb(214,214,214) 0%, rgb(238,238,238) 100%);
}
.navbar .nav > li.divider-hover.-active {
  border-bottom: 3px solid theme(colors.green.700);
}
.navbar .nav > li.divider-hover.-active > a {
  color: theme(colors.black);
}
.navbar .nav > li > a {
  font-size: theme(fontSize.xs);
  color: theme(colors.neutral.700);
  text-decoration: none;
  /*safari and chrome */
  /* firefox */

  transition: color 300ms ease-in;
  /* opera */

}
.navbar .nav > li > a:hover {
  color: theme(colors.neutral.900);
}
.nav > li > a {
  outline: none;
}
.nav-pills > .active > a,
.nav-pills > .active > a:hover {
  background-color: theme(colors.white) !important;
  border: 1px solid theme(colors.neutral.300);
  border-bottom: 2px solid theme(colors.neutral.300);
  color: theme(colors.neutral.600) !important;
}
.nav-tabs .dropdown-toggle .caret,
.nav-pills .dropdown-toggle .caret {
  border-bottom-color: #2F2F2F;
  border-top-color: #2F2F2F;
  margin-top: 6px;
}
.nav-pills.nav-stacked > li > a,
.nav-stacked li {
  color: #2F2F2F;
}
.nav-pills.nav-stacked > li > a:hover,
.nav-stacked li:hover {
  background-color: theme(colors.neutral.100);
  border-radius: 5px;
}

.navbar-search .search-query {
  transition: none 0s ease 0s;
  background: url('search_icon.png') no-repeat scroll 10px center theme(colors.white);
  color: theme(colors.neutral.700);
  font-family: theme(fontFamily.sans);
  font-size: theme(fontSize.sm);
  font-weight: normal;
  line-height: 1;
  padding: 4px 9px;
  padding-left: 30px;
  margin-top: 1px;
  height: 19px;
  border: 1px solid theme(colors.neutral.300);
  box-shadow: none;
}
.navbar-search .search-query:focus {
  padding: 4px 9px;
  padding-left: 30px;
}
.navbar-search .search-query:placeholder {
  color: theme(colors.neutral.400);
}

.sidebar-nav {
  padding-left: 0px;
  padding-right: 0px;
  background-color: theme(colors.white);
  padding: 0;
  font-size: theme(fontSize.sm);

  svg {
    float: right;
    margin-right: -6px;
    margin-top: 2px;
    opacity: 1;
    color: theme(colors.neutral.300);
  }

  &.-left-icon {
    svg { float: left; margin-right: 20px; }
  }

}

.sidebar-nav > li:hover a {
  color: theme(colors.neutral.600);
  background-color: theme(colors.neutral.200);
}
.sidebar-nav > li:hover svg {
  color: #949494;
}
.sidebar-nav > .active > a > svg {
  color: white;
}
.sidebar-nav > li > a {
  display: block;
  margin: 0 0 -1px;
  padding: 8px 14px;
  color: theme(colors.neutral.500);
  border: none;
}
/*.nav-pills.nav-stacked > li > a, .nav-stacked li*/
.sidebar-nav > .active > a,
.sidebar-nav > li.active:hover > a,
.sidebar-nav > li.active > a:focus {
  box-shadow: none;
  padding: 9px 15px;
  position: relative;
  text-shadow: none;
  z-index: 2;
  border: none;
  border-right: none;
  border-left: none;
  background-color: theme(colors.vidgreen);
  border-color: theme(colors.green.600);
  color: theme(colors.white);
  font-weight: bold;
}
.nav-tabs > li > a {
  color: theme(colors.neutral.800);
}

ul li.nav-input {
  .nav-input-icon { position: absolute; z-index: 99; left: 10px; top: 10px; color: theme(colors.neutral.800); }
  > input { width: 100%; margin-bottom: 0px; padding-left: 30px; paddin-right: 20px; font-size: theme(fontSize.sm); box-sizing: border-box; height: 35px; background-color: theme(colors.neutral.100); border: none; box-shadow: none; }

  &.-with-background {
    border: 1px solid theme(colors.neutral.300); background-color: theme(colors.neutral.100);
    > input { background-color: white; border: 1px solid theme(colors.neutral.300); }
  }
}

ul li .nav-text-divider {
  border-top: 1px solid theme(colors.neutral.200); padding-top: 0px; display:flex; align-items: stretch;

  > .ntd-triangle {
    border-bottom: 19px solid white;
    border-left: 19px solid theme(colors.neutral.200);
    width: 0;
  }

  > .ntd-content {
    box-sizing: border-box;  padding-left: 10px; padding-right: 0px; color: theme(colors.neutral.800); background-color: theme(colors.neutral.200); font-size: theme(fontSize.2xs); text-transform: uppercase;
  }

  &.-company-type-1 {  .ntd-content { color: theme(colors.white); background-color: theme(colors.vidgreen); }  .ntd-triangle { border-left-color: theme(colors.vidgreen); } }
  &.-company-type-2 {  .ntd-content { color: theme(colors.white); background-color: #282b7b; }  .ntd-triangle { border-left-color: #282b7b; } }
  &.-company-type-3 {  .ntd-content { color: theme(colors.white); background-color: theme(colors.neutral.700); }  .ntd-triangle { border-left-color: theme(colors.neutral.700); } }
  &.-company-type-4 {  .ntd-content { color: theme(colors.white); background-color: #35517e; }  .ntd-triangle { border-left-color: #35517e; } }
}

/* VidLinks top layout bar */
#vid-links-bar-content {
  display: flex;
  flex-direction: row-reverse;
  width: 960px; margin: auto;

  .logout-link { padding-top: 5px; margin-right: 0px; float: right; }
  .links-bar-right {padding-top: 5px; margin-right: 20px; float: right;}
  .links-bar {padding-top: 5px; margin-right: 20px;}
  .companies-link { padding-top: 5px; margin-left: 0px; }

  .nav-bar-label {
    display: flex; padding-top: 6px; padding-left: 0px; padding-right: 20px; flex-grow: 1; margin-left: 5px;
    .label-box {
      display: block;
      width: fit-content;
      max-width: 150px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 30px;
      padding: 1px 10px;
      color: theme(colors.neutral.300);
      background: theme(colors.neutral.500);
      text-shadow: 0 -1px theme(colors.neutral.500), 0 1px grey;
      box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    }
  }
  #companies-launcher {
    a.links-bar-button {
      span.fa { font-size: theme(fontSize.2xs); }
      span.name { margin-left: 5px; font-size: theme(fontSize.xs); white-space: nowrap; }
      span.caret { margin-top: 3px; margin-left: 3px; }
    }

    ul { margin-top: 10px; margin-left: -5px; min-width: 300px; }
  }

  .vid-links-bar-company-name {
    display: block;
    max-width: 175px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* this class is used to override the reverse alignment of the vid-links-bar-content 
   the right to left setup is causing screen readers to go through elements backwards.
   used for nav bar when applicant sign in is on
*/
.vid-links-bar-align-right {
  flex-direction: row !important; 
  justify-content: right !important;
}

@media (max-width : 668px) {
  #vid-links-bar {
  }
  #vid-links-bar-content .logout-link {
    margin-right: 20px;
  }

  #vid-links-bar-content .companies-link {
    margin-left: 20px;
  }

  #vid-links-bar-content {
    width: 100%;
  }
}

#launchpad-admin-menu {
  margin-right: 0px; margin-top: 0px; width: 280px;
}