@import "../config/config_vidcruiter";

.intl-tel-input .country-list .country.highlight {
  background-color: rgba(128, 128, 128, 0.4);
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  border: 1px solid var(--focused-color);
  outline: none;
  box-shadow: 0 0 5px rgba(var(--focused-color), .3);
}

.filter-by-select2-container {
  z-index: 999991;
}

#qtip-overlay, #qtip-overlay div {
  display: none !important;
}