.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: theme(colors.neutral.100);
  border: 1px solid theme(colors.neutral.200);
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.well blockquote {
  border-color: rgba(0, 0, 0, 0.15);
}
.well-large {
  padding: 24px;
  border-radius: 6px;
}
.well-small {
  padding: 9px;
  border-radius: 3px;
}
.well-v3 {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: theme(colors.neutral.100);
  border: 1px solid theme(colors.neutral.200);

    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3);

    background: none repeat scroll 0 0 theme(colors.neutral.100);
/*
 background: none repeat scroll 0 0 theme(colors.neutral.50);
    border: 1px solid theme(colors.neutral.300);
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.05) inset;*/
}

.well-v3.-warning {
  margin: 20px; font-weight: bold; background-color: theme(colors.white); text-align: center; color: theme(colors.neutral.700); font-style: italic;
}

.well-v3-white {
  background-color: theme(colors.white); border: 1px solid theme(colors.neutral.300);
}

.well-v3 blockquote {
  border-color: rgba(0, 0, 0, 0.15);
}
.well-large-v3 {
  padding: 24px;
  border-radius: 6px;
}
.well-small-v3 {
  padding: 9px;
  border-radius: 3px;
}

.well-v3-title {
  position: relative; background-color: theme(colors.neutral.400); color: theme(colors.white); display: inline-block; text-transform: uppercase; font-size: theme(fontSize.xs); font-weight: bold; margin-left: -20px; 
  margin-bottom: 15px; padding: 2px; padding-left: 20px; padding-right: 20px;
  line-height: 16px;
  margin-top: -10px;
}

.v3-groupbox {
  border: 1px solid theme(colors.neutral.300); padding-top: 7px; padding-bottom: 5px;
}

.v3-groupbox-none {
  border: none; padding-top: 0px; padding-bottom: 0px;
}

.v3-groupbox>.v3-groupbox-label-container {
  width: 100%;
  border-bottom: none;
  position: relative;
}

.v3-groupbox>.v3-groupbox-label-container>.v3-groupbox-label {
  position: absolute; text-align: center; width: 100%; top: -12px; z-index: 400;
}

.v3-groupbox>.v3-groupbox-label-container>.v3-groupbox-label>div {
  background-color: theme(colors.white);
  text-transform: uppercase;
  font-size: theme(fontSize.2xs);
  line-height: 11px;
  border-radius: 999px; width: 75%; color: theme(colors.neutral.700); margin: auto;
}