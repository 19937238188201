.table {
  width: 100%;
  margin-bottom: 20px;
}
.table th,
.table td {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid theme(colors.neutral.300);
}
.table th {
  font-weight: bold;
}
.table thead th {
  vertical-align: bottom;
}

.table tr.red td, .table tr.red th {
  background-color: theme(colors.red.100) !important; color: theme(colors.red.800);
}

.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
  border-top: 0;
}
.table tbody + tbody {
  border-top: 2px solid theme(colors.neutral.300);
}
.table .table {
  background-color: theme(colors.white);
}
.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}
.table-bordered {
  border: 1px solid theme(colors.neutral.300);
  border-collapse: separate;
  border-left: 0;
  border-radius: theme(borderRadius.DEFAULT);
}
.table-bordered th,
.table-bordered td {
  border-left: 1px solid theme(colors.neutral.300);
}
.table-bordered caption + thead tr:first-child th,
.table-bordered caption + tbody tr:first-child th,
.table-bordered caption + tbody tr:first-child td,
.table-bordered colgroup + thead tr:first-child th,
.table-bordered colgroup + tbody tr:first-child th,
.table-bordered colgroup + tbody tr:first-child td,
.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}
.table-bordered thead:first-child tr:first-child > th:first-child,
.table-bordered tbody:first-child tr:first-child > td:first-child {
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.table-bordered thead:first-child tr:first-child > th:last-child,
.table-bordered tbody:first-child tr:first-child > td:last-child {
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
.table-bordered thead:last-child tr:last-child > th:first-child,
.table-bordered tbody:last-child tr:last-child > td:first-child,
.table-bordered tfoot:last-child tr:last-child > td:first-child {
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.table-bordered thead:last-child tr:last-child > th:last-child,
.table-bordered tbody:last-child tr:last-child > td:last-child,
.table-bordered tfoot:last-child tr:last-child > td:last-child {
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
.table-bordered tfoot + tbody:last-child tr:last-child td:first-child {
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
}
.table-bordered tfoot + tbody:last-child tr:last-child td:last-child {
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
}
.table-bordered caption + thead tr:first-child th:first-child,
.table-bordered caption + tbody tr:first-child td:first-child,
.table-bordered colgroup + thead tr:first-child th:first-child,
.table-bordered colgroup + tbody tr:first-child td:first-child {
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.table-bordered caption + thead tr:first-child th:last-child,
.table-bordered caption + tbody tr:first-child td:last-child,
.table-bordered colgroup + thead tr:first-child th:last-child,
.table-bordered colgroup + tbody tr:first-child td:last-child {
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
.table-striped tbody > tr:nth-child(odd) > td,
.table-striped tbody > tr:nth-child(odd) > th {
  background-color: theme(colors.neutral.100);
}
.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: theme(colors.neutral.100);
}

table.vid-simple tr.header th:first-child {
  border-left: 1px solid theme(colors.neutral.300);
}
table.vid-simple tr.header th:last-child {
  border-right: 1px solid theme(colors.neutral.300);
}
table tbody tr.no-borders:hover td {
  border: none;
}
table.v3-highlight tbody tr:hover td:first-child {
  border-left: 1px solid theme(colors.neutral.300);
}
table.v3-highlight tbody tr:hover td:last-child {
  border-right: 1px solid theme(colors.neutral.300);
}
table.v3-striped tbody > tr:nth-child(odd) > td,
table.v3-striped tbody > tr:nth-child(odd) > th {
  background-color: theme(colors.neutral.100);
}

table.vid-simple {
  border-collapse: collapse;
  font-size: theme(fontSize.sm);
   border: 1px solid theme(colors.neutral.300);
}

table.v3-white {
    border: none;
}

table.vid-simple tr td, table.vid-simple tr th {
  @apply py-2 px-2.5;
}

table.v3-white tr td, table.v3-white tr th {
    border: 1px solid white;
    border-bottom: 1px solid theme(colors.neutral.300);
}

table.v3-white-noborder tr td, table.v3-white-noborder tr th {
  border: none;
  border-bottom: none;
}

table.vid-simple tr td.link a {
  color: theme(colors.neutral.400);
  font-weight: bold;
  font-size: theme(fontSize.xs);
}

table.vid-simple tr td.link a:hover {
  color: theme(colors.neutral.800);
  text-decoration: none;
}

table.vid-simple tr td.button a:hover {
  background-color: yellow;
}

table.vid-simple tr th {
    color: theme(colors.neutral.700);
    font-weight: bold;
    padding-right: 15px;
    background-color: theme(colors.neutral.100);
    border: 1px solid theme(colors.neutral.300);
    border-bottom: 2px solid theme(colors.neutral.300);
}

table.v3-white tr th {
    background: none;
    border: 1px solid white;
    padding-left: 0px;
}

table.v3-highlight tbody tr:hover td {
    background-color: theme(colors.amber.100);
    border-left: 1px solid theme(colors.amber.200);
    border-right: none;
    border-top: 1px solid theme(colors.amber.50);
    border-bottom: 1px solid theme(colors.amber.300);
}

table.vid-simple tr.header th {
    color: theme(colors.neutral.700);
    font-weight: bold;
    padding-right: 15px;
    font-size: theme(fontSize.xs);
    padding-top: 4px;
    padding-bottom: 4px;
    background: none;
    background-color: theme(colors.neutral.100);
    border-top: 1px solid white;
    border-right: none;
    border-left: none;
    text-shadow: none;
    border-bottom: 1px solid theme(colors.neutral.300);
}



table.vid-simple tr.no-borders td, table.vid-simple tr.no-borders:hover td, table.vid-simple tr.no-borders th, table.vid-simple tr.no-borders:hover th {
    border-left: none;
    border-right: none;
}

table.vid-simple tr.blue td {
    background-color: theme(colors.blue.50);
}

table.table-header-v3 th {
  color: theme(colors.neutral.700);
  font-size: theme(fontSize.sm);
  font-weight: normal;
  margin-bottom: 0;
  text-align: left;
  text-transform: uppercase; border-top: none; border-bottom:  2px solid theme(colors.neutral.300);
}

table.table-header-v3 td {
  border-top: none;
}

.v3-table {
}

.v3-table tr {
}

.v3-table th {
  font-weight: bold;
  padding: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: theme(colors.neutral.50);
  @apply text-xs;
}

.v3-table td {
  @apply p-2.5 xl:py-3 2xl:py-3.5 text-xs;
}

.v3-table-force-small-rows tr.v3-hoverable:hover {
  background: theme(colors.neutral.50);
  border-radius: theme(borderRadius.DEFAULT);
}

.v3-table tr.v3-hoverable-shadow, .force-v3-small-rows .v3-table tr.v3-hoverable-shadow:hover {
  background-color: inherit;
  @apply shadow-tight;
  border-radius: theme(borderRadius.DEFAULT);
}

@media screen and (min-device-pixel-ratio:0) {
  .v3-table {
      border-collapse: separate;
      border-spacing: 0;
  }

    .v3-table-small tr.v3-hoverable-shadow {
      border: none;
      border-radius: theme(borderRadius.DEFAULT);
    }

    .force-v3-small-rows .v3-table tr.v3-hoverable-shadow td, .force-v3-small-rows .v3-table tr.v3-hoverable-shadow td:first-child, .force-v3-small-rows .v3-table tr.v3-hoverable-shadow td:last-child {
      border-color: theme(colors.white);
    }

    .v3-table tr.v3-hoverable-shadow td {
      @apply rounded-lg;
    }

    .v3-table tr.v3-hoverable-shadow td:first-child, .force-v3-small-rows .v3-table tr.v3-hoverable-shadow:hover td:first-child {
      border: 1px solid theme(colors.neutral.100);
      border-left: 1px solid theme(colors.neutral.200);
      border-bottom: 2px solid theme(colors.neutral.300);

      border-right: none;

      border-radius: 4px 0px 0px 4px;
    }

    .v3-table tr.v3-hoverable-shadow td, .force-v3-small-rows .v3-table tr.v3-hoverable-shadow:hover td {

      border-top: 1px solid theme(colors.neutral.100);
      border-bottom: 2px solid theme(colors.neutral.300);

    }

  .v3-table tr.v3-hoverable-shadow td:last-child, .force-v3-small-rows .v3-table tr.v3-hoverable-shadow:hover td:last-child {
      border: 1px solid theme(colors.neutral.100);
      border-right: 1px solid theme(colors.neutral.200);
      border-bottom: 2px solid theme(colors.neutral.300);

      border-left: none;

      border-radius: 0px 4px 4px 0px;
    }
}


.force-v3-small-rows .v3-table tr td {
  background: initial !important;
}

.force-v3-small-rows .v3-table tr.v3-hoverable {
  border: 1px solid white;
  box-shadow: none;
}

.v3-table tr.divider-row td, .v3-table tr.divider-row th, .business-process-table tr.divider-row td, .business-process-table tr.divider-row th {
  padding: 0px;
}

.v3-table-small td {
  padding-top: 8px;
  padding-bottom: 8px;
}

.force-v3-small-rows .v3-table-small td {
  padding-top: 4px; padding-bottom: 4px;
  border-bottom: none;
}

.v3-table .person-list-overview {
  display: flex; width: 100%; height: 100%;
}

.v3-table .person-list-overview .image {
  float: left; text-align: center; width: 40px; height: 40px; display: block;
}

.v3-table .person-list-overview .image .avatars-thumb {
  float: left; width: 40px; height: 40px; line-height: 40px; display: block;
  font-size: theme(fontSize.lg);
}

.v3-table-small .person-list-overview .image .avatars-thumb {
  float: left; width: 20px; height: 20px; line-height: 20px; display: block;
  font-size: theme(fontSize.xs);
}

.v3-table .person-list-overview .image img {
  width: 40px; height: 40px; position: relative;
}

.v3-table-small .person-list-overview .image img, .v3-table-small .person-list-overview .image {
  width: 20px; height: 20px;
}

.v3-table .person-list-overview .name {
  float: left; margin-left: 10px; display: block;
}

.person-list-overview .name-text {
  font-weight: bold; margin-bottom: 3px; margin-top: 3px; display: block; color: theme(colors.neutral.900);
  @apply text-lg;
}

.v3-table-small .person-list-overview .name-text {
  margin-top: 1px;
}


.person-list-overview:hover .name-text {
  color: theme(colors.sky.800);
  text-decoration: underline;
}

.person-list-overview .extra {
  margin-bottom: 0px; display: block;
  @apply text-xs;
}

.v3-table-small .person-list-overview .extra, .v3-table-small .person-list-overview .extra-line1, .v3-table-small .person-list-overview .extra-line2 {
  display: none !important;
}

.person-list-overview .extra-line1 {
  margin-top: 6px; font-weight: bold; color: theme(colors.neutral.600); display: block; margin-bottom: 0px; line-height: 15px;
  @apply text-sm;
}

.person-list-overview .extra-line2 {
  display: block; margin-top: 0px; line-height: 15px; margin-bottom: 5px; color: theme(colors.neutral.500);
  @apply text-xs;
}

.link-v3-box-row-dropdown {
  padding-bottom: 0px; padding-top: 0px;
}

.v3-table-small .link-v3-box-row-dropdown {
  padding-bottom: 0px; padding-top: 0px;
}

.schedule-table td {
  padding: 5px;
  @apply text-xs;
}

.schedule-table .schedule-done td {
  background-image: url('striped_bg.png');
}

.schedule-table-header {
  font-weight: bold;
  border: 1px solid theme(colors.neutral.300);
}

.schedule-table .schedule-table-header td {
  @apply text-sm;
}

.schedule-table-header td {
  border-right: 1px solid theme(colors.neutral.300);
}

.schedule-table tr {
  border-top: 1px solid theme(colors.neutral.200);
}

.schedule-table tr.no-borders td {
  border: none;
}

.schedule-table tr.no-borders {
  border-bottom: none;
  border-top: none; border-right: none; border-left: none;
}

.schedule-past:last-of-type {
  border-bottom: 3px solid theme(colors.rose.400) !important;
}

table.public-profile {
  width: 100%;
  margin-bottom: 20px;
}

table.public-profile th, table.public-profile td {
  padding: 5px 15px;
  text-align: left;
}

table.public-profile tr.header {
  background-color: theme(colors.neutral.100);
}

table.public-profile tr.row {
  border-bottom: 1px dotted theme(colors.neutral.300);
}

table td.ellipsis-overflow, table th.ellipsis-overflow {
  overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
}


/* Flex tables */

.labeled-divider {
  display: flex; width: 100%; align-items: center;

  > hr { width: 100%; }

  > .labeled-divider-header {
    font-size: theme(fontSize.xs);
    color: theme(colors.neutral.600);
    text-align: left;
    white-space:nowrap;
    padding-left: 0px;
    padding-right: 25px;
    font-weight: bold;

    &.large { font-size: theme(fontSize.sm); }
    &.last { padding-right: 0px; padding-left: 25px; }
  }
}

.flex-entry-container .box-v3-content-toggler .fa-minus-circle {
  display: none;
}

.flex-entry-container .box-v3-content-toggler .fa-plus-circle {
  display: 'black';
}

.flex-entry-container.-open .box-v3-content-toggler .fa-minus-circle {
  display: initial;
}

.flex-entry-container.-open .box-v3-content-toggler .fa-plus-circle {
  display: none;
}

.flex-entry-container {
  margin-top: 6px;
  margin-bottom: 6px;
  @apply shadow-tight;
  border-radius: theme(borderRadius.DEFAULT);
}

.flex-entry {
  display: flex; width: 100%; align-items: center;
  background: theme(colors.white);
  @apply bg-gradient-to-b from-white to-neutral-100;
  box-sizing: border-box;
  border-radius: theme(borderRadius.DEFAULT);
}

.force-v3-small-rows .flex-entry-container {
  box-shadow: none;
  border-radius: 0px;
  /*border-bottom: 1px solid theme(colors.neutral.200);*/
}

.force-v3-small-rows .flex-entry.-hoverable:hover {
  background-color: inherit;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  border-radius: theme(borderRadius.DEFAULT);
}

.force-v3-small-rows .flex-entry-container.-open .flex-entry.-hoverable:hover, .force-v3-small-rows .flex-entry-container.-open .flex-entry {
  box-shadow: none;
  border-radius: theme(borderRadius.xl);
}



.force-v3-small-rows .flex-entry-container.-open {
  border-radius: theme(borderRadius.xl);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}

.force-v3-small-rows .flex-entry {
  background-image: none;
  border-radius: 0px;
  border: none;
}

.flex-entry span.large {
  font-size: theme(fontSize.lg);
  font-weight: bold;
}

.flex-entry .flex-entry-icon-link, .flex-entry-icon-link {
  color: theme(colors.neutral.400);
}

.flex-entry .flex-entry-icon-link:hover, .flex-entry-icon-link {
  color: theme(colors.neutral.900);
}

.flex-entry .flex-entry-icon {
  font-size: theme(fontSize.lg);
  padding: 10px;
  padding-right: 15px;
  padding-left: 20px;
}

.force-v3-small-rows .flex-entry .flex-entry-icon {
  padding: 6px;
}

.flex-column-list {
  display: flex; flex-flow:row; width: 100%;
}

.flex-column-list > div {
  flex-grow: 1;
}

.flex-column-list ul {
  list-style-type: none;
  margin-left: 0px;
}

.flex-column-list.-more-info-simple > div {
  width: 285px;
  min-width: 285px;
  max-width: 285px;
  margin: 5px;
}

.v-table {
  border-radius: theme(borderRadius.DEFAULT);
  border: 1px solid theme(colors.neutral.300);
  width: 100%;
  border-collapse: initial;

  &.-fixed { table-layout: fixed; }
  &.-align-top {
    td { vertical-align: top; }
  }

  thead tr {
    background: theme(colors.neutral.50);

    th {
      padding: 5px;
      border-bottom: 1px solid theme(colors.neutral.300);
    }
  }

  tbody tr {
    td { padding: 5px 10px; border-bottom: 1px solid theme(colors.neutral.200); }
  }

  tbody tr.-disabled {
    td {
      color: theme(colors.neutral.400);

      p {
        color: theme(colors.neutral.400);
      }
    }
  }

  thead:first-child tr:first-child th:first-child, tbody:first-child tr:first-child td:first-child {
    border-radius: 4px 0 0 0;
  }
  thead:last-child tr:last-child th:first-child, tbody:last-child tr:last-child td:first-child {
    border-radius: 0 0 0 4px;
  }
}

.flat-row-container.-outside-border {
  > .flat-row {
    border: none;
    background-color: inherit;
  }

  &.-hoverable:hover > .flat-row {
    background-color: inherit;
    box-shadow: none;
    border: none;
  }
}

.flat-row-container-fullwidth {
  .flat-row-container {
    margin: 0px;
  }

  .flat-row-container > .flat-row {
    border-left: none;
    border-right: none;
    border-radius: 0px;
  }

  .flat-row-container.-hoverable:hover {
    > .flat-row {
      border-color: theme(colors.sky.200);
      border-left: none;
      border-right: none;
      background-color: theme(colors.sky.50);
      /* -webkit-box-shadow: none;
      -moz-box-shadow: none; */
      box-shadow: none;
    }
  }

}

.flat-row-container {
  margin: 10px;

  .flat-row-left-action .flat-row-icon {
    font-size: theme(fontSize.base);
  }

  .big-list-number {
    align-self: baseline;

    .number.-bigger {
      @apply text-3xl
    }
  }

  > .flat-row {
    width: 100%;

    .flat-row-body { width: 100%; }
    .flat-row-more { width: 100%; }
  }

  &.-hoverable:hover {
    > .flat-row {
      border: 1px solid theme(colors.sky.200);
      background-color: theme(colors.sky.50);
      /* -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4); */
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    }
  }

  &.-no-background {
    > .flat-row {
      background-color: theme(colors.white);
    }
  }
}

.flat-row-container.-compact {
  > .big-list-number, span.reorder-handle > .big-list-number {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .flat-row-body {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

.flat-row-container.-inner {
  margin: 0px;

  border-top: 1px solid theme(colors.neutral.300);

  > .flat-row-left-action {
    width: 30px;
  }

  .big-list-number {
    font-size: theme(fontSize.sm);
    padding-left: 0px; padding-right: 0px;
    width: 40px;
    color: theme(colors.neutral.400);
  }

  > .flat-row {
    border-radius: 0px;
    background-color: inherit;
    border: none;

    .flat-row-chevron {
      margin-right: 10px;
    }

    > .flat-row-body {
      box-sizing: border-box;

      .flat-row-title {
        font-size: theme(fontSize.sm);
      }
    }
  }

  &.-hoverable:hover {
    > .flat-row {
      border: none;
      background-color: inherit;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  &.-header {
    background-color: theme(colors.neutral.100);
  }
}

.flat-row-container.-no-border {
  border-top: none;
}

.hide-flat-row-archived .flat-row-container.-archived {
  display: none !important;
}

.flat-row-container.-archived {
  border-top: 1px dashed theme(colors.red.100);
  border-bottom: 1px dashed theme(colors.red.100);
}

.hide-flat-row-hidden .flat-row-container.-hidden {
  display: none !important;
}

.flat-row-container.-hidden {
  border-top: 1px dashed theme(colors.neutral.200);
  border-bottom: 1px dashed theme(colors.neutral.200);
}
.flat-row-container.-compact {
  border-top: none;
  border-bottom: none;
}

.flat-row-container.-form {
  .flat-row-form {
    display: none;
    padding: 20px;
    box-sizing: border-box;

    &.-form-active {
      border: 1px solid theme(colors.sky.200);
      background-color: theme(colors.sky.50);
    }

    form { box-sizing: border-box; display:inline-block; margin-bottom: 0px; }
  }

  &.-form-active {
    .flat-row {
      border: 1px solid theme(colors.sky.200);
      background-color: theme(colors.sky.50);
    }

    .flat-row-form {
      display: block;
    }

    .flat-row-body {
      display: none;
    }
  }
}

.flat-row-form.-form-active {
  border: 1px solid theme(colors.sky.200);
  background-color: theme(colors.sky.50);
  border-right: none;
  border-left: none;

  padding: 20px;
}

.ui-dialog {
  .flat-row > .flat-row-body .flat-row-title {
    font-size: theme(fontSize.sm);
  }

  .flat-row-container.-inner > .flat-row > .flat-row-body .flat-row-title {
    font-size: theme(fontSize.sm);
  }
}

.flat-row {
  border-radius: 5px;
  background-color: theme(colors.neutral.100);
  border: 1px solid theme(colors.neutral.300);

  .flat-row-chevron {
    width: 30px;
    flex-shrink: 0;
  }

  .flat-row-label {
    color: theme(colors.neutral.500);
    font-size: theme(fontSize.xs);

    &.-smaller {
      font-size: theme(fontSize.xs);
    }
  }

  .flat-row-link {
    &.-less-padding {
      padding: 5px;
      padding-left: 0px;
      padding-right: 0px;
    }

    &.-no-border {
      border-top: none;
    }

    &.-inner {
      padding-left: 40px;
    }

    width: 100%; box-sizing: border-box;padding: 10px;
    display:flex; align-items: center;
    border-top: 1px solid theme(colors.neutral.300);

    .flat-row-link-icon {
      width: 25px;
      margin-right: 2px;
      text-align: center;
      font-size: theme(fontSize.sm);
    }
  }

  > .flat-row-body {
    display:flex;
    align-items: center;
    padding: 8px 8px;
    box-sizing: border-box;

    &.-less-padding {
       padding: 4px 8px;
    }

    &.padding-left-5 {
      padding-left: 5px;
    }

    .flat-row-icon {
      width: 18px;
      font-size: theme(fontSize.base);
      padding-right: 10px;
      text-align: right;
    }

    .flat-row-title {
      flex-grow: 1;
      font-size: theme(fontSize.base);
      font-weight: bold;

      .icon-circle-container {
        font-size: 8px;
        width: 8px;
        padding: 4px;
        margin-left: 2px;
        margin-right: 3px;
      }
    }
  }

  > .flat-row-more {
    background-color: theme(colors.white);
    border-radius: 0px 0px 5px 5px;
  }
}

.flat-row-container > .big-list-number, .flat-row-container span.reorder-handle > .big-list-number {
  display:flex;
  padding: 12px 10px;
  width: 35px;
  padding-left: 0px;
  padding-right: 10px;
  justify-content: flex-end;

  &.field-row-number-not-reorderable {
    display: none;
  }

  .reorder-icon {
    display:none;
  }

  &:hover {
    .reorder-icon {
      display:block;
    }
  }
}

.flat-row-container .field-row-number-reorderable {
  display: flex;
}

.hide-flat-row-hidden, .hide-flat-row-archived {
  .flat-row-container > .big-list-number, .flat-row-container span.reorder-handle > .big-list-number {
    &.field-row-number-not-reorderable {
      display: flex;
    }

    &.field-row-number-reorderable {
      display: none !important;
    }
  }
}

.table-relaxed tr td,
.table-relaxed tr th {
    @apply p-2;
}

/* Table Component CSS */
table.table-component {
  @apply w-full mb-2 border border-separate border-gray-300 rounded;
}

table.table-component tr td, table.table-component tr th {
  @apply text-left border-b border-gray-300 p-1.5 pl-2 pr-2;
}

table.table-component tr.-highlight td, table.table-component tr.-highlight th {
  @apply bg-yellow-50;
}

table.table-component tr td {
  @apply bg-white align-top;
}

table.table-component tr th {
  @apply bg-neutral-50 p-2;
}

table.table-component tr:first-of-type td:first-of-type, table.table-component tr:first-of-type th:first-of-type {
  @apply rounded-tl;
}

table.table-component tr:first-of-type td:last-of-type, table.table-component tr:first-of-type th:last-of-type {
  @apply rounded-tr;
}
  
table.table-component tr:last-of-type td:first-of-type, table.table-component tr:last-of-type th:first-of-type {
  @apply rounded-bl;
}

table.table-component tr:last-of-type td:last-of-type, table.table-component tr:last-of-type th:last-of-type {
  @apply rounded-br;
}

table.table-component tr:last-of-type td, table.table-component tr:last-of-type th {
  @apply border-b-0;
}

/* Plain Styled Table Component */
table.table-component.-plain {
  @apply border-0 rounded-none;
}

table.table-component.-plain tr th {
  @apply bg-white border-b border-gray-300;
}

table.table-component.-plain tr td, table.table-component.-plain tr th {
  @apply rounded-none !important;
}

table.table-component.-padding-medium tr td, 
table.table-component.-padding-medium tr th {
  @apply p-2;
}

table.table-component tr.disabled td,
table.table-component tr.disabled td p
{
  color: theme(colors.gray.500) !important;
  background-color: theme(colors.gray.100);
}
