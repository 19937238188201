/* jQuery Countdown styles 1.6.3. */
.hasCountdown {
	border: 1px solid theme(colors.neutral.300);
	padding: 5px;
	padding-top: 7px;

  -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow:    1px 1px 4px rgba(0, 0, 0, 0.3);
  box-shadow:         1px 1px 4px rgba(0, 0, 0, 0.3);

	background: theme(colors.white);

	@apply bg-gradient-to-b from-white to-neutral-100;
}

.countdown_rtl {
	direction: rtl;
}
.countdown_holding span {
	color: theme(colors.neutral.500);
	padding: 5px;
}

/*
<span class="countdown_row countdown_show2">
	<span class="countdown_section">
		<span class="countdown_amount">1</span>
		<br>Min
	</span>
	<span class="countdown_section">
		<span class="countdown_amount">19</span>
		<br>Secs
	</span>
</span>*/

.countdown_row {
	padding: 0px 3px;
	text-align: center;
	display: inline-block;
}
.countdown_show1 .countdown_section {
	width: 98%;
}
.countdown_show2 .countdown_section {
	width: 48%;
}
.countdown_show3 .countdown_section {
	width: 32.5%;
}
.countdown_show4 .countdown_section {
	width: 24.5%;
}
.countdown_show5 .countdown_section {
	width: 19.5%;
}
.countdown_show6 .countdown_section {
	width: 16.25%;
}
.countdown_show7 .countdown_section {
	width: 14%;
}
.countdown_section {
	display: inline-block;
	float: left;
	text-align: center;
	width: 100px;
	font-size: theme(fontSize.xs);
}
.countdown_amount {
	font-size: theme(fontSize.lg);
}
.countdown_descr {
	display: block;
	width: 100%;
}
