
@import "../sources/modules/button";
@import "../sources/modules/links";
@import "../sources/modules/alert";
@import "../sources/modules/progress";
@import "../sources/modules/tooltip";
@import "../sources/modules/table";
@import "../sources/modules/nav";
@import "../sources/modules/tab";
@import "../sources/modules/label";
@import "../sources/modules/accordion";
@import "../sources/modules/paginate";
@import "../sources/modules/divider";
@import "../sources/modules/well";
@import "../sources/modules/sidebar_dialog";
@import "../sources/modules/read_more";
@import "../sources/modules/sort_by";
@import "../sources/modules/form";
@import "../sources/modules/list_filtering";
@import "../sources/modules/multi-select";
@import "../sources/modules/avatars";
@import "../sources/modules/droppable";
@import "../sources/modules/duration_picker";
@import "../sources/modules/profile_link_settings";
@import "../sources/modules/more_info";
@import "../sources/modules/tags";
@import "../sources/modules/radio";

.block-message-panel {
	padding:14px; font-size: theme(fontSize.lg); font-weight: bold; color: theme(colors.neutral.600);
}