
html.vidIframePopup-open,body.vidIframePopup-open {
  touch-action: none;
}

.vidIframePopup-open {
  height: 100vh;
  width: 100vw;
  margin:0;
  padding:0;
  /* required to prevent rogue scrollbars */
  overflow: hidden;

  /* This was added to prevent scrolling, and a gap at the bottom in iOS after a rotate. It seems to work as-is, but a wrapp might be needed
  See this solution -> https://stackoverflow.com/questions/62717621/white-space-at-page-bottom-after-device-rotation-in-ios-safari */
}

.fancybox-iframe-popup .fancybox-inner {
  box-sizing: border-box;
}

.fancybox-iframe-popup * {
  touch-action: pan-x pan-y;
}

.vidIframePopup-container.-small .-hide-large { display: initial !important; }
.vidIframePopup-container.-small .-hide-small { display: none !important; }

.vidIframePopup-iframe {
  position: absolute;
  min-width: 200px;
  min-height: 200px;
  z-index: 98010;
}

.vidIframePopup-iframe.-hidden {
  top: -9999999px !important;
  left: 0px !important;
  display:none !important;
}

.vidIframePopup-open .fancybox-overlay {
  z-index: 98008 !important;
}

.vidIframePopup-open .fancybox-opened {
  z-index: 98009;
}