/* Custom Select UI */
.custom-ui-container-focus {
  border-width: 1px;
  border-color: gray;
  border-style: solid;
}
.custom-ui-container .custom-ui-option {
  padding: 5px;
  cursor: pointer;
}

.custom-ui-container .custom-ui-option:hover {
  background-color: theme(colors.neutral.100);
}

.custom-ui-container .custom-ui-option.active,
.custom-ui-container .custom-ui-option.active:hover {
  background-color: gray;
  color: white;
  font-weight: bold;
}

/* Titlebars */

.titlebar-v3 {
  @apply relative p-3 border-b border-neutral-200 bg-neutral-100 text-left;
}

/* Textures */

/* Calendar */

.calendar-v3 .ui-datepicker-close {
  position: absolute;
  bottom: 5px;
  right: 10px;
}

.calendar-v3-icon {
  background: white url('v3_icon_calendar.png') right no-repeat;
}

.calendar-v3-icon:hover {
  background: url('v3_icon_calendar_hover.png') right no-repeat;
}

.autocomplete-v3-item {
  color: theme(colors.neutral.700);
  padding-left: 20px;
}
.autocomplete-v3-item:hover {
  background: theme(colors.blue.500);
  color: theme(colors.white);
}

.calendar-v3-popup .ui-datepicker {
  border: none;
  box-shadow: none;
  padding-top: 3px;
  padding-bottom: 3px;
}
.calendar-v3-popup-no-minutes .ui_tpicker_minute_label {
  display: none;
}
.calendar-v3-popup-no-minutes .ui_tpicker_minute {
  display: none;
}
.calendar-v3-popup-no-minutes .ui-timepicker-div {
  padding-top: 3px;
}
.calendar-v3-popup .ui-datepicker-buttonpane {
  display: none !important;
}
.calendar-v3-popup .ui-timepicker-div {
  margin-bottom: -15px;
}
.calendar-v3-double {
  width: 510px !important;
}
.calendar-v3-double .ui-datepicker-group {
  width: 250px;
}

/* Links */

.v3-link-black {
  color: theme(colors.neutral.800);
}
.v3-link-black:hover {
  color: theme(colors.sky.600);
  text-decoration: underline;
}

/* Boxes */

.box-v3 {
  border: 1px solid theme(colors.white);
  padding: 5px;
}
.box-v3-row,
.force-v3-small-rows .box-v3-container.-open .box-v3-row {
  @apply p-0 h-10;

  &.-flex {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    min-height: 40px;

    .divider-v3 {
      height: initial;
      align-self: stretch;
    }
    .box-v3-reference-number {
      margin-top: 1px;
    }
    .link-v3-large {
      padding: initial;
    }
    .star-v3-container {
      align-self: center;
    }
    .box-v3-count {
      align-self: center;
      margin-top: 0px;
      line-height: 0;
      a,
      span {
        @apply text-sm px-2;
      }
    }
  }
}

.box-v3-count {
  @apply leading-none;
  a {
    font-size: theme(fontSize.base);
    @apply px-2 py-1;
  }
}

.force-v3-small-rows .box-v3-container .box-v3-row {
  &.-flex {
    height: auto;
    min-height: 25px;
  }
}

.force-v3-small-rows .box-v3-container.-open .box-v3-row {
  &.-flex {
    height: auto;
    min-height: 30px;
  }
}

.box-v3.-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-v3-row.-flex {
  height: auto;

  .link-v3-large {
    padding: 0px;
  }
}

.box-v3-content {
  border-top: 1px solid theme(colors.neutral.300);
}
.box-v3-container {
  @apply my-1.5 shadow-tight;
}

.box-v3-container-flat {
  box-shadow: none;
  border: 1px solid theme(colors.neutral.300);
}

.box-v3-container-flat .box-v3-content {
  border-top: 1px solid theme(colors.white);
}

.box-v3-container .box-v3-count {
  margin-top: 11px;
}
.force-v3-small-rows .box-v3-container .box-v3-count {
  margin-top: 0px;
}

.force-v3-small-rows .box-v3-container {
  margin-top: 2px;
  margin-bottom: 2px;
  box-shadow: none;

  &.-open {
    border-radius: 10px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

    margin-bottom: 10px;
  }
}

.force-v3-small-rows .box-v3-container .box-v3-row {
  background: theme(colors.white);
  background-image: none;
}

.force-v3-small-rows .box-v3-container .divider-v3 {
  background-image: none;
  height: 1px;
}

.force-v3-small-rows .box-v3-container .box-v3-row {
  height: 25px;

  &.-flex {
    height: auto;
    min-height: 30px;
  }
}

.force-v3-small-rows .box-v3-container .link-v3-box-row-dropdown,
.force-v3-small-rows .box-v3-container .link-v3-box-row,
.force-v3-small-rows .box-v3-container .box-v3-row .link-v3-large {
  padding-top: 0px;
  padding-bottom: 0px;
}

.box-v3-row .link-v3-large {
  padding: 10px;
  display: inline-block;
}

.box-v3-row-small {
  height: 20px;
  box-shadow: none;
}

.box-v3-notices {
  position: absolute;
  bottom: -22px;
  width: 100%;
  text-align: center;
}

.box-v3-row .box-v3-column.-full-width {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 10px;
  box-sizing: border-box;
}

.box-v3-row .box-v3-column.-dates {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.box-v3-row .box-v3-column.-dates td.date-label {
  text-align: right;
  font-size: theme(fontSize.xs);
  color: theme(colors.neutral.700);
  font-weight: bold;
}

.box-v3-row .box-v3-column.-dates td.date-text {
  font-size: theme(fontSize.xs);
  color: theme(colors.neutral.700);
  font-weight: normal;
  text-align: left;
}

@media (max-width: 768px) {
  .box-v3-row.-flex {
    flex-direction: column;
  }

  .box-v3-row.-flex table {
    text-align: left;
  }

  .box-v3-row .box-v3-column.-full-width {
    width: 100%;
  }

  .box-v3-row .box-v3-column.-dates {
    width: 100%;
    padding-bottom: 10px;
  }

  .box-v3-row .box-v3-column.-dates td.date-label {
    text-align: left;
  }

  .box-v3-row .box-v3-column.-dates td.date-text {
    text-align: left;
  }
}

/* Box Tables */

.box-v3-content-table td.box-v3-content-table-label {
  border-right: none;
  border-bottom: 1px solid theme(colors.neutral.300);
  vertical-align: top;
  @apply px-2.5 py-1.5 text-xs text-left font-bold uppercase whitespace-nowrap;
}
.box-v3-content-table td.box-v3-content-table-fill {
  border-right: none;
  border-bottom: 1px solid theme(colors.neutral.300);
  padding: 5px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: top;
}

.box-v3-content-table-bottom-border {
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-right: none;
}

.box-v3-content-table-bottom-border td.box-v3-content-table-label {
  border-right: none;
  border-left: none;
  border-bottom: 1px solid theme(colors.neutral.300);
}

.box-v3-content-table-bottom-border td.box-v3-content-table-fill {
  border-left: none;
  border-right: none;
}

.box-v3-content-table-no-border {
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-right: none;
}

.box-v3-content-table-no-border td.box-v3-content-table-label {
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-right: none;
}

.box-v3-content-table-no-border td.box-v3-content-table-fill {
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-right: none;
}

.remote-v3-load-info {
  padding: 10px;
}

/* Links */

.plus-minus-v3 {
  font-size: theme(fontSize.xs);
  color: theme(colors.neutral.400);
}

.link-v3 {
  color: theme(colors.sky.800);
}
.link-v3-dark {
  color: theme(colors.neutral.900);
}

.selectable-template .link-v3-large {
  font-size: theme(fontSize.sm);
}
.link-v3-large {
  @apply text-lg bold p-1;
}
.link-v3-large:hover {
  color: theme(colors.sky.800);
}
.link-v3-large .plus-minus-v3:hover {
  text-decoration: none;
}

.link-v3-icon {
  padding: 3px;
  color: theme(colors.neutral.500);
  font-size: theme(fontSize.base);
}
.link-v3-icon:hover {
  color: theme(colors.neutral.900);
  text-decoration: none;
}

.star-v3 {
  font-size: theme(fontSize.lg);
  color: theme(colors.neutral.300);
  @apply !outline-none duration-75;
}
.star-v3:hover {
  color: theme(colors.neutral.400);
  text-decoration: none;
}

.star-v3-active {
  color: theme(colors.amber.300);
}
.star-v3-active:hover {
  color: theme(colors.amber.400) !important;
  text-decoration: none;
}

.box-v3-row .star-v3 {
  margin-left: 10px;
}

.link-v3-hoverable:hover {
  background-color: theme(colors.sky.100);
  outline: 2px solid theme(colors.sky.300);
  color: theme(colors.sky.950);
}

.link-v3-box-row {
  display: inline-block;
  padding: 10px;
  color: theme(colors.neutral.600);
  font-weight: bold;
  font-size: theme(fontSize.xs);
}
.link-v3-box-row-large-text {
  font-size: 26px;
  font-weight: 200;
  padding: 12px;
  padding-left: 20px;
  padding-right: 20px;
}

.link-v3-box-row-dropdown {
  padding: theme(padding.2);
  color: theme(colors.neutral.500);
  font-size: theme(fontSize.lg);
  display: inline-block;
}
.link-v3-box-row-dropdown .caret {
  border-top-color: theme(colors.neutral.500);
  border-bottom-color: theme(colors.neutral.500);
  @apply mt-2;
}
.link-v3-box-row-dropdown.items-center .caret {
  @apply mt-0;
}
.link-v3-box-row-dropdown:hover {
  text-decoration: none;
  color: theme(colors.neutral.700);
}
.link-v3-box-row-dropdown:hover .caret {
  border-top-color: theme(colors.neutral.700);
  border-bottom-color: theme(colors.neutral.700);
}

.top-link-v3 .caret {
  border-top-color: theme(colors.neutral.900);
  border-bottom-color: theme(colors.neutral.900);
}
.top-link-v3:hover .caret {
  border-top-color: theme(colors.white);
  border-bottom-color: theme(colors.white);
}

/* User listing */

.user-v3-small {
  @apply inline-flex items-center justify-center text-sm;
}
.user-v3-small .avatar {
  margin-top: 0px;
}
.user-v3-small .avatar img {
  @apply w-8 h-8;
}
.user-v3-small .name {
  margin-left: 7px;
  margin-top: 1px;
  white-space: nowrap;
}

.select2-no-border .select2-choices {
  border: none !important;
  background-image: none !important;
  box-shadow: none !important;
  background: none !important;
}

.invitation-input-v3 {
  margin-bottom: 8px;
}
.invitation-input-v3 .icon {
  float: left;
  padding: 2px;
  padding-bottom: 0px;
}
.invitation-input-v3 .icon i {
  font-size: theme(fontSize.lg);
  color: theme(colors.neutral.300);
}
.invitation-input-v3-active .icon i,
.invitation-input-v3-exists .icon i {
  color: theme(colors.green.700) !important;
}

.invitation-input-v3-exists input.delete-hover,
.invitation-input-v3-error input {
  background-color: theme(colors.red.50);
  color: theme(colors.red.950);
  border-color: theme(colors.red.300);
}

.invitation-input-v3 .input {
  float: left;
  margin-left: 5px;
}
.invitation-input-v3 .input input[type='text'],
.email-recipients-v3-details .email-loopin-v3 {
  background-color: inherit;
  margin-bottom: 0px;
  font-size: theme(fontSize.xs);
  height: 20px;
  padding-top: 2px;
  padding-bottom: 0px;
  border: none;
  box-shadow: none;
  border-bottom: 1px dotted theme(colors.neutral.300);
  border-radius: 0px;
  padding-left: 0px;
  width: 340px;
  font-weight: bold;
}
.invitation-input-v3-active .input input[type='text'] {
  border-bottom: 1px dotted theme(colors.black) !important;
}
.invitation-input-v3 .close {
  float: left;
  display: none;
}
.invitation-input-v3-exists .close {
  float: left;
  display: block;
}

.email-recipients-v3-details .email-loopin-v3 {
  font-size: theme(fontSize.xs);
  font-weight: normal;
}
.email-recipients-v3-details .email-loopin-v3:focus {
  box-shadow: none !important;
  border-bottom: 1px dotted theme(colors.black) !important;
}
.email-recipients-v3-details .email-loopin-type {
  font-weight: bold;
  font-size: theme(fontSize.2xs);
}

.user-selection-list-v3-small .column {
  float: left;
  margin-right: 20px;
}
.user-selection-list-v3-small .column label {
  font-size: theme(fontSize.xs);
}

input[type='text'].no-border,
input[type='text'].no-border:focus {
  border: none;
  box-shadow: none;
  background-color: initial;
}

input[type='text'].outlined:focus {
  outline: 1px dotted black;
}

.ace_vidcruiter_keyword {
  border-radius: 4px !important;
  background: none repeat scroll 0 0 theme(colors.amber.300) !important;
  border: 1px solid theme(colors.amber.400) !important;
  color: theme(colors.amber.950) !important;
  padding: 2px !important;
}

.email-edit-link-v3 {
  padding: 10px;
}

.email-edit-link-v3 .icon {
  float: left;
  border: 2px solid theme(colors.neutral.300);
  text-align: center;
  margin-left: 10px;
  margin-top: 8px;
  @apply size-10 flex items-center justify-center;
}
.email-edit-link-v3 .icon i {
  font-size: 23px;
  color: theme(colors.neutral.500);
}
.email-edit-link-v3 .description {
  float: left;
  margin-left: 15px;
  margin-top: 2px;
  width: 550px;
}
.email-edit-link-v3 .description div {
  background-color: white;
  border: 1px solid theme(colors.neutral.300);
  font-size: theme(fontSize.base);
  padding: 18px;
  color: theme(colors.neutral.400);
  line-height: 20px;
  margin-top: 0px;
}

.email-edit-link-v3:hover .description div {
  border: 1px solid theme(colors.neutral.400);
  color: theme(colors.neutral.700);
}

.email-edit-link-v3-subtrigger {
  cursor: pointer;
  font-size: theme(fontSize.base);
  padding: 18px;
  color: theme(colors.neutral.400);
  line-height: 20px;
}
.email-edit-link-v3-subtrigger:hover {
  color: theme(colors.neutral.700);
}

.dialog-v3-buttons {
  padding-bottom: 0px;
  border-top: 0px solid theme(colors.neutral.300);
  padding: 15px;
  margin-bottom: 0px;
  margin-top: 20px;
}

/* new language dialog css */
.dialog-flex {
  display: flex !important;
}
.dialog-flex-content {
  display: flex;
  flex-direction: column;
  width: 750px;
  min-height: 100px;
}
.dialog-flex-top {
  flex-grow: 1;
}
.dialog-flex-footer {
  display: flex;
  justify-content: flex-end;
}
.dialog-flex-button {
  margin-right: 10px;
}

.simple-panel-v3 {
  background-color: theme(colors.neutral.50);
  border: 1px solid theme(colors.neutral.200);
}
.simple-panel-v3-success {
  border: 1px solid theme(colors.green.600);
}

.live-interview-scheduler .ui-datepicker td.ui-state-disabled .ui-state-default {
  background-color: theme(colors.neutral.200);
  border-color: white;
  color: theme(colors.neutral.700);
}

.live-interview-scheduler .ui-datepicker td.ui-datepicker-current-day .ui-state-active,
.scheduler-legend-selected {
  background-color: theme(colors.vidgreen);
  border: 1px solid theme(colors.vidgreen);
  color: theme(colors.white);
}

.live-interview-scheduler .ui-datepicker td span,
.ui-datepicker td a {
  font-size: theme(fontSize.xs);
  @apply p-1;
}

.live-interview-scheduler .ui-datepicker-header {
  font-size: theme(fontSize.base);
}

.live-interview-scheduler .ui-datepicker .ui-datepicker-prev,
.live-interview-scheduler .ui-datepicker .ui-datepicker-next {
}

.live-interview-scheduler .ui-datepicker .ui-icon-circle-triangle-e {
}

.live-interview-scheduler .ui-datepicker table {
  font-size: theme(fontSize.sm);
}

.live-interview-scheduler .ui-datepicker {
  width: initial;
}

.live-interview-scheduler .ui-datepicker td .ui-state-default,
.scheduler-legend-available {
  background-color: theme(colors.green.50);
  color: theme(colors.green.800);
  border: 1px solid theme(colors.green.200);
  font-weight: bold;
}

.appointment-picket-timeslots {
  float: left;
  margin-left: 30px;
  padding: 10px;
  width: 480px;
}

.selected-timeslot {
  border: 1px solid theme(colors.neutral.300);
  margin-top: 20px;
  padding: 10px;
  padding-left: 20px;
  background-color: theme(colors.white);
}

.timeslot-picker-title {
  font-size: theme(fontSize.base);
  font-weight: bold;
}

.time-slot-fieldset:focus-within {
  outline: 2px solid theme(colors.sky.500);
}

.time-slot-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  font-size: theme(fontSize.lg);
  padding: 9px 0px 9px 35px;
  margin: 10px 20px 10px 0px;
  float: left;
  max-width: 65px;
  min-width: 65px;
}

@media (max-width: 668px) {
  .timeslot-picker-title {
    font-size: theme(fontSize.sm);
    text-align: center;
  }

  .selected-timeslot {
    width: 100%;
    margin-left: 0px;
    padding: 0px;
  }

  .appointment-picket-timeslots {
    width: 100%;
    margin-left: 0px;
    padding: 0px;
    border-top: 2px dashed theme(colors.neutral.300);
    margin-top: 15px;
    padding-top: 15px;
  }

  .appointment-picket-timeslots h2,
  .appointment-picket-timeslots p {
    padding-left: 10px;
  }
}

body.backend-layout .fc-icon,
body.backend-layout .fc {
  font-size: theme(fontSize.sm);
}

.fc h2 {
  font-size: theme(fontSize.xl);
}

.flash-notice-success {
  padding: 20px;
  padding-right: 37px;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px solid theme(colors.orange.100) !important;
  text-align: center;
  margin-top: 30px;
  @apply text-sm bg-orange-50;
}

.availability-selected-event {
  border: 3px solid theme(colors.sky.600);
}

.fc-view-container {
  position: relative;
}

button.fc-state-default {
  @apply bg-none;
}

.calendar-timeline {
  position: absolute;
  border-top: 2px solid red;
  border-bottom: none;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 99999 !important;
}

#rating-float-bar-trigger {
  float: left;
  padding-bottom: 10px;
  @apply w-full;
}
#rating-floating-bar-container {
  position: relative;
  height: 45px;
  margin-bottom: 60px;
}
#rating-float-bar-container {
  z-index: 999;
  position: sticky;
  top: 0;
  background-color: theme(colors.white);
}
#rating-float-bar {
  background-color: theme(colors.white);
}
#rating-float-bar .menu-progress {
}
#rating-float-bar .menu-progress-left {
  float: left;
  width: 140px;
}
#rating-float-bar .menu-progress-left .progress {
  width: 140px;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 20px;
}
#rating-float-bar .menu-progress-left .progress div {
  text-align: left;
  font-size: theme(fontSize.2xs);
  line-height: 14px;
}
#rating-float-bar .menu-current {
  float: left;
  margin-left: 5px;
}
#rating-float-bar .menu-current span {
  font-weight: bold;
  color: theme(colors.neutral.800);
  font-size: theme(fontSize.base);
}
#rating-float-bar .menu-description {
  float: left;
}
#rating-float-bar .menu-description span {
  font-size: theme(fontSize.sm);
  font-weight: bold;
}
#rating-float-bar .menu-all {
}
#rating-float-bar .menu-all a {
  @apply text-neutral-400 text-xs underline;
}

#vidcruiter-rating-global-progress {
  display: inline-block;
  margin-left: 10px;
  font-weight: bold;
  text-align: left;
}

.vidcruiter-rating-current-index {
  position: absolute;
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: center;
  font-weight: bold;
  top: 2px;
  color: theme(colors.neutral.400);
  @apply w-9 left-[-2.4rem] text-sm;
}
.vidcruiter-rating-current-index .number {
  padding-top: 2px;
  padding-bottom: 2px;
  border: none;
  border-left: 1px solid theme(colors.neutral.300);
  border-top: 1px solid theme(colors.neutral.300);
}
.vidcruiter-rating-avatar {
  width: 60px;
}

.ratings-person-time {
  margin-bottom: 0px;
  font-size: theme(fontSize.xs);
  color: theme(colors.neutral.400);
}

.vidcruiter-rating-panel-description {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

#ratings-wrapper .vidcruiter-rating-panel-description {
  width: 100%;
  max-width: 860px;
  overflow-x: auto;
}

#ratings-wrapper.ratings-sidebar-open .vidcruiter-rating-panel-description {
  width: 100%;
  max-width: 600px;
  overflow-x: auto;
}
.vidcruiter-rating-panel-description-video-response {
  width: 100%;
  max-width: 100%;
  text-align: center;
}

#rating-review-list-people {
  @apply flex-1;
}
#rating-review-list-people .review-name .name p {
  font-size: theme(fontSize.base);
  font-weight: bold;
  color: theme(colors.neutral.800);
  margin-bottom: 0px;
  margin-top: 3px;
}
#rating-review-list-people .review-name .box-v3-more-identifier {
  float: left;
  margin-left: 10px;
  margin-top: 10px;
  font-weight: normal;
  font-size: theme(fontSize.base);
  color: theme(colors.neutral.500);
}
#rating-review-list-sidebar {
  @apply w-52;
}
#rating-review-list-sidebar-mobile {
  display: none;
}
.new-note-form {
  position: relative;
}
#notes-list {
  margin-top: 10px;
  padding: 3px;
  width: 400px;
}
#notes-list .entry {
  margin-bottom: 10px;
  margin-left: 10px;
}
#notes-list .entry .avatar {
  float: left;
}
#notes-list .entry .note {
  float: left;
  margin-left: 10px;
  width: 320px;
}
#rating-vidcruiter-sideout {
  display: none;
}

.vidcruiter-rating-guide {
  width: 100%;
  font-size: theme(fontSize.sm);
}

#preview-locales-dropdown .dropdown-menu li > a:hover,
#preview-locales-dropdown .dropdown-menu li > a:focus,
#preview-locales-dropdown .dropdown-submenu:hover > a {
  color: theme(colors.neutral.700);
  background-color: theme(colors.neutral.200);
  text-decoration: none;
  background-position: 0 -15px;
  transition: background-position 0.1s linear;
  background-image: none;
  outline: 0;
  box-shadow:
    inset 0 2px 4px rgba(0, 0, 0, 0.15),
    0 1px 2px rgba(0, 0, 0, 0.05);
}

#preview-locales-submenu.dropdown-submenu > a:after {
  display: none;
}

#preview-locales-dropdown.dropdown-submenu:hover > a:after {
  display: none;
}

.vidcruiter-rating-guide .background {
  background-color: theme(colors.green.200);
}
.vidcruiter-rating-guide .info-list ul {
  margin-bottom: 0px;
  text-align: left;
}

.vidcruiter-rating-guide-mobile .info-list ul {
  text-align: left;
}
.vidcruiter-rating-guide td,
.vidcruiter-rating-guide th {
  text-align: center;
  padding: 6px;
}
.vidcruiter-rating-guide th.number {
  font-size: theme(fontSize.xl);
}
.vidcruiter-rating-guide .title {
  width: 100%;
  padding: 5px;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
}
.vidcruiter-rating-guide .description {
  width: 100%;
  color: theme(colors.neutral.700);
  background-color: theme(colors.neutral.100);
  padding: 5px;
  font-size: theme(fontSize.sm);
}
.vidcruiter-rating-guide-mobile .title {
  font-size: theme(fontSize.base);
}
.vidcruiter-rating-guide-mobile th.number {
  font-size: theme(fontSize.base);
  background-color: theme(colors.neutral.50);
}

.vidcruiter-show-regular {
  display: initial;
}
.vidcruiter-show-mobile {
  display: none;
}

table.vidcruiter-show-regular {
  display: table;
}
table.vidcruiter-show-mobile {
  display: none;
}

/* Removing the hidden text for the media player, this is causing issues in display so I am hiding it.  */
.mejs-offscreen {
  display: none !important;
}

@media (max-width: 350px) {
  .hide-stars-mobile .stars-container {
    display: none;
  }
}

.rating-step-link-container {
  display: table;
  overflow: hidden;
  float: left;
  margin: 10px;
  margin-top: 0px;
  margin-bottom: 20px;
  height: 100px;
  max-height: 100px;
  min-height: 100px;
  box-sizing: border-box;
}

.rating-step-link {
  @apply box-border flex flex-row items-center justify-center px-5 py-2 text-lg text-center border-b border-white;
  width: 285px;
  max-height: 100px;
  min-height: 100px;
}

@media (max-width: 668px) {
  .rating-step-link-container {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
  }

  .rating-step-link {
    width: 100%;
  }
}

.rating-indicator-button {
  width: 100%;
  padding: 10px;
}

.rating-indicator-button:hover {
  background-color: theme(colors.neutral.100);
  text-decoration: none;
}

/* career pages */
#job-board-language-selector-container {
  padding-bottom: 15px;
  text-align: left;
  width: 100%;
}

#job-board-language-selector {
  text-align: left;
  margin: auto;
  display: inline-block;
}

.languages-select {
  width: 150px;
  margin-left: 10px;
}

@media (max-width: 768px) {
  #job-board-language-selector-container {
    text-align: center;
  }
}

.aspectRatioSizer {
  display: grid;
}
.aspectRatioSizer > * {
  grid-area: 1 / 1 / 2 / 2;
}

/* dashboard activity timeline */
.activity-timeline-dropdown {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  vertical-align: bottom;
}
.activity-timeline-dropdown.users {
  max-width: 115px;
}
.activity-timeline-dropdown.business-process {
  max-width: 250px;
}
.activity-timeline-dropdown.questionnaire {
  max-width: 110px;
}
.activity-timeline-dropdown.date {
  max-width: 115px;
}
