.will-paginate {
  padding-top: 30px;
  padding-bottom: 0px;
  text-align: center;
  @apply text-xs;
}
.will-paginate .pagination {
  margin: auto;
  border-radius: 4px 4px 4px 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  display: inline-block;
  margin-bottom: 0;
  margin-left: 0;
}
.will-paginate .pagination a,
.will-paginate .pagination em,
.will-paginate .pagination span {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: theme(colors.white);
  border-color: theme(colors.neutral.300);
  border-image: none;
  border-style: solid;
  border-width: 1px 1px 1px 0;
  float: left;
  line-height: 20px;
  padding: 4px 12px;
  text-decoration: none;
  display: inline-block;
  color: theme(colors.neutral.500);
  font-weight: bold;
  background: theme(colors.white);
  background-image: linear-gradient(to top, theme(colors.neutral.100) 14%, theme(colors.white) 57%, theme(colors.white) 79%);
}
.will-paginate .pagination a:hover {
  cursor: pointer;
  color: var(--lightblue-highlight-text);
  border-right: 1px solid theme(colors.blue.200);
  text-shadow: 0 1px 0 theme(colors.blue.50);
  background: linear-gradient(to bottom, var(--lightblue-highlight-background) 0%, theme(colors.blue.100) 100%);
}
.will-paginate .pagination em,
.will-paginate .pagination span {
  color: theme(colors.neutral.700);
  cursor: default;
}
.will-paginate .pagination > :first-child {
  border-bottom-left-radius: 4px;
  border-left-width: 1px;
  border-top-left-radius: 4px;
}
.will-paginate .pagination > :last-child {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}