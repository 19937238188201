
.read-more {
    max-height: 120px;
	  min-height: 60px;
  	position: relative;
  	overflow: hidden;
}

.read-more .read-less-link {
	width: 100%;
	text-align: center;
}

.read-more .read-more-link { 
  position: absolute; 
  bottom: 0; 
  left: 0;
  width: 100%; 
  text-align: center; 
  margin: 0;
  padding: 30px 0 15px 0; 

  /* "transparent" only works here because == rgba(0,0,0,0) */  
  background-image: linear-gradient(to bottom, transparent, white);
}