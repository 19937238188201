.ms-container{
  background: transparent url('switch.png') no-repeat 50% 50%;
  width: 370px;
}

.ms-container:after{
  content: ".";
  display: block;
  height: 0;
  line-height: 0;
  font-size: 0;
  clear: both;
  min-height: 0;
  visibility: hidden;
}

.ms-container .ms-selectable, .ms-container .ms-selection{
  background: theme(colors.white);
  color: theme(colors.neutral.600);
  float: left;
  width: 45%;
}
.ms-container .ms-selection{
  float: right;
}

.ms-container .ms-list{
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border linear 0.2s, box-shadow linear 0.2s;
  border: 1px solid theme(colors.neutral.300);
  position: relative;
  height: 200px;
  padding: 0;
  overflow-y: auto;
}

.ms-container .ms-list.ms-focus{
  border-color: rgba(82, 168, 236, 0.8);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  outline: 0;
}

.ms-container ul{
  margin: 0;
  list-style-type: none;
  padding: 0;
}

.ms-container .ms-optgroup-container{
  width: 100%;
}

.ms-container .ms-optgroup-label{
  margin: 0;
  padding: 5px 0px 0px 5px;
  cursor: pointer;
  color: theme(colors.neutral.400);
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection{
  border-bottom: 1px theme(colors.neutral.200) solid;
  padding: 2px 10px;
  color: theme(colors.neutral.600);
  font-size: theme(fontSize.sm);
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover{
  cursor: pointer;
  color: theme(colors.white);
  text-decoration: none;
  background-color: theme(colors.sky.600);
}

.ms-container .ms-selectable li.disabled,
.ms-container .ms-selection li.disabled{
  background-color: theme(colors.neutral.200);
  color: theme(colors.neutral.400);
  cursor: text;
}