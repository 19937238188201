:root {
  --duet-color-primary: var(--focused-color);
  --duet-color-text: #333;
  --duet-color-text-active: #fff;
  --duet-color-placeholder: #666;
  --duet-color-button: #f5f5f5;
  --duet-color-surface: #fff;
  --duet-color-overlay: rgba(0, 0, 0, 0.8);
  --duet-color-border: rgb(204, 204, 204);
  --duet-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  --duet-font-normal: 400;
  --duet-font-bold: 600;
  --duet-radius: 4px;
  --duet-z-index: 600;
}

/* blocks a "#form-builder-right table" selector */
.duet-date__table {
  height: unset !important;
}

/* blocks a ".vidcruiter-form .vidcruiter-field .vidcruiter-input label" selector */
.duet-date__mobile-heading {
  @apply !inline-block;
  font-weight: var(--duet-font-bold) !important;
  @apply !m-0;
}

/* Apply the not allowed cursor over the calendar icon */
.duet-date__toggle[disabled]{
  @apply !cursor-not-allowed;
}

/* Adjust the height to match the other fields */
.duet-date__input{
  @apply h-9;
  @apply !cursor-pointer;
}

.duet-date__input[disabled]{
  @apply !cursor-not-allowed;
}

.duet-date__input[readonly]:not([disabled]){
  @apply !bg-white;
}

@media (min-width: 36em) {
  .duet-date__mobile-heading {
    @apply !hidden;
  }
}
