section.business-process-more-info {
  h6 {
    color: theme(colors.neutral.700);
    @apply text-2xs;
  }

  > .bpmi-header {
    background: theme(colors.neutral.100); border-bottom: 1px solid theme(colors.neutral.300);

    > .bpmi-overview {
      display: flex; padding: 15px 20px; width: 100%; box-sizing: border-box; align-items: center;

      .bpmi-status-container {
        display: flex; align-items: center; flex-grow: 1;

        .bpmi-status {
          display: flex; align-items: center;
          span {
            @apply text-xl ml-2.5;
          }
        }

        .bpmi-divider {
          height: 30px; border-left: 1px solid theme(colors.neutral.300); margin-left: 30px; margin-right: 30px;
        }

        h6 {
          @apply text-2xs text-neutral-700;
        }

        .bpmi-text {
          @apply text-sm font-bold;
        }
      }
    }

    > .bpmi-users-container {
      padding: 15px; padding-left: 10px; padding-right: 10px; padding-top: 5px; width: 100%; box-sizing: border-box;

      .bpmi-users {
        display: flex; border: 1px solid theme(colors.neutral.300); align-items: center; padding: 10px 15px; padding-right: 10px; background-color: theme(colors.white); border-radius: 10px;

        .bpmi-users-list {
          display: flex; align-items: center; flex-grow: 1;
        }
      }
    }
  }

  > .bpmi-people-container {
    display: flex; width: 100%; box-sizing: border-box; padding: 10px 10px;

    .bpmi-applicant-steps {
      flex-grow: 1;
    }

    .bpmi-folders {
      width: 25%;

      ul {
        list-style-type: none; margin-left: 0px; margin-top: 10px;

        li { margin-bottom: 5px; }
      }
    }
  }

  > .bpmi-links-container {
    width: 100%; padding: 15px 15px; box-sizing: border-box;

    .bpmi-links {
      margin-bottom: 20px;background-color: white;padding:  20px;border-radius: 10px;border:  1px solid theme(colors.neutral.300);

      &.no-margin-bottom { margin-bottom: 0px; }
    }
  }
}

section.application-links-container {
  h6 {
    color: theme(colors.neutral.700);
    @apply text-xs;
  }
  .alc-row {
    display: flex; width: 100%; padding: 5px 0px; box-sizing: border-box;

    .alc-link {
      flex-grow: 1;
      a {
        @apply text-xs;
      }
    }

    .alc-language {
      width: 100px;
      text-align: right;
      margin-left: 20px;
      margin-right: 20px;
      white-space: nowrap;
      font-weight: bold;
      box-sizing: border-box;
      @apply text-2xs;
    }
  }
}