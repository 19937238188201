.sidebar-dialog {
	height: 455px;
}

.sidebar-dialog .sidebar {
	height: 100%;
	background-color: theme(colors.neutral.50);
	border-right: 1px solid theme(colors.neutral.300);
	overflow-y: auto;
	@apply w-64;
}

.sidebar-dialog .main-panel {
	height: 100%;
	overflow-y: scroll;
	@apply flex-1;
}

.sidebar-dialog-footer {
	border-top: 1px solid theme(colors.neutral.300);
	padding: 10px;
}

.ui-dialog.workflow-builder-form-dialog {
	z-index: 10000 !important;
}

.ui-widget-overlay.ui-front.workflow-builder-form-dialog {
	z-index: 9999 !important;
}