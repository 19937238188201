.meows {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999999;
}

.meow {
  position: relative;
  margin: 20px 20px 0 0;
}

.meow .inner {
  width: 300px;
  min-height: 48px;
  padding: 10px;
    padding-top: 5px; padding-bottom: 5px;
  font-family: theme(fontFamily.sans);
  font-size: theme(fontSize.sm);
  color: theme(colors.neutral.700);
  background: theme(colors.white);
  font-weight: normal;
  -moz-hyphens:auto;
  -ms-hyphens:auto;
  -webkit-hyphens:auto;
  hyphens:auto;
  word-wrap:break-word;
  border: 2px solid transparent;
    border-radius: 5px;
  zoom: 1;
  box-shadow: 2px 2px 10px rgba(25, 25, 25, 0.25);
    display: inline-flex;
    align-items: center;
}

.meow .inner:after {
  display: block;
  height: 0;
  clear: both;
  line-height: 0;
  content: "\0200";
  visibility: hidden;
}

.meow.hover .inner {
  border: 2px solid theme(colors.white);
}

.meow .inner h1 {
  color: theme(colors.neutral.700);
  padding: 0;
  margin: 0;
  padding-bottom: 5px;
  font-size: theme(fontSize.xs);
  font-weight: bold;
  line-height: 1;
}

.meow .inner .content {
    flex: 1;
}

.meow .inner .icon {
  margin-right: 10px;
}

.meow .inner .icon img {
  max-width: 48px;
  max-height: 48px;
}

.meow .inner .close {
  display: none;
}

.meow.hover .inner .close {
  position: absolute;
  top: 6px;
  right: 6px;
  display: block;
  width: 18px;
  height: 18px;
  color: theme(colors.black);
}

.meow.-live  {
    margin: 10px 10px 0 0;
}

.meow .inner {
    font-size: theme(fontSize.sm);
    border-top-left-radius: 4px;
    width: 280px;
}

.meow .inner h1 {
    font-size: theme(fontSize.sm);
}

.meow .icon {
    margin-right: 10px;
    color: theme(colors.neutral.500);
    font-size: 32px;
}

.meow.-success .icon {
    color: theme(colors.green.600);
}

.meow.-warning .icon {
}

.meow.-error .icon {
    color: theme(colors.red.600);
}

.meow.-info .icon {
}

#live-meow-container-outer {
    position: relative;
}

#live-meow-container {
    position: absolute; margin-left: -320px; top: 5px; z-index: 9999991;
}

@media (max-width : 668px) {
    #live-meow-container-outer {
         position: fixed;
         top: 0px; left: 0px;
         width: 100%;
         box-sizing: border-box;
            z-index: 9999990;
     }

    #live-meow-container {
        left: 0px; top: 0px; width: 100%; text-align: center;
        margin-left: 0px;
    }

    .meow {
      margin: 10px 10px 0 0;
        width: 100%;
        text-align: center;
    }

    .meow .inner {
        width: 280px;
        text-align: left;
        margin: auto;
     }
 }