/* stylelint-disable value-no-vendor-prefix */

.vidcruiter-scoring-container {
  * {
    box-sizing: border-box;
  }

  .scoring-flex-row {
    display: -webkit-box; /* wkhtmltopdf uses this one */
    display:flex;
    width: 100%;
    border-radius: 4px;
    align-items: center;
    -webkit-box-pack: center; /* wkhtmltopdf uses this one */
    justify-content: center;
    box-sizing: border-box;
    background-color: inherit;

    /* see app/views/layouts/_dynamic_js.html.erb for application of tailwind classes dynamically */
    &.vidcruiter-scoring-field-row-error, &.vidcruiter-scoring-field-row-error:hover,
    &.-odd.vidcruiter-scoring-field-row-error, &.-odd.vidcruiter-scoring-field-row-error:hover { background: theme(colors.red.200) !important; color: theme(colors.red.900) !important; }

    &.-header {
      font-weight: bold;
      .scoring-title { font-weight: bold; }
    }

    .scoring-title {
      padding: 5px 10px;
      -webkit-box-flex: 1;
      /* Fix CB-3059 flex: 1 0 auto; */
      flex: 1;
      font-weight: normal;
      @apply text-xs;
    }

    label.scoring-radio, .scoring-header {
      width: 30px;
      padding: 10px;
      text-align: center;
      @apply text-sm;
    }

    label.scoring-radio {
      display: -webkit-box; /* wkhtmltopdf uses this one */
      display:flex;
      align-items: center;
      -webkit-box-pack: center; /* wkhtmltopdf uses this one */
      justify-content: center;
      margin-bottom: 0px;
      text-align: left;

      span { display: none; }
      input { width: initial; margin-bottom: 0px;}
    }
  }

  .scoring-table-row {
    border-radius: 4px;
    background-color: #ffffff;
    background-color: inherit;

    &.vidcruiter-scoring-field-row-error, &.vidcruiter-scoring-field-row-error label, &.vidcruiter-scoring-field-row-error:hover,
    &.-odd.vidcruiter-scoring-field-row-error, &.-odd.vidcruiter-scoring-field-row-error:hover { background: theme(colors.red.200) !important; color: theme(colors.red.900) !important; }

    &.-header {
      font-weight: bold;
      width: 100%;
      .scoring-title { font-weight: bold; }
    }
    &.-odd { background-color: theme(colors.neutral.100); }

    th {
      background-color: #ffffff;
    }

    .scoring-title {
      padding: 5px 10px;
      font-size: theme(fontSize.sm);
      font-weight: normal;
      line-height: 22px;
    }

    label.scoring-radio, .scoring-header {
      padding: 10px;
      text-align: center;
    }

    label.scoring-radio {

      margin-bottom: 0px;

      input { width: auto; width: initial; margin-bottom: 0px;}
    }
  }

}

@define-mixin vidcruiter-scoring-container-small {
    .scoring-flex-row {
      flex-direction: column;
      background-color: initial;
      padding-left: 20px; padding-right: 20px;

      &.-odd { background-color: initial; }
      &:hover, &.-odd:hover { background-color: inherit; }

      .scoring-header { display: none; }

      label.scoring-radio {
        width: 100%;
        justify-content: left;
        background-color: theme(colors.neutral.100);
        padding: 5px;
        border-radius: 4px;
        margin-bottom: 5px;
        input { margin-left: 5px; }
        span { flex-grow: 1; text-align: left; display: initial; margin-left: 5px; }
      }

      .scoring-title {
        text-align: left;
        width: 100%;
        font-size: theme(fontSize.sm);
        line-height: 26px;
      }

      &.-header {
        .scoring-title { font-weight:bold; }
      }
    }
}

@media (max-width : 768px) {
  .vidcruiter-scoring-container {
    @mixin vidcruiter-scoring-container-small;
  }
}

.vidcruiter-scoring-container.-small {
  @mixin vidcruiter-scoring-container-small;
}
