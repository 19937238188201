@import "../../../config/config_vidcruiter";

#position-status-nav {
  display: flex;
  width: 100%;
  margin-top: 0px; margin-right: 0px; padding-bottom: 0px;
  flex-direction: row;

  > a, > div {
    flex: 1 1 0;
    width: 0;
  }

  .position-status-nav-item {
    display: block;
    text-align: left;
    border-right: 1px solid var(--color-gray-border);
    border-top: 4px solid var(--color-background-lightgray);
    @apply px-2.5 py-2;
  }

  > a.-first, .position-status-nav-item.-first {
    border-radius: 5px 0px 0px 5px;
  }

  > a.-last,.position-status-nav-item.-last {
    border-radius: 0px 5px 5px 0px;
    border-right: none;
  }

  a .position-status-nav-item:hover {
    text-decoration: none;
    cursor: pointer;

    color: var(--color-lightblue-highlight-text);
    background: var(--color-lightblue-highlight-background);

    p { color: theme(colors.black); }
    h6 { color: var(--color-lightblue-highlight-text); }
  }

  .position-status-nav-item.-green {
    border-top: 4px solid var(--color-default);
  }

  .position-status-nav-item.-gray {
    border-top: 4px solid var(--color-background-lightgray);
  }

  h6 {
    text-transform: none;
    line-height: 15px;
  }

  p {
    margin-bottom: 0px;
    color: var(--color-dark-gray);
    @apply text-sm;
  }

  .position-status-nav-item.-active {
    border-top-color: var(--color-default);

    h6 { color: var(--color-default); }
    p { font-weight: bold; color: theme(colors.black); }
  }

  .position-status-nav-item.-current {
    border-top-color: var(--color-current-step);
    h6 { color: var(--color-current-step); }
  }

  > a:active {
    .position-status-nav-item {
      p { color: theme(colors.black); }
      h6 { color: theme(colors.black); }
    }
  }

  > a:hover {
    text-decoration: none;

    .position-status-nav-item.-gray {
      border-top-color: var(--color-lightblue-highlight-border);
    }
  }

  a {
    .position-status-nav-item.-done {
      border-top-color: var(--color-default);
    }
  }
}

#step-bottom-navigation {
  a:hover { text-decoration: none; }

  .step-bottom-nav-item {
    width: 20px; height: 20px; background-color: theme(colors.neutral.200); color: white; font-weight: normal; padding: 4px; text-align: center; border: 2px solid theme(colors.neutral.200);
    @apply text-lg;
  }

  .step-bottom-nav-done {
    background-color: var(--color-default); border: 2px solid var(--color-default);
  }

  .step-bottom-nav-current {
    background-color: var(--color-current-step); border: 2px solid var(--color-current-step);
  }

  .step-bottom-nav-active {}
}
