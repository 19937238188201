
.vidcruiter-form-header a.help, a.help {
  @apply text-sm !text-neutral-500;
}

.icon-link,
.help.icon-link,
.dark-gray-link.icon-link {
  @apply text-lg;
}
