.icon-circle-container {
  background-color: gray; color: white; border-radius: 999px; display: inline-block; font-size: theme(fontSize.2xs); font-weight: bold; line-height: 0; margin-right: 5px; padding: 5px; text-align: center; width: 10px;
}

.icon-circle-container-large {
  font-size: theme(fontSize.sm); padding: 6px; width: 13px;
}

.icon-circle-container.-small {
  font-size: 8px; padding: 2px; width: 8px;
}

.icon-circle-container-blue {
  background-color: theme(colors.sky.600);
}

.icon-circle-container-green {
  background-color: theme(colors.vidgreen);
}

.icon-circle-container-yellow {
  background-color: theme(colors.amber.500);
}

.icon-circle-container-red {
  background-color: theme(colors.red.500);
}

.icon-color-yellow {
  color: theme(colors.amber.500);
}

.icon-color-blue {
  color: theme(colors.sky.600);
}
