@import "../config/config_vidcruiter";

.vidcruiter-form {
	background-color: white;
    padding-bottom: 15px;
}

.vidcruiter-form p {
  font-size: 16px; /* This should not be responsive because TinyMCE uses px */
  line-height: 24px;
}

.vidcruiter-form .vidcruiter-field {
	margin-top: 20px;
}

.vidcruiter-form-small .vidcruiter-field {
  margin-top: 15px;
}

.vidcruiter-form-small .vidcruiter-rating-field {
  max-width: 850px;
}

/* These are custom css field utils */
.vidcruiter-form .custom-css-hide .vidcruiter-field {
  display: none;
}

.vidcruiter-form .vidcruiter-field .vidcruiter-label, .vidcruiter-form .vidcruiter-field .vidcruiter-label label {
    color: theme(colors.neutral.700);
    font-size: theme(fontSize.base);
    font-weight: bold;
    letter-spacing: 0.4px;
    margin-top: 0;
    margin-bottom: 5px;
    text-align: left;
}

.vidcruiter-form-small .vidcruiter-field .vidcruiter-label {
  font-size: theme(fontSize.xs);
  margin-bottom: 2px;
}

.vidcruiter-form .vidcruiter-field .vidcruiter-input {
	margin-bottom: 0px;
  margin-top: 0px;
}

.vidcruiter-form .vidcruiter-field .vidcruiter-input input[type=text]:not(.dropdown-input),
.vidcruiter-form .vidcruiter-field form input[type=text]:not(.dropdown-input),
.vidcruiter-form .vidcruiter-field .vidcruiter-input input[type=tel],
.vidcruiter-form .vidcruiter-field form input[type=tel],
.vidcruiter-form form #phone_call_out_number,
.vidcruiter-form .vidcruiter-field .vidcruiter-input input[type=date]
{
  width: 340px;
  font-size: theme(fontSize.base);
  padding: 8px;
  margin-bottom: 0px;
}

.vidcruiter-form .vidcruiter-field .vidcruiter-input input[type=checkbox], .vidcruiter-form .vidcruiter-field form input[type=checkbox] {
    min-height: 18px;
    padding-left: 18px;
}

.vidcruiter-form .vidcruiter-field .vidcruiter-input label {
    @apply block text-base mb-1.5;
}

.vidcruiter-form .vidcruiter-date, .vidcruiter-date {
  padding: 8px !important;
  width: 220px !important;
  cursor: pointer !important;
  /* margin-right: 40px !important; */
  /* height: 20px !important; */
}

.vidcruiter-form .vidcruiter-field .vidcruiter-help-text, .help-text {
  color: theme(colors.neutral.800);
  font-size: theme(fontSize.xs);
}

.vidcruiter-form .vidcruiter-field .vidcruiter-input textarea, .vidcruiter-form .vidcruiter-field form textarea {
  box-sizing: border-box;
	width: 100%;
	height: 165px;
	font-size: theme(fontSize.base);
	padding: 10px;
    margin-bottom: 0px;
}

.vidcruiter-form-small .vidcruiter-field .vidcruiter-input textarea, .vidcruiter-form-small .vidcruiter-field form textarea {
  font-size: theme(fontSize.xs);
  padding: 8px;
  height: 100px;
}

.vidcruiter-form .vidcruiter-field .vidcruiter-header {
    font-family: theme(fontFamily.sans);
    font-size: theme(fontSize.lg);
    font-weight: 300;
    color: theme(colors.neutral.700);
}

.vidcruiter-form .vidcruiter-field .vidcruiter-description {
	color: theme(colors.neutral.600);
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; /* This should be the same as TinyMCE default */
	font-size: 16px; /* This should not be responsive because TinyMCE uses px */
	line-height: 24px; 
}

.vidcruiter-form .vidcruiter-month {
	width: 100px;
}

.vidcruiter-form .vidcruiter-day {
	width: 50px;
}

.vidcruiter-form .vidcruiter-year {
	width: 70px;
}

.vidcruiter-form .vidcruiter-field-date .vidcruiter-help-text {
	margin-top: -5px;
}

.vidcruiter-form .vidcruiter-field-date .vidcruiter-input input[type="text"], .vidcruiter-form .vidcruiter-field-date form input[type="text"], input.field-calendar {
  width: 180px;
  cursor: pointer;
  position: relative;
  /* z-index: 1000; */
}

.vidcruiter-form .vidcruiter-field-date .vidcruiter-input .clear-date {
  margin-left: 15px;
}

.ui-datepicker-trigger {
  position: absolute;
  left: 180px;
  cursor: pointer;
  /* z-index: 1000; */
}

.vidcruiter-form .vidcruiter-field-date .vidcruiter-input {
  position: relative;

}

.vidcruiter-form .vidcruiter-field-video .vidcruiter-help-text {
	margin-top: 10px;
}

.vidcruiter-form .vidcruiter-required, span.vidcruiter-required {
	color:theme(colors.red.800);
	font-size: theme(fontSize.lg);
}

.vidcruiter-form .vidcruiter-ranking-items {
  list-style-type: none;
  margin-left: 0px;
}

.vidcruiter-form .vidcruiter-ranking-items li {
  border: 1px dotted theme(colors.neutral.300);
  padding: 10px;
  background-color: white;
  margin-bottom: 5px;
  cursor: move;
  @apply text-base;
}

.vidcruiter-form .vidcruiter-ranking-items li:hover {
    border: 1px solid theme(colors.neutral.300);
}

.vidcruiter-form .vidcruiter-ranking-items .index-number {
  color: theme(colors.neutral.500); padding-right: 10px; height: 23px;
}

.vidcruiter-form .vidcruiter-ranking-items .resize-vertical {
/*  display: none;
*/}

.vidcruiter-form .vidcruiter-ranking-items li:hover .resize-vertical {
/*  display: block;
*/}

/* Inputs */

#vidcruiter-video-response {
  position: fixed; top: 0; width: 100%; background-color: white; text-align: center; z-index: 999999;
}

#vidcruiter-video-response-content {
  padding-bottom: 0px;
    padding-top: 0px;
    overflow-x: hidden;
    overflow-y: auto;
}

.vidcruiter-job-item {
  padding: 30px; padding-bottom: 12px; padding-top: 12px;
}

.vidcruiter-job-item-first {
  border-top: none;
}

.vidcruiter-job-item:hover {
  background-color: theme(colors.neutral.50);
}

.vidcruiter-job-board {
  background-color: white; padding-bottom: 20px;
}

.vidcruiter-title {
  background-color: theme(colors.cyan.700); color: theme(colors.white); font-size: theme(fontSize.xl); padding: 15px; padding-left: 15px;
}

.vidcruiter-h1 {
    font-size: theme(fontSize.lg);
    font-weight: normal;
    line-height: 24px;
}

.vidcruiter-h2 {
    font-size: theme(fontSize.sm);
    font-weight: bold;
    line-height: 24px;
}

.vidcruiter-h2-position-title {
    font-size: theme(fontSize.lg);
    font-weight: bold;
    line-height: 18px;
}

.vidcruiter-interview-form .vidcruiter-title {
  font-size: theme(fontSize.lg);
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 24px;
}

.vidcruiter-language-selector button {
  background: none!important;
  border: none;
  padding: 0!important;
  font-size: theme(fontSize.sm);
  color: inherit;
}

.vidcruiter-language-selector .vidcruiter-language-selector-dropdown a {
  font-size: theme(fontSize.sm);
  color: theme(colors.black);
  text-decoration: none;
}


.vidcruiter-job-board-header {
  padding: 15px; padding-left: 15px;
}

.vidcruiter-job-board-header-description {
  font-size: theme(fontSize.base); color: theme(colors.neutral.500); padding: 3px;
}

.vidcruiter-job-item-title {
  font-size: theme(fontSize.lg); color: theme(colors.cyan.700); font-weight: normal; margin-bottom: 0px; line-height: 20px;
}

.vidcruiter-job-item-title a {
  text-decoration: underline;
}

.vidcruiter-job-item-description-title {
  font-size: theme(fontSize.sm); color: theme(colors.neutral.800); margin-top: 0px; margin-bottom: 0px; line-height: 16px; font-weight: bold;
}

.vidcruiter-job-item-description-text {
  font-size: theme(fontSize.sm); margin-top: 10px; margin-bottom: 0px; line-height: 16px;
}

.vidcruiter-job-board-search-container {
  margin: 20px; border: 1px solid theme(colors.neutral.300);  border-radius: 4px;
}

.vidcruiter-job-board-search-title {
  font-weight: bold; color: theme(colors.neutral.900); font-size: theme(fontSize.base); padding: 10px;
}

.vidcruiter-job-board-search-form {
  padding-left: 15px; padding-top: 15px; background-color: white;
}

.vidcruiter-job-board-search-form input[type=text] {
  font-size: theme(fontSize.sm); padding: 3px; padding-left: 10px; padding-right: 10px; width: 300px; margin-bottom: 0px;
}

.vidcruiter-job-board-search-form input[type=submit] {
  font-weight: bold;
}

.vidcruiter-job-board-search-form-help-text {
  color: theme(colors.neutral.400); padding-left: 5px; padding-top: 5px;
}

.vidcruiter-profile-title {
  background: theme(colors.neutral.200); color: theme(colors.neutral.400); margin-top: 0px; margin-bottom: 0px; padding: 17px; padding-top: 6px; padding-bottom: 6px; font-size: theme(fontSize.xl);
}

.vidcruiter-divider {
  width: 100%; border-top: 1px solid theme(colors.neutral.200); margin-top: 0px; margin-bottom: 0px;
}

.vidcruiter-interview-header {
  background-color: theme(colors.neutral.200); font-size: theme(fontSize.sm); font-weight: bold; padding-top: 8px; padding-bottom: 8px; color: theme(colors.neutral.600);
}

.vidcruiter-interview-header-dark {
  background-color: theme(colors.neutral.200); font-size: theme(fontSize.sm); font-weight: bold; padding-top: 8px; padding-bottom: 8px; color: theme(colors.neutral.500);
}

.vidcruiter-interview-subtitle {
  font-size: theme(fontSize.sm); line-height: 22px;
}

.vidcruiter-field-errors {
	color: theme(colors.red.700);
}

.vidcruiter-scoring {
  margin-top: 15px;
  width: 100%;

  border-spacing: 0px;
  border-collapse: collapse;
}

.vidcruiter-form-small .vidcruiter-scoring {
  margin-top: 0px;
}

.vidcruiter-scoring-score {
  width: 30px;
}

.vidcruiter-scoring td, .vidcruiter-scoring th {
  padding: 5px;
  font-size: theme(fontSize.xs);
  vertical-align: middle;
  text-align: center;
}

.vidcruiter-form-small .vidcruiter-scoring td, .vidcruiter-form-small .vidcruiter-scoring th {
  padding: 4px;
  font-size: theme(fontSize.xs);
}

.vidcruiter-scoring tr.even {
  background-color: theme(colors.white);
}

.vidcruiter-scoring tr.odd {
  background-color: theme(colors.neutral.100);
}


.vidcruiter-scoring tr:hover {
    background-color: theme(colors.slate.200);
}

.vidcruiter-scoring tr.vidcruiter-scoring-field-row-error, .vidcruiter-scoring tr.vidcruiter-scoring-field-row-error:hover {
  background-color: theme(colors.red.200); color: theme(colors.red.900);
}

.vidcruiter-answers-applicant-step-name {
  font-weight: bold; background-image: url('v3_paper.jpg'); border-bottom: 1px solid theme(colors.neutral.300); border-top: 1px solid theme(colors.neutral.300); color: theme(colors.neutral.700); padding: 15px;
  @apply text-base;
}

.vidcruiter-answers-applicant-step-name > span {
  color: theme(colors.cyan.700);
}

.vidcruiter-answers-number {
  text-align: center; font-size: theme(fontSize.2xl); font-weight: bold; color: theme(colors.neutral.300); background-color: theme(colors.white); border-right: none; border-bottom: 1px solid theme(colors.neutral.300);
}

.vidcruiter-answers-question-page-container {
  border-top: 1px dashed theme(colors.neutral.300); border-bottom: 1px solid theme(colors.neutral.300); font-weight: normal; padding: 10px 8px;
  @apply text-sm;
}

.vidcruiter-answers-question-page-name {
  width: 100%; padding-bottom: 6px; padding-top: 5px; font-weight: bold; color: theme(colors.slate.600);
  @apply text-base;
}

.vidcruiter-answers-question-page-timestamp, .vidcruiter-answers-question-page-timestamp-references {
  float: right; margin-right: 10px;
  @apply text-xs;
}

.answer-filter-value {
  @apply mb-2.5;
}

.vidcruiter-answers-label {
  @apply py-2 pl-0 pr-5 text-sm;
  overflow-wrap: anywhere;
}

.vidcruiter-answers-label-first, .vidcruiter-answers-answer-first {
  border-top: 1px dashed theme(colors.neutral.300);
}

.vidcruiter-answers-answer {
  padding: 8px;
  word-wrap: break-word;
  word-break: break-word;
}

.vidcruiter-linkedin {
  text-align: left; margin-left: 40px; margin-top: 20px; margin-right: 40px;
}

.vidcruiter-linkedin p {
  margin-top: 10px;
}


.vidcruiter-full-page-custom-design #content {
  padding: 0px !important;
}

.vidcruiter-ratings-showing-all {
  @apply bg-sky-100 p-1.5 text-center text-xs;
}

#vidcruiter-form {
  width: 100%; text-align: center; padding-top: 40px; padding-bottom: 40px;
}

#vidcruiter-form-content {
  width: 960px; margin: auto; text-align: left;
}

.vidcruiter-form-field-set-container {
  padding-left: 40px; padding-right: 40px;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
/* Styles */
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 321px) {
/* Styles */
}

.vidcruiter-public-profile-section-title { color: theme(colors.neutral.700); font-weight: bold; font-size: theme(fontSize.sm); padding: 10px; margin-bottom: 15px;
    border-bottom: 1px solid theme(colors.neutral.300);
    border-top: 1px solid theme(colors.neutral.300);
    background-color: theme(colors.neutral.100);
    text-align: left;
    background-image: url('bg_texture.png');
}

.vidcruiter-public-profile-section-title.margin-bottom-0 {
    margin-bottom: 0px;
}

.vidcruiter-public-profile-section-title .vidcruiter-public-profile-section-timestamp, .vidcruiter-form .vidcruiter-public-profile-section-title .vidcruiter-public-profile-section-timestamp {
    float: right; text-align: right; font-weight: bold; color: theme(colors.neutral.500); font-size: theme(fontSize.xs);
}

.vidcruiter-public-profile-section-title .vidcruiter-public-profile-section-timestamp p, .vidcruiter-form .vidcruiter-public-profile-section-title .vidcruiter-public-profile-section-timestamp p {
    line-height: 16px; font-size: theme(fontSize.xs); color: theme(colors.neutral.500); font-weight: normal; margin-bottom: 0px;
}

.vidcruiter-public-profile-section-subtitle {
  margin: 10px; background-color: theme(colors.neutral.200); display: inline-block; padding: 5px; padding-top: 2px; padding-bottom: 2px;
}

.vidcruiter-public-profile-section-timestamp {
  display: inline-block; padding: 5px; padding-top: 2px; padding-bottom: 2px; padding-right: 5px;
}


.vidcruiter-public-profile-section-subtitle .darker {
  color: theme(colors.neutral.700);
  margin-right: 5px;
}

#vidcruiter-public-profile-title {
  background-color: theme(colors.cyan.700); color: theme(colors.white); padding: 15px; padding-left: 15px;
  font-size: theme(fontSize.lg);
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  line-height: 24px;
}

#vidcruiter-public-profile-applicant-header {
  display:flex;

  #vidcruiter-public-profile-applicant-status {
    width: 200px; padding-left: 20px;
  }

  #vidcruiter-public-profile-applicant-tags {
    width: 250px; text-align: right; flex-grow: 1;
  }
}

#vidcruiter-public-profile-applicant {
  margin-bottom: 20px;
}

#vidcruiter-public-profile-applicant .avatar img {

}

#vidcruiter-public-profile-applicant .info {
  margin-left: 15px;
}

#vidcruiter-public-profile-applicant .info .name { font-size: theme(fontSize.xl); margin-bottom: 0px; font-weight: 200;
}

#vidcruiter-public-profile-applicant .info .email {
  margin-bottom: 5px; margin-top: 0px; line-height: 18px;
}

#vidcruiter-public-profile-applicant .info .email a {
  font-size: theme(fontSize.sm);
}

#vidcruiter-public-profile-applicant .info .date {
  font-size: theme(fontSize.xs); font-style: italic; color: theme(colors.neutral.500); font-weight: normal; margin-bottom: 5px; line-height: 18px;
}

.vidcruiter-public-profile-attachment {
  padding-bottom: 10px;
}

.vidcruiter-public-profile-attachment .image {

}


.vidcruiter-public-profile-attachment .info {
  margin-left: 10px; padding-right: 10px;
}

.vidcruiter-public-profile-attachment .info p {
  line-height: 16px;
}

.vidcruiter-public-profile-attachment .info a {
  font-size: theme(fontSize.xs);
}

.vidcruiter-public-profile-question-page-title .index {
  background-color: theme(colors.neutral.300); padding: 5px; padding-left: 10px; padding-right: 10px; font-weight: bold;
}

.vidcruiter-public-profile-question-page-title .name {
  width: 80%; padding: 5px; padding-left: 10px; padding-right: 10px; background-color: theme(colors.neutral.200); font-weight: bold;
}

.vidcruiter-public-profile-question-page-title .timestamp {
  width: 20%; text-align: right; padding: 5px; padding-right: 10px; background-color: theme(colors.neutral.200); font-weight: bold;
}

.vidcruiter-public-profile-question-page-answer {
  border-bottom: 1px dotted theme(colors.neutral.300);
  overflow-wrap: anywhere;
}

.vidcruiter-public-profile-question-page-answer .question {
  width: 50%; float: left; padding: 10px; /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */

}

.vidcruiter-public-profile-question-page-answer .answer {
  width: 50%; float: left; padding: 10px;
  word-break: break-word; /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.vidcruiter-public-profile-question-page-answer .answer .mejs__container {
    max-width: 100%;
    margin: auto;
}

.vidcruiter-public-profile-question-page-answer .question .description {
  width: 370px; max-width: 370px; overflow: hidden; font-style: italic;
  font-size: theme(fontSize.xs); color: theme(colors.neutral.500);
}

.vidcruiter-public-profile-no-answer {
  padding: 10px; color: theme(colors.neutral.400);
}

.vidcruiter-job-board-individual-container {
  padding: 30px;
}

.vidcruiter-job-board-individual-container .vidcruiter-job-board-individual-header-content {
    width: 100%;
}

.vidcruiter-job-board-individual-container .vidcruiter-job-item-title {
  font-size: theme(fontSize.2xl); font-weight: bold; color: theme(colors.neutral.900);
}

.vidcruiter-job-board-individual-content {
  margin-top: 40px;
}

.vidcruiter-job-board-individual-content .apply-now {
  font-weight: bold; font-size: theme(fontSize.base); margin-bottom: 5px;
}

.vidcruiter-job-board-individual-content .apply-now-content {
  color: theme(colors.neutral.400); font-size: theme(fontSize.xs); line-height: 18px; margin-bottom: 10px;
}

.vidcruiter-job-board-individual-header {
    display: flex; width: 100%; align-items: start; box-sizing: border-box;
}

.vidcruiter-job-board-individual-header .vidcruiter-job-board-apply-now-top {
    margin-left: 20px;
}

.vidcruiter-job-board-individual-header .vidcruiter-job-board-apply-now-top .btn,
.vidcruiter-job-board-apply-now-bottom .btn {
    min-width: 200px;
}

@media (max-width : 668px) {
  #vidcruiter-public-profile-applicant-header {
    flex-direction: column;


    #vidcruiter-public-profile-applicant-status {
      box-sizing: border-box;
      width: 100%; padding-left: 0px;
      padding: 5px 20px;
    }

    #vidcruiter-public-profile-applicant-tags {
      width: 100%; text-align: left;
      padding: 5px 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
      .tag-list-item-container {
        display: block;

        .tag-list-item {
          float: left;
        }
      }
    }
  }



  .vidcruiter-job-board-individual-header {
        flex-direction: column;
    }

    .vidcruiter-job-board-individual-header .vidcruiter-job-board-apply-now-top .btn, .vidcruiter-job-board-apply-now-bottom .btn {
        width: 100%;
    }

    .vidcruiter-job-board-individual-header .vidcruiter-job-board-apply-now-top {
        margin-left: 0px; margin-bottom: 30px;
    }

    .vidcruiter-job-board-apply-now-top {
        width: 100%;
    }


    .vidcruiter-job-board-individual-header .vidcruiter-job-board-individual-header {
        width: 100%;
    }
}

.vidcruiter-note { width: 100%; border-bottom: 1px dotted theme(colors.neutral.200); margin-bottom: 15px; margin-top: 10px; }
.vidcruiter-note-avatar { float: left; margin-right: 15px; width: 50px; height: 50px; }
.vidcruiter-note-avatar img {width: 50px; height: 50px; margin-right: 10px;}
.vidcruiter-note-small .vidcruiter-note-avatar { width: 30px; margin-left: 10px; margin-top: 10px; }
.vidcruiter-note-small .vidcruiter-note-avatar img { width: 30px; height: 30px; }
.vidcruiter-note-content { padding-bottom: 10px; padding-top: 4px; width: 100%; line-height: 21px; font-size: theme(fontSize.base); word-wrap: break-word }
.vidcruiter-note-small .vidcruiter-note-content { width: 180px; font-size: theme(fontSize.xs); line-height: 18px; padding-top: 5px; padding-bottom: 5px; }
.vidcruiter-note-info { float: left; width: 250px; font-size: theme(fontSize.sm); color: theme(colors.neutral.500); text-align: right; margin-top: 0px; }
.vidcruiter-note-info-date { margin-top: 0px; margin-bottom: 3px; }
.vidcruiter-note-answers .vidcruiter-note-content { width: 520px; }
.vidcruiter-note-answers .vidcruiter-note-info { margin-top: 20px; }

/* Smartphones (portrait) ----------- */
/* @media only screen and (max-width : 320px) { */
/*  @media only screen and (min-device-width : 320px) and (max-device-width : 668px) {*/

  @media (max-width : 768px) {

      .vidcruiter-note-avatar {
          float: left;
          margin-right: 10px;
          margin-top: 5px;
      }

      .vidcruiter-note-avatar img {
          width: 35px;
          height: 35px;
      }

      .vidcruiter-note-info {
          font-size: theme(fontSize.xs);
          text-align: left;
          margin-top: -20px;
          margin-left: 45px;
          margin-bottom: 10px;
      }

      .vidcruiter-note-info p {
          font-size: theme(fontSize.xs);
      }

      .vidcruiter-note-info-date {
          display: inline-block;
          margin-right: 10px;
      }

      .vidcruiter-note-content {
          width: 230px;
          font-size: theme(fontSize.xs);
      }

      .vidcruiter-note-content p {
          font-size: theme(fontSize.xs);
      }

      /* Styles */
      #vidcruiter-form-content {
          width: 100%;
      }

      .vidcruiter-form .vidcruiter-field .vidcruiter-input input[type="text"],
      .vidcruiter-form .vidcruiter-field form input[type="text"],
      .vidcruiter-form .vidcruiter-field .vidcruiter-input input[type="tel"],
      .vidcruiter-form .vidcruiter-field form input[type="tel"]
      {
          width: 100% !important; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
          box-sizing: border-box; /* Opera/IE 8+ */
          height: 31px;
          padding-bottom: 0;
          padding-top: 0;
      }

      .vidcruiter-form .vidcruiter-field .vidcruiter-input input[type="date"]
      {
        width: 100% !important; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
        box-sizing: border-box; /* Opera/IE 8+ */
        font-size: theme(fontSize.base);
        padding-bottom: 0;
        padding-top: 0;
        padding: 5px;
        height: 31px;
        display:block;
        appearance: none;
        -webkit-min-logical-width: calc(100% - 40px);
        min-height: 1.2em;
      }

    .vidcruiter-form .vidcruiter-field .vidcruiter-input textarea, .vidcruiter-form .vidcruiter-field form textarea {
          width: 100%; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
          box-sizing: border-box; /* Opera/IE 8+ */
      }

      .vidcruiter-form-field-set-container {
          padding-left: 20px;
          padding-right: 20px;
      }

      .vidcruiter-form select {
          width: 100%;
      }

      .vidcruiter-title {
          font-size: theme(fontSize.base);
          padding: 10px;
          padding-left: 10px;
      }

      .vidcruiter-job-board-header {
          padding: 10px;
          padding-left: 10px;
      }

      .vidcruiter-interview-header {
          padding-top: 4px;
          padding-bottom: 4px;
      }

      .vidcruiter-form .vidcruiter-field-date .vidcruiter-input input[type="text"], .vidcruiter-form .vidcruiter-field-date form input[type="text"], input.field-calendar {
          width: 100%;
          cursor: pointer;
          position: relative;
          height: 38px;
          /* z-index: 1000; */
      }

      .vidcruiter-form .vidcruiter-field-date .vidcruiter-input .clear-date {
          margin-left: 0px;
      }

      .ui-datepicker-trigger {
          right: 0px;
          left: auto;
      }

      /* Public Profile */
      #vidcruiter-public-profile-menu {
          display: none;
      }

      #vidcruiter-public-profile-applicant .avatar img {
          width: 50px;
          height: 50px;
          padding-left: 0px;
      }

      #vidcruiter-public-profile-applicant .info {

      }

      #vidcruiter-public-profile-applicant .info .name {
          font-weight: bold;
          font-size: theme(fontSize.base);
          line-height: 20px;
      }

      #vidcruiter-public-profile-applicant .info .email a {
          font-size: theme(fontSize.xs);
      }

      #vidcruiter-public-profile-applicant .info .date {
          font-size: theme(fontSize.2xs);
      }

      #vidcruiter-public-profile-title {
          font-size: theme(fontSize.sm);
          padding: 6px;
          margin-bottom: 15px;
          text-align: center;
      }

      .vidcruiter-public-profile-section-title {
          text-align: center;
          margin-bottom: 10px;
          padding: 6px;
      }

      .vidcruiter-public-profile-section-title-name {
          width: 100%;
      }

      .vidcruiter-public-profile-section-title .vidcruiter-public-profile-section-timestamp {
          float: right;
          text-align: center;
          width: 100%;
      }

      .vidcruiter-public-profile-section-title .vidcruiter-public-profile-section-timestamp p {
          line-height: 16px;
          font-size: theme(fontSize.xs);
          color: theme(colors.neutral.500);
          font-weight: normal;
          margin-bottom: 0px;
      }

      .vidcruiter-public-profile-section-title .vidcruiter-public-profile-section-timestamp p, .vidcruiter-form .vidcruiter-public-profile-section-title .vidcruiter-public-profile-section-timestamp p {
        text-align: center;
    }

      .vidcruiter-answers-question-page-timestamp-references {
          width: 100%;
          padding: 10px;
      }
      .vidcruiter-answers-question-page-timestamp-references p {
        margin-left: 10px;
      }

      .vidcruiter-public-profile-attachment {
    padding-bottom: 5px;
  }

  .vidcruiter-public-profile-attachment .image {
    margin-left: 10px;
  }

  .vidcruiter-public-profile-attachment .info {
    width: 200px; font-size: theme(fontSize.xs);
  }

  .vidcruiter-public-profile-question-page-answer .question {
    width: 100%;
    padding-bottom: 0px;
  }

  .vidcruiter-public-profile-question-page-answer .answer {
    width: 100%;
    padding-top: 0px;
  }

  .vidcruiter-public-profile-question-page-answer .question .description {
    width: 100%; padding-bottom: 5px;
  }



  .vidcruiter-job-board-search-container {
    padding-bottom: 20px;
    margin: 10px;
    margin-bottom: 20px;
  }

  .vidcruiter-job-board-title {
    text-align: center;
  }

  .vidcruiter-job-board-search-title {
    text-align: center; font-size: theme(fontSize.sm); padding: 6px; margin-bottom: 5px;
  }

  .vidcruiter-job-board-search-form {
    width: 280px;
  }

  .vidcruiter-job-board-search-form-input {
    width: 280px;
  }

  .vidcruiter-job-board-search-form input[type="text"] {
    width: 240px;
  }

  .vidcruiter-job-board-search-form input[type="submit"] {
    position: absolute; top: -9999px;
  }
}

.v3-approve-check {
  display: block; margin-top: 0px; width: 50px; padding-top: 11px; padding-bottom: 11px; text-align: center;
  background-color: theme(colors.vidgreen);
  color: theme(colors.white);
}

.v3-approve-check:hover {
    background-color: theme(colors.vidgreen);
    color: theme(colors.white);
    text-decoration: none;
}

.v3-maybe-check, .v3-maybe-check:active {
  display: block; margin-top: 10px; bottom: 0px; width: 50px; max-height: 40px; padding-top: 11px; padding-bottom: 11px; text-align: center; background-color: theme(colors.orange.400); color: theme(colors.white);
}

.v3-maybe-check:hover {
    background-color: theme(colors.orange.500);
    color: theme(colors.white);
    text-decoration: none;
}

.v3-reject-check, .v3-reject-check:active {
  display: block; margin-top: 10px; bottom: 0px; width: 50px; max-height: 40px; padding-top: 11px; padding-bottom: 11px; text-align: center; background-color: theme(colors.red.500);color: theme(colors.white);
}

.v3-reject-check:hover {
    background-color: theme(colors.red.600);
    color: theme(colors.white);
    text-decoration: none;
}

.vidcruiter-field-typing-test {
  overflow-wrap: anywhere;
}

.vidcruiter-typing-test-container {
  border: 1px solid theme(colors.neutral.300); position: relative; border-radius: 4px; margin-top: 30px;
}

.vidcruiter-typing-test-script {
  background-color: theme(colors.neutral.100); padding: 10px; border-bottom: 1px dotted theme(colors.neutral.300);
}

.vidcruiter-typing-test-text {
  padding: 20px; padding-bottom: 0px;
}

.vidcruiter-typing-test-container textarea {
  width: 96%; padding: 8px; height: 50px; font-size: theme(fontSize.xs);
}

.vidcruiter-typing-test-menu {
  margin-top: 5px;
}

.vidcruiter-typing-test-status {
  float: right; margin-right: 20px; margin-top: 5px; margin-bottom: 15px;
  @apply text-base;
}

.vidcruiter-typing-test-status span {
  font-weight: bold; text-transform: uppercase;
}

.vidcruiter-typing-test-button {
  float: left; margin-left: 20px; margin-top: 0px; margin-bottom: 20px;
}

.vidcruiter-typing-test-display {
  margin-top: 10px;
}

.vidcruiter-typing-test-display .accuracy {
  float: right; margin-left: 20px;
}

.vidcruiter-typing-test-display .wpm {
  float: right; margin-right: 10px;
}

.vidcruiter-typing-test-display span.number {
  font-size: 28px; font-weight: 200;
}

.vidcruiter-typing-test-display span.descriptor {
  color: theme(colors.neutral.500)
}

.new-note-link {
  border: none;
}

.new-note-link:hover {
  border: inherit;
}

.vidcruiter-save-video-alert {
  background-color: red;  border-radius: 0;
    color: white;
    margin-bottom: 10px;
    padding: 5px;
}

#video-response-header-title {padding-left: 20px; padding-bottom: 5px;}
#video-response-header-title h2 {font-size: theme(fontSize.lg); margin-top: 0px;}
#vidcruiter-video-response-description {overflow-x: auto; padding: 10px; padding-top: 0px; padding-bottom: 10px; padding-left: 20px;}
#vidcruiter-video-response-recorder {padding-left: 10px; padding-right: 20px; overflow-y:hidden;}
#vidcruiter-video-response-status {margin-bottom: 0px; border: 1px solid theme(colors.neutral.300); padding: 5px; border-bottom: none;}
#vidcruiter-records-left-label {border-right: 1px solid theme(colors.neutral.300);}
#vidcruiter-video-response-content {width: 100%; height: 100%; margin: auto;}
.vidcruiter-answer-timer-container .content {padding: 5px; padding-right: 7px; padding-left: 7px; width: 395px; color: theme(colors.neutral.700); border: 1px solid theme(colors.neutral.300); font-size: theme(fontSize.base);}
.vidcruiter-answer-timer-container .content  .desc {font-size: theme(fontSize.sm); padding-left: 0px;}
#vidcruiter-recorder-content {position: relative; width: 400px; height: 300px;}
#vidcruiter-video-overlay-buttons-content {position: absolute; z-index: 5; bottom: 12px; right: 12px;}
#vidcruiter-video-overlay-buttons-content > a {border-radius: 0px; box-shadow: none !important; background-image: url('transparent_white.png'); color: theme(colors.neutral.700); border: none; font-size: theme(fontSize.xs);}
#vidcruiter-video-overlay-buttons-content > a:hover { background: theme(colors.white); text-decoration: underline; }
#vidcruiter-recorder-footer-buttons {border: 1px solid theme(colors.neutral.300); border-top: none; width: 398px; padding: 0px; padding-top: 0px;}
#vidcruiter-video-response-buttons {margin-bottom: 0px; border: 1px solid theme(colors.neutral.300); padding: 0px; border-top: none;}
#vidcruiter-video-response-buttons .noborder { margin-top: 0px; float: right; }
#vidcruiter-video-response-buttons .noborder a { border-radius: 0px; border: none; }
#vidcruiter-video-response-buttons .border {margin-top: 0px; float: left;}
#vidcruiter-video-response-buttons .border a {border-radius: 0px; border: none;}

.vidcruiter-fullscreen-edit-container {

}

.vidcruiter-fullscreen-edit-container textarea {
  height: 120px; width: 97%;
}

.vidcruiter-fullscreen-edit-container-tablet textarea { height: 200px; font-size: theme(fontSize.lg); }

.postload-video {
    width: 400px; height: 350px; background-color: theme(colors.neutral.50); border: 1px solid theme(colors.neutral.300);
}

.postload-video span.loading {
    margin-top: 140px; font-size: theme(fontSize.lg); font-weight: bold; color: theme(colors.neutral.400); display: block;
}

.postload-video.loaded {
    background-color: initial;
    border: none;
    width: auto;
    height: auto;
}

.rich-text-word-count {
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
}
.div-has-rich-text {
  max-width: 505px;
  overflow-x: auto;
}
.rating-rich-text {
  max-width: 960px;
}
.word-count-rich-text {
  margin-top: -15px;
  @apply text-sm;
}

.vidcruiter-full-page-custom-design .select2-container--default  .select2-selection--multiple .select2-search--inline .select2-search__field {
    margin-bottom: 0px;
}


.vidcruiter-full-page-custom-design .select2-container .select2-selection--multiple .select2-selection__rendered {
    margin-left: 0px;
}

.vidcruiter-full-page-custom-design .select2-container--default .select2-selection--multiple {
    outline: none;
    background-color: theme(colors.white);
    border: 1px solid theme(colors.neutral.300);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border linear .2s, box-shadow linear .2s;
}

.vidcruiter-full-page-custom-design .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: var(--focused-color);
  outline: 2px solid var(--focused-color);
  box-shadow: inset 0 1px 1px rgba(var(--focused-color),.075), 0 0 8px rgba(var(--focused-color),.8);
}

.focused-outline {
  outline: 2px solid var(--focused-color);
}

#errors-question-page-form {
  @apply min-w-72 mb-4 mx-5 md:mx-10;
}

.form-errors-overview {
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid theme(colors.red.300);
  background-color: theme(colors.red.50);
  color: theme(colors.red.950);
  @apply text-left;
}

.form-errors-overview h2 {
  font-size: theme(fontSize.base);
}

.form-errors-overview > p {
  font-style: italic;
}

.form-errors-overview ul {
  @apply list-disc list-inside;
}

p.error {
  color: theme(colors.red.800);
  margin-bottom: 0px;
  margin-top: 5px;
}

.form-element {
  width: 100%;
  margin-bottom: 10px;
}

.form-element.-inline {
  display:flex; align-items: baseline;
}

.form-element label:not(.error) {
  font-weight: bold;
  font-size: theme(fontSize.sm);
}

.form-element label.error {
  @apply mt-2;
}

.form-element.-inline label {
  width: 100px;
  margin-bottom: 0px;
}

.form-element.-inline input, .form-element.-inline select {
  margin-bottom: 0px;
}

.form-element .required-indicator {
  color: theme(colors.red.800);
  font-size: theme(fontSize.base); font-weight: bold;
  margin-left: 5px;
}

.small-login-form {
  @apply flex p-8 border-box w-full;

  .small-login-form-right {
    @apply ml-5;

    .form-element .field-container {
      @apply w-full;
    }
  }
}

.multiple-choice-container ul {
  @apply list-disc list-inside;
}
