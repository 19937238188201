.ui-progressbar {
  position: relative;
  width: 300px;
  border: 1px solid theme(colors.neutral.300);
  height: 21px;
  background-image: url("progressbar_bg.png");
  background-repeat: repeat-x;
  text-align: center;
}

.pblabel {
  position: absolute;
  display: block;
  width: 100%;
  text-align: center;
  font-size: theme(fontSize.xs);
  padding-top: 2px;
  color: theme(colors.neutral.800);
  font-weight: bold;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.9);
}

.ui-progressbar-value {
  overflow: hidden;
  height: 20px;
  background-image: url("progressbar_bg_aqua.png");
  background-repeat: repeat-x;
  border: 1px solid theme(colors.cyan.700);
}

.ui-progressbar-value .pblabel {
  display: none;
}

.progress {
  overflow: hidden;
  height: 20px;
  margin-bottom: 20px;
  background-color: theme(colors.neutral.200);
  border-radius: 4px;
}
.progress .bar {
  width: 0%;
  height: 100%;
  color: theme(colors.white);
  float: left;
  font-size: theme(fontSize.xs);
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: theme(colors.sky.600);
  background-repeat: repeat-x;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  transition: width 0.6s ease;
}
.progress .bar + .bar {
  box-shadow: inset 1px 0 0 rgba(0,0,0,.15), inset 0 -1px 0 rgba(0,0,0,.15);
}
.progress.active .bar {
  animation: progress-bar-stripes 2s linear infinite;
}
.progress-success .bar,
.progress .bar-success {
  background-color: theme(colors.vidgreen);
  background-image:none;
}

.progress-v3 {
    background-color: theme(colors.neutral.100);
    background-image: none;
    border-radius: 0px;
/*    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
*/    height: 20px;
    margin-bottom: 20px;
    box-shadow: none;
}

.progress .bar {
    box-sizing: border-box;
    background-repeat: repeat-x;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
   color: theme(colors.white);
    float: left;
    font-size: theme(fontSize.xs);
    height: 100%;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    transition: width 0.6s ease 0s;
    width: 0;
}

.progress .bar-success-v3 {
  background-color: theme(colors.green.700);
}

.rater-progress-circle .text-label {
  text-align: center; font-size: theme(fontSize.2xs); font-weight: normal; line-height: 8px; color: theme(colors.neutral.500);
}

.mini-progress {
  @apply w-14 h-5 rounded relative bg-amber-100;
}

.mini-progress>.bar {
  position: absolute; height: 100%;  border-radius: 4px; background-color: theme(colors.amber.300);
}

.mini-progress>.bg-bar {
  position: absolute; width: 100%; text-align: center; font-weight: bold; font-size: theme(fontSize.sm); margin-top: 1px; color: theme(colors.neutral.700);
}

#rating-step-progress-pages {
  max-height: 264px;
  overflow-y: auto;
}

.rating-progress-name-container-sidebar {
  text-align: center;
  font-weight: bold;
  font-size: 12pt;
  margin-top: 8px;
}

.rating-progress-name-container-inperson {
  text-align: center;
  font-weight: bold;
  font-size: 12pt;
  margin-top: 15px;
}

.rating-progress-person-name {
  font-weight: bold;
  font-size: theme(fontSize.base);
}
@media (max-width : 768px) {
  .rating-progress-person-name {
    font-size: theme(fontSize.sm);
  }
}

/* Rating Step Progress */
#ratings-step-progress-wrapper {
  width: 100%; max-width: 960px; position: fixed; top: 0px; background-color: theme(colors.white); z-index: 999;
}

#ratings-step-wrapper-design #ratings-step-progress-wrapper {
  position: relative;
}

.rating-step-progress-single .rating-step-progress-single-hide {
  display: none;
}

.rating-step-progress-single-show {
  display: none;
}

.rating-step-progress-single .rating-step-progress-single-show {
  display: block;
}

.text-progress-bar {
  background-color: theme(colors.neutral.100); padding: 5px 10px; margin-left: 5px; margin-right: 5px;
  text-align: center;
}

.text-progress-bar.completed {
  background-color: theme(colors.green.600);
  color: theme(colors.white);
}

.text-progress-bar h2 {
  font-size: theme(fontSize.sm); margin-bottom: 0px; line-height: 13px;
}

.text-progress-bar p {
  margin-bottom: 0px; font-size: theme(fontSize.2xs); line-height: 12px;
}


.rating-step-progress-menu {
  margin-top: 9px;
  margin-left: 4px;
}

.rating-step-progress-menu .pages { font-weight: bold; padding: 5px 10px;
  font-size: theme(fontSize.xs);
}

.rating-step-progress-page {
  display: block;
  color: theme(colors.neutral.700);
}

.rating-step-progress-page:hover {
  background-color: theme(colors.neutral.100);
  text-decoration: none;
  color: theme(colors.neutral.700);
}

.rating-step-progress-page.active, .rating-step-progress-page.active:hover {
  background-color: theme(colors.yellow.100);
  cursor: default;
}

.rating-step-progress-page div {
  box-sizing: border-box;
  padding: 6px 10px;
  border-top: 1px solid theme(colors.neutral.300);
}

.rating-step-progress-page div span.rating-step-progress-page-percent {
  font-weight: bold;
  background-color: theme(colors.neutral.100);
  padding: 3px 5px;
}

.rating-step-progress-page.completed div span.rating-step-progress-page-percent {
  background-color: theme(colors.green.600);
  color: theme(colors.white);
}
