#global-search:focus {
  position: absolute;
  font-size: theme(fontSize.base) !important;
  z-index: 9990;
  right: 0px;
  width: 470px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}

.ui-menu .gsi-r-icon {
  display: none;
}

.ui-menu .gsi-r-icon > div {
}

.ui-autocomplete .ui-menu-item .ui-state-active .gsi-r-icon {
  display: block;
}

.ui-menu .ui-menu-item.ui-autocomplete-link a {
  padding: 8px !important;
  padding-left: 15px !important;
  font-size: theme(fontSize.sm); display: block; text-align: left;
  font-weight: bold;
}

ul.ui-autocomplete {
  overflow-x: hidden;
  overflow-y: auto;
}

.ui-menu .ui-menu-item.ui-autocomplete-link a:hover, .ui-menu .ui-menu-item.ui-autocomplete-link a:active {
  text-decoration: underline;
}

.ui-menu .ui-autocomplete-category {
  margin: 0;
  padding: 5;
  margin-left: 5px;
  zoom: 1;
  float: left;
  clear: left;
  width: 100%;
  font-weight: bold;
  padding-top: 20px;
  box-sizing: border-box;
}

.ui-menu .ui-autocomplete-category:first-child {
  padding-top: 15px;
}

.ui-menu .ui-autocomplete-category.no-results {
  padding-bottom: 15px;
}

.ms-th {
  background-color: theme(colors.neutral.50);
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: theme(fontSize.xs);
  padding-left: 10px;
  padding-right: 10px;
  color: theme(colors.neutral.600);
  font-weight: bold;
  border-bottom: 1px solid theme(colors.white);
}

.ms-th-s {
  background-color: theme(colors.white);
  font-weight: normal;
  font-size: theme(fontSize.2xs);
}

.ms-th a {
  text-align: center;
  font-size: theme(fontSize.sm);
}