.accordion {
  margin-bottom: 20px;
}
.accordion details summary::marker {
  display: none;
  content: "";
}
.accordion-group {
  margin-bottom: 2px;
  border: 1px solid theme(colors.neutral.200);
  border-radius: 4px;
}
.accordion-heading {
  border-bottom: 0;
}
.accordion-heading .accordion-toggle {
  display: block;
  padding: 8px 15px;
}
.accordion-toggle {
  cursor: pointer;
}
.accordion-inner {
  padding: 9px 15px;
  border-top: 1px solid theme(colors.neutral.200);
}

#nested {
  width: 320px; 
  list-style: none; 
  color: #003333; /* TODO: no matching TW color */
  margin-bottom: 15px; 
  margin-left: 0px;
}
#nested .acc-section {
  overflow:hidden;
  background:theme(colors.white);
  border-top: 1px solid theme(colors.neutral.300);
}
#nested .acc-content {
  width: 288px;
  padding:15px;
  border-top:none;
  background:theme(colors.white);
}
