@import "tom-select/dist/css/tom-select.bootstrap4.css";
@import "./tom-select_overrides.css";

.ts-wrapper {
  @apply inline-block text-base;
}

.backend-layout .ts-wrapper {
  @apply text-sm;
}

.ts-wrapper:hover .ts-control {
  @apply bg-gray-50;
}

.ts-wrapper .ts-control {
  min-height: calc(1.5em + 0.5rem + 2px);
  @apply pl-2.5 !pr-5 py-1.5 text-gray-600 rounded duration-150;
}

.ts-wrapper.plugin-remove_button .ts-control {
  @apply !pr-10;
}

.ts-dropdown .optgroup::before {
  content: none;
}

.ts-dropdown .optgroup-header {
  @apply py-1.5 bg-neutral-100 !text-gray-600 text-xs uppercase font-bold;
  letter-spacing: 0.05em !important;
}

.ts-wrapper.single .ts-control:not(.rtl)::after {
  right: 0;
}

.ts-wrapper.single .ts-control::after {
  @apply h-5 w-5 border-0 top-auto bg-center bg-no-repeat mt-0;
  background: url('icons/fas-chevron-down.svg') no-repeat 0.125rem 50%;
  background-size: 0.625rem;
}

.ts-wrapper.single.dropdown-active .ts-control::after {
  @apply h-5 w-5 border-0 top-auto bg-center mt-0 bg-contain bg-no-repeat;
  background: url('icons/fas-chevron-up.svg') no-repeat 0.125rem 50%;
  background-size: 0.625rem;
}

.ts-wrapper .ts-control,
.plugin-dropdown_input .dropdown-input {
  @apply border border-solid border-neutral-300 duration-200;
}

.ts-dropdown {
  z-index: 10000;
}

.ts-dropdown.ts-dropup {
  bottom: 100%;
  top: auto;
}

.ts-dropdown .option {
  @apply py-2 px-2.5 min-h-5;
}

.ts-dropdown.plugin-dropdown_input .dropdown-input {
  @apply box-border pt-1.5 pr-4 pl-6 pb-1.5 w-full text-base;
  background: url('icons/fas-magnifying-glass.svg') no-repeat 6px 50%;
  background-size: 12px;
}

.backend-layout .ts-dropdown.plugin-dropdown_input .dropdown-input {
  @apply text-sm;
}

.ts-wrapper.input-icon-add .plugin-dropdown_input .dropdown-input {
  background: url('icons/fas-plus.svg') no-repeat 6px 50%;
  background-size: 12px;
}

.ts-dropdown.plugin-dropdown_input .dropdown-input-wrap {
  @apply box-border p-2 border-b border-neutral-200;
}

.plugin-dropdown_input.focus .ts-dropdown .dropdown-input,
.ts-wrapper.focus .ts-control {
  border-color: var(--focused-color);
  outline: 1px solid var(--focused-color);
  box-shadow: 0 0 5px rgba(var(--focused-color), .3);
}

.ts-dropdown,
.ts-dropdown.form-control {
  @apply shadow-xl;
}

.ts-dropdown {
  @apply text-base;
}

.backend-layout .ts-dropdown {
  @apply text-sm;
}

.ts-dropdown-content {
  @apply max-h-72 px-0 pt-0 pb-1;
}

.ts-dropdown .ts-dropdown-content div.selected {
  background-color: var(--color-lightblue-hightlight-background);
  color: var(--color-lightblue-hightlight-text);
}

.ts-dropdown .ts-dropdown-content div.active {
  @apply bg-blue-400 text-white;
}

#tw-app .ts-dropdown .ts-dropdown-content div.active .sub-text {
  @apply text-white;
}

.ts-wrapper.multi .ts-control>div {
  @apply bg-gray-100;
}

.ts-wrapper.multi .ts-control {
  @apply pt-1.5 pb-1.5;
}

.ts-wrapper>.ts-control>.item {
  @apply overflow-hidden;
}

.ts-wrapper.plugin-remove_button:not(.rtl) .item .remove,
.ts-wrapper:not(.rtl) .item .select-item-btn {
  @apply border-l-0 text-base pr-2 pl-2 rounded-md text-gray-700;
}

.ts-wrapper:not(.rtl) .item .select-item-btn {
  font-size: theme(fontSize.xs);
}

.ts-wrapper.select-popup .ts-control {
  @apply hidden;
}

.ts-wrapper.full-width-items .ts-control {
  @apply !pr-1 overflow-hidden;
}

.ts-wrapper.not(.full-width-items).plugin-remove_button .ts-control:not(.rtl) {
  padding-right: 30px !important;
}

.ts-wrapper.full-width-items .item {
  @apply w-full flex;
}

.ts-wrapper .item .select-item-btn:hover {
  background: rgba(0, 0, 0, 0.05);
}

.ts-wrapper.single:not(.full-width-items).plugin-remove_button:not(.rtl) .item .remove {
  @apply absolute top-1/2 -translate-y-1/2 right-5;
}

.ts-wrapper.full-width-items.has-items .ts-control>input {
  @apply !mb-1;
  @apply !mt-1;
}

.ts--inline-results .ts-dropdown {
	position: relative !important;
	top: 0 !important;
	left: 0 !important;
	width: auto !important;
	box-shadow: none;
	height: auto !important;
}

.ts--inline-results .ts-dropdown-content {
	overflow: auto !important;
	/* max-height: none !important; */

}

.ts--inline-results .ts-wrapper{
	position: sticky;
	top: 2rem;
	z-index:2000;
}

.ts--inline-results .option{
	margin: 0;
}
