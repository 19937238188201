
.tox.tox-tinymce-aux {
  z-index: 99991;
}

.mce-container-body {
  border-radius: 4px 0px 4px 0px;
}

.mce-content-body {
  ul,
  ol {
    @apply mb-2.5 ml-6 pl-0 list-outside;
    ul {
      @apply list-circle;
    }
    ul, ol {
      @apply mb-0;
      ul {
        @apply list-square;
      }
    }
    li {
      > * {
        @apply align-top;
      },
      > ul,
      > ol {
        @apply ml-6;
      }
      > div,
      > p,
      > h1,
      > h2,
      > h3,
      > h4,
      > h5,
      > h6,
      > pre {
        @apply inline-block;
      }
    }
  }
  ul {
    @apply list-disc;
  }
  ol {
    @apply list-decimal;
  }
}

.tox.tox-mce {
  border-radius: 4px;
  max-width: 100%;
  ol, ul {
    padding-left: 0;
    list-style-position: inside;
  }
}

.mce-edit-focus {
  outline: none;
}

.tox.tox-tinymce-inline {
  z-index: 1100;
  max-width: 526px;

  .tox-editor-container .tox-editor-header {
    max-width: 514px !important;
    border: 1px solid #eee;
    border-radius: 4px 0px;
  }
}

.tox-silver-sink .tox-collection .tox-collection__item {
  cursor: pointer;
}

div.tox .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active) {
  background: #a6ccf7;

  span {
    background: #a6ccf7;
  }
}

.tox.tox-tinymce {
  .tox-menubar + .tox-toolbar-overlord {
    margin-top: 0;
    padding: 0;
  }

  /* ultra specific here to override theme rule */
  &:not(.tox-tinymce-inline) .tox-editor-header {
    padding-bottom: 0;
  }

  button.tox-tbtn,
  button.tox-mbtn {
    margin: 0;
    padding: 2px;
    background: #fff;
    cursor: pointer;

    .tox-tbtn__select-label {
      cursor: pointer;
      background: #fff;
    }

    &:hover {
      background: #a6ccf7;

      .tox-tbtn__select-label {
        background: #a6ccf7;
      }
    }
  }

  .tox-menubar {
    margin-left: 0;
    padding: 0 0 3px 9px;
    border-bottom: 1px solid #e2e4e7;
  }

  .tox-toolbar {
    padding: 2px;
  }

  .tox-toolbar {
    max-height: 36px;
    border-bottom: 1px solid #d9d9d9;
    padding: 0;
    background: #fff;

    /* limit size of font-size menu, prevents last toolbar items from wrapping */
    &:first-child .tox-toolbar__group:nth-child(2) .tox-tbtn:nth-child(2) {
      max-width: 100px;
    }

    /* limit size of paragraph menu */
    &:nth-child(2) .tox-toolbar__group:first-child .tox-tbtn:first-child {
      max-width: 100px;
    }

    /* limit size of paragraph menu */
    &:nth-child(2) {
      .tox-toolbar__group:nth-child(4),
      .tox-toolbar__group:nth-child(5) {
        button {
          width: auto;
        }
      }
    }

    .tox-toolbar__group {
      height: 35px;
      padding: 0px;
      margin-left: 2px;
      border-left: 1px solid #d9d9d9;
      background: #fff;

      .tox-split-button {
        margin: 0;
        cursor: pointer;
      }
  
      &:first-child {
        border-left: none;
      }
  
      &:not(:first-child) {
        padding-left: 2px;
      }
    }
  }
}
