.durationpicker-container {
  display: inline-block !important;
  width: auto;
}

.durationpicker-innercontainer {
  display: inline-block !important;
  width: auto;
  padding-right: 5px;
}

.durationpicker-duration {
  @apply !w-14 !px-2;
  display: inline-block !important;
  border: none;
  text-align: left !important;
  font-size: theme(fontSize.base) !important;
}

.durationpicker-label {
  display: inline-block !important;
  color: theme(colors.neutral.500);
  font-size: theme(fontSize.xs) !important;
}
