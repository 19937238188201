@import "../../config/config_vidcruiter";

.close-x {
  font-size: theme(fontSize.lg); font-weight: bold; color: gray;
}

.close-x:hover {
  text-decoration: none;
}

.btn.fa-container > .svg-inline--fa {
  height: 1.41em;
}

.btn {
  display: inline-block;
  /* IE7 inline-block hack */
  font-size: theme(fontSize.sm);
  line-height: 20px;
  vertical-align: middle;
  color: theme(colors.neutral.700);
  background-color: theme(colors.neutral.100);
  background-repeat: repeat-x;
  border: 1px solid theme(colors.neutral.300);
  @apply duration-150 rounded py-1 px-3 mb-0 text-center cursor-pointer;
}
.btn:active,
.btn.active {
}
.btn:first-child {
}
.btn:hover {
  color: theme(colors.neutral.900);
  text-decoration: none;
  background-position: 0 3rem;
  transition: background-position 0.1s linear;
  @apply bg-neutral-200;
}
.btn:focus {
  outline: 2px solid var(--focused-color);
}
.btn.active,
.btn:active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.btn.disabled, btn.disabled:hover,
.btn[disabled], .btn[disabled]:hover {
  @apply cursor-not-allowed;
  background-image: none;
  opacity: 0.65;
  box-shadow: none;
}
.btn-large {
  padding: 11px 19px;
  font-size: 17.5px;
  border-radius: 6px;
}
.btn-large [class^="icon-"],
.btn-large [class*=" icon-"] {
  margin-top: 4px;
}
.btn-small {
  padding: 2px 10px;
  font-size: 11.9px;
  border-radius: 3px;
}
.btn-small [class^="icon-"],
.btn-small [class*=" icon-"] {
  margin-top: 0;
}
.btn-mini [class^="icon-"],
.btn-mini [class*=" icon-"] {
  margin-top: -1px;
}

/* There is a bug whereby events don't bubble to the parent
If you click exactly on the FA decoration in a link */
.btn-mini .svg-inline--fa {
  pointer-events: none;
}

.btn-mini {
  padding: 0 6px;
  font-size: 10.5px;
  border-radius: 3px;
}
.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  box-sizing: border-box;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.btn-warning.active,
.btn-danger.active,
.btn-success.active,
.btn-info.active,
.btn-inverse.active {
  color: rgba(255, 255, 255, 0.75);
}

span.btn-icon {
  font-size: theme(fontSize.sm);
  margin-right: 4px;
}

.btn-primary {
  color: theme(colors.white);
  background-color: theme(colors.blue.600);
  background-repeat: repeat-x;
  @apply border-transparent;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
  color: theme(colors.white);
  background-color: theme(colors.blue.700);
}
.btn-primary:active,
.btn-primary.active {
}
.btn-danger {
  color: theme(colors.white);
  background-color: theme(colors.red.500);
  background-repeat: repeat-x;
  @apply border-transparent;
}
.btn-danger:hover,
.btn-danger:active,
.btn-danger.active,
.btn-danger.disabled,
.btn-danger[disabled] {
  color: theme(colors.white);
  background-color: theme(colors.red.700);
}
.btn-danger:active,
.btn-danger.active {
}
.btn-success {
  color: theme(colors.white);
  background-color: theme(colors.vidgreen);
  background-repeat: repeat-x;
  @apply border-transparent;
}
.btn-success:hover,
.btn-success:active,
.btn-success.active,
.btn-success.disabled,
.btn-success[disabled] {
  color: theme(colors.white);
  background-color: theme(colors.green.700);
}
.btn-success:active,
.btn-success.active {
}

button.btn,
input[type="submit"].btn {
}
button.btn::-moz-focus-inner,
input[type="submit"].btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button.btn.btn-large,
input[type="submit"].btn.btn-large {
}
button.btn.btn-small,
input[type="submit"].btn.btn-small {
}
button.btn.btn-mini,
input[type="submit"].btn.btn-mini {
}
.btn-link,
.btn-link:active,
.btn-link[disabled] {
  background-color: transparent;
  background-image: none;
  box-shadow: none;
}
.btn-link {
  border-color: transparent;
  cursor: pointer;
  color: theme(colors.sky.600);
  border-radius: 0;
}
.btn-link:hover {
  color: theme(colors.sky.800);
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover {
  color: theme(colors.neutral.700);
  text-decoration: none;
}
.btn-group {
  position: relative;
  display: inline-block;
  /* IE7 inline-block hack */
  font-size: 0;
  vertical-align: middle;
  white-space: nowrap;
}
.btn-group:first-child {
}
.btn-group + .btn-group {
  margin-left: 5px;
}
.btn-toolbar {
  font-size: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.btn-toolbar > .btn + .btn,
.btn-toolbar > .btn-group + .btn,
.btn-toolbar > .btn + .btn-group {
  margin-left: 5px;
}
.btn-group > .btn {
  position: relative;
  border-radius: 0;
}
.btn-group > .btn + .btn {
  margin-left: -1px;
}
.btn-group > .btn,
.btn-group > .dropdown-menu,
.btn-group > .popover {
  font-size: theme(fontSize.sm);
}
.btn-group > .btn-mini {
  font-size: 10.5px;
}
.btn-group > .btn-small {
  font-size: 11.9px;
}
.btn-group > .btn-large {
  font-size: 17.5px;
}
.btn-group > .btn:first-child {
  @apply rounded-l ml-0;
}
.btn-group > .btn:last-child,
.btn-group > .dropdown-toggle {
  @apply rounded-r;
}
.btn-group > .btn.large:first-child {
  @apply rounded-l ml-0;
}
.btn-group > .btn.large:last-child,
.btn-group > .large.dropdown-toggle {
  @apply rounded-r;
}
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active {
  z-index: 2;
}
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}
.btn-group > .btn + .dropdown-toggle {
  @apply px-2;
  box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
}
.btn-group > .btn-mini + .dropdown-toggle {
  @apply px-1.5;
}
.btn-group > .btn-small + .dropdown-toggle {
}
.btn-group > .btn-large + .dropdown-toggle {
  @apply px-3;
}
.btn-group.open .dropdown-toggle {
  background-image: none;
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.btn-group.open .btn.dropdown-toggle {
  background-color: theme(colors.neutral.200);
}
.btn-group.open .btn-primary.dropdown-toggle {
  background-color: theme(colors.blue.700);
}
.btn-group.open .btn-warning.dropdown-toggle {
  background-color: theme(colors.amber.500);
}
.btn-group.open .btn-danger.dropdown-toggle {
  background-color: theme(colors.red.700);
}
.btn-group.open .btn-success.dropdown-toggle {
  background-color: theme(colors.vidgreen);
}
.btn-group.open .btn-info.dropdown-toggle {
  background-color: theme(colors.sky.500);
}
.btn-group.open .btn-inverse.dropdown-toggle {
  background-color: theme(colors.neutral.800);
}
.btn .caret {
  @apply mt-2 ml-0;
}

.dropdown {
  .btn-mini .caret,
  .btn-small .caret,
  .btn-large .caret {
    @apply mt-1.5;
  }
}
.btn-mini .caret,
.btn-small .caret,
.btn-large .caret {
  @apply mt-2;
}
.btn-large .caret {
  border-left-width: 5px;
  border-right-width: 5px;
  border-top-width: 5px;
}
.dropup .btn-large .caret {
  border-bottom-width: 5px;
}
.btn-warning .caret,
.btn-danger .caret,
.btn-info .caret,
.btn-success .caret,
.btn-inverse .caret {
  border-top-color: theme(colors.white);
  border-bottom-color: theme(colors.white);
}
.btn-group-vertical {
  display: inline-block;
  /* IE7 inline-block hack */
}
.btn-group-vertical > .btn {
  display: block;
  float: none;
  max-width: 100%;
  border-radius: 0;
}
.btn-group-vertical > .btn + .btn {
  margin-left: 0;
  margin-top: -1px;
}
.btn-group-vertical > .btn:first-child {
  @apply rounded-t;
}
.btn-group-vertical > .btn:last-child {
  @apply rounded-b;
}
.btn-group-vertical > .btn-large:first-child {
  @apply rounded-t-md;
}
.btn-group-vertical > .btn-large:last-child {
  @apply rounded-b-md;
}

.btn-inner {
  outline: 0;
  border-radius: 100px 100px 100px 100px;
}
.btn-mini-inner {
  padding: 10px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.btn-bigger {
  width: 200px;
  @apply py-4;
}

.btn {
  @apply text-sm py-1 px-2.5 xl:py-1.5 xl:px-3;
}
.btn-success-v3:focus,
.btn-success-v3:active {
  @apply bg-vidgreen;
}
.btn-mini {
  @apply py-0.5 px-1.5 text-2xs;
}
.btn-large {
  line-height: normal;
  @apply text-base rounded-md py-2.5 px-3.5;
}
.btn-group > .btn,
.btn-group > .dropdown-menu,
.btn-group > .popover {
  @apply text-sm;
}


.btn-v2 {
  background-color: theme(colors.white);
  filter: none;
  border: 1px solid #CFD7DA;
  border-radius: 3px;
  color: theme(colors.neutral.500);
  font-weight: bold;
  background: theme(colors.white);
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
  outline: 0;
}
.btn-v2:hover {
  color: theme(colors.neutral.700);
  text-decoration: none;
  background: theme(colors.white);
  background-image: none;
}

.new-button {
  display: inline-block;
  background-image: url('30_add_button_left.png');
  height: 29px;
  padding-left: 55px;
  border: 1px solid #c8c8c8;
  border-left: none;
  background-repeat: no-repeat;
  background-position: top left;
  padding-top: 9px;
  padding-right: 15px;
  color: theme(colors.neutral.800);
  text-decoration: none;
  max-height: 38px;
  font-size: theme(fontSize.sm);
}
.new-button:hover {
  color: green;
  text-decoration: none;
}

.btn-success-v2 {
  border: 1px solid #53a66c;
  color: theme(colors.white);
  background: #5ebe7b;
}
.btn-success-v2:hover {
  color: theme(colors.white);
  text-decoration: none;
  background-color: #67d287;
}
.btn-v3, .ui-datepicker .ui-datepicker-buttonpane button {
  box-sizing: border-box;
  font-family: theme(fontFamily.sans);
  @apply bg-white text-neutral-700 border-neutral-300 border hover:bg-neutral-50 hover:border-neutral-300 hover:text-neutral-800 shadow-sm focus:bg-white focus:text-neutral-700 focus:border-neutral-300 active:bg-neutral-100 active:border-neutral-300 font-medium gap-2 rounded-lg text-sm leading-6 xl:leading-5 text-center transition-all;
}

.btn-v3::-moz-focus-inner, .ui-datepicker .ui-datepicker-buttonpane button::-moz-focus-inner {
    border:0;
    padding:0;
}

.btn-huge {
    line-height: normal;
    @apply text-lg rounded-md py-2.5 px-4;
}


.btn-success-text-v3, .btn-success-text-v3:focus {
    background: none repeat scroll 0 0 theme(colors.neutral.200);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1) inset;
}

.btn-success-text-v3:hover {
    border: 1px solid theme(colors.neutral.300);
    @apply bg-neutral-200;

}

.btn-secondary-v3 {
    background: theme(colors.white);
    background-color: theme(colors.white);
    color: theme(colors.neutral.700);
    border: 1px solid theme(colors.neutral.200);
}

.btn-secondary-v3-dark {
  color: theme(colors.neutral.700);
}

.btn-secondary-v3-nohover {
    background: none;
    border: 1px solid theme(colors.neutral.200);
    color: theme(colors.neutral.800);
}

.btn-secondary-v3:hover, .btn-secondary-v3:active {
    background: none;
    border: 1px solid theme(colors.neutral.300);
    color: theme(colors.neutral.900);
}

.btn-add-v3 {
  color: theme(colors.green.500);
  background: none repeat scroll 0 0 theme(colors.white);
  @apply inline-block flex items-center justify-center rounded-full shadow-none w-14 h-14 text-4xl;
}

.btn-add-v3:hover, .btn-add-v3:active {
    color: theme(colors.green.500);
    background: none repeat scroll 0 0 theme(colors.white);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1) inset;
}

.btn-add-v3-small {
  @apply text-2xl w-11;
}

.btn-secondary-v3-close {
  font-weight: bold;
  font-size: 22px;
  padding-bottom: 5px;
}

.btn-secondary-v3-inverse {
  background-color: theme(colors.black); border: 1px solid theme(colors.neutral.700); color: theme(colors.neutral.100);
}

.btn-secondary-v3-inverse:hover {
  background-color: theme(colors.black); border: 1px solid theme(colors.neutral.700);
  color: white;
}

.btn-secondary-v3-noborder {
    border: 1px solid theme(colors.white);
    border-color: transparent;
    background-color: transparent;
    @apply shadow-none;
}

.btn-secondary-v3-noborder:hover {
    border: 1px solid theme(colors.neutral.300);
}

.btn-secondary-v3-red:hover {
  @apply text-red-800;
}

.btn-v3-tab {
  @apply px-2.5 rounded-none border-0 my-0 mx-1 py-[9px];
}

.btn-v3-tab:active {
  box-shadow: none;
}

.btn-v3-tab:hover {
  border: none;
  background-color: theme(colors.neutral.300);
}

.btn-v3-tab span {
  color: theme(colors.neutral.600); padding-left: 4px; display: none;
  font-size: theme(fontSize.xs);
}

.btn-v3-tab-selected span {
  display: inline-block;
  color: theme(colors.neutral.800);
}

.btn-v3-tab-selected, .btn-v3-tab-selected:hover, .btn-v3-tab-selected:active, .btn-v3-tab-selected:focus { position: relative;
    color: theme(colors.neutral.800);
    margin-top: 0px;
    font-size: theme(fontSize.xs);
    padding-top: 9px;
    padding-bottom: 9px;
    font-weight: bold;
    margin-left: 7px; margin-right: 7px;
    background: theme(colors.white);
    box-shadow: none;
    border: none;
  border-radius: 0px;
 }

.btn-white-v3:focus {
    border-color: theme(colors.vidgreen);
    color: theme(colors.white);
}

.btn-white-v3:active {
    background: none;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2) inset;
    color: theme(colors.white);
}

.btn-v3.btn-flat-v3 {
  background: theme(colors.neutral.200);
  border-color: theme(colors.neutral.200);
  font-weight: bold;
}

.btn-v3.btn-flat-v3:hover {
  background: theme(colors.neutral.200);
  border-color: theme(colors.neutral.200);
}

.btn-success-v3 {
    @apply bg-vidgreen text-white border-vidgreen border hover:bg-green-700 hover:border-green-700 shadow-sm focus:bg-vidgreen focus:border-vidgreen active:bg-vidgreen active:border-vidgreen;
}

.btn-success-v3:hover {
    background-color: theme(colors.green.700);
    border-color: theme(colors.green.600);
    color: theme(colors.white);
}

.btn-success-v3.disabled, .btn-success-v3[disabled] {
    background-color: theme(colors.green.700);
    color: theme(colors.white);
}

.btn-success-v3:focus {
    border-color: theme(colors.vidgreen);
    color: theme(colors.white);
}


.btn-flat-v3.btn-success-v3 {
  background: theme(colors.vidgreen);
  background-color: theme(colors.vidgreen);
  border-color: theme(colors.vidgreen);
  color: theme(colors.white);
  box-shadow: none;
}

.btn-flat-v3.btn-success-v3:hover {
  background: theme(colors.green.700);
  background-color: theme(colors.green.700);
  border-color: theme(colors.green.700);
}

.btn-flat-v3.btn-success-v3.disabled, .btn-flat-v3.btn-success-v3[disabled] {
  background: theme(colors.green.700);
  background-color: theme(colors.green.700);
  border-color: theme(colors.green.700);
}

.btn-flat-v3.btn-success-v3:focus {
  border-color: theme(colors.vidgreen);
}

.btn-success-v3:active {
    background: none repeat scroll 0 0 theme(colors.vidgreen);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2) inset;
    color: theme(colors.white);
}

.btn-flat-v3.btn-danger-v3 {
  background: theme(colors.red.600);
  border-color: theme(colors.red.600);
  box-shadow: none;
}

.btn-flat-v3.btn-danger-v3:hover {
  background: theme(colors.red.700);
  background-color: theme(colors.red.700);
  border-color: theme(colors.red.700);
}

.btn-danger-v3 {
    background-color: theme(colors.red.600);
    border-color: theme(colors.red.700);
    color: theme(colors.white);
    font-weight: bold;
}

.btn-danger-v3:hover {
    background-color: theme(colors.red.700);
    color: theme(colors.white);
}

.btn-danger-v3:focus {
    border-color: theme(colors.red.700);
    color: theme(colors.white);
}

.btn-danger-v3:active {
    background: none repeat scroll 0 0 theme(colors.red.500);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2) inset;
    color: theme(colors.white);
}

.btn-cancel-v3.btn-flat-v3 {
  background: theme(colors.neutral.200);
  border-color: theme(colors.neutral.200);
  color: theme(colors.neutral.700);
}

.btn-cancel-v3.btn-flat-v3:hover {
  background: theme(colors.neutral.300);
  border-color: theme(colors.neutral.300);
  color: theme(colors.black);
}

.btn-cancel-v3 {
    border-color: theme(colors.neutral.300);
    color: theme(colors.neutral.500);
}

.btn-cancel-v3:hover {
    color: theme(colors.neutral.500);
}

.btn-cancel-v3:active {
    background: none repeat scroll 0 0 theme(colors.neutral.300);
    color: theme(colors.neutral.500);
}

.btn-utility-v3, .btn-utility-v3-inverse:hover {
  background-color: theme(colors.white);
  filter: none;
  border: 1px solid theme(colors.neutral.300);
  border-radius: 3px;
  box-shadow: none;
  color: theme(colors.neutral.500);
  text-decoration: none;
  background: theme(colors.white);
  background-image: none;
  outline: 0;
}

.btn-utility-v3:hover, .btn-utility-v3-inverse {
  font-weight: bold;
  color: theme(colors.neutral.800);
  background: theme(colors.white);
}

.v3-x {
    display: inline-block;
    font-weight: bold;
    background-image: url('30_tiny_x.png');
    background-repeat: no-repeat;
    height: 10px;
    width: 10px;
    margin-top: 4px;
    margin-left: 6px;
}

.v3-x:hover {
    background-image: url('30_tiny_x_active.png');
}

.v3-x-small {
    background-image: url('30_small_x.png');
    height: 16px;
    width: 16px;
}

.v3-x-small:hover {
    background-image: url('30_small_x_active.png');
}

.star-toggle,.star-toggle-active {
  padding: 5px;
}

.star-toggle {
  color: theme(colors.neutral.400);
}

.star-toggle:hover, .star-toggle-active:hover {
  text-decoration: none;
  color: inherit;
}

.star-toggle-active {
  color: theme(colors.neutral.700);
}

.btn-primary {
  @apply box-border block w-full px-4 py-2 bg-primary rounded text-white text-center text-sm font-medium tracking-wide no-underline duration-100 hover:bg-[--color-default-hover] active:bg-[--color-default-hover] focus:bg-[--color-default-hover] disabled:bg-[--color-default-hover] disabled:cursor-not-allowed hover:text-white hover:no-underline;
}

.btn-vidgreen {
  @apply box-border block w-full px-4 py-2 bg-primary rounded text-white text-center text-sm font-medium tracking-wide no-underline duration-100 hover:bg-green-700 active:bg-green-700 focus:bg-green-700 disabled:bg-green-700 disabled:cursor-not-allowed hover:text-white hover:no-underline;
}

.btn-light {
  @apply box-border block w-full px-4 py-2 bg-neutral-200 rounded text-neutral-700 text-center text-sm font-medium tracking-wide no-underline duration-100 hover:bg-neutral-300 active:bg-neutral-300 focus:bg-neutral-300 disabled:bg-neutral-300 disabled:cursor-not-allowed hover:text-black hover:no-underline;
}

.btn-dark {
  @apply box-border block w-full px-4 py-2 bg-neutral-800 rounded text-neutral-100 text-center text-sm font-medium tracking-wide no-underline duration-100 hover:bg-neutral-950 active:bg-neutral-950 focus:bg-neutral-950 disabled:bg-neutral-800 disabled:cursor-not-allowed hover:text-white hover:no-underline;
}

.btn-disabled {
  @apply bg-neutral-200 hover:bg-neutral-200 text-neutral-500 hover:text-neutral-500 cursor-not-allowed;
}

.btn-outlined {
  @apply box-border block w-full px-4 py-2 bg-white rounded text-granite-600 border border-granite-200 text-center text-sm font-medium tracking-wide no-underline duration-100 hover:border-granite-300 active:border-granite-300 focus:border-lightBlue-500 focus:outline-2 focus:outline-lightBlue-500 disabled:border-neutral-50 disabled:cursor-not-allowed  hover:text-granite-900 hover:no-underline;
}

.btn-outlined-reverse {
  @apply box-border block w-full px-4 py-2 bg-transparent rounded text-neutral-300 border border-neutral-300 text-center text-sm font-medium tracking-wide no-underline duration-100 hover:border-neutral-50 active:border-neutral-50 focus:border-slate-100 focus:outline-2 focus:outline-lightBlue-500 focus:bg-white focus:bg-opacity-10 focus:outline focus:outline-offset-2 disabled:border-neutral-50 disabled:cursor-not-allowed  hover:text-slate-100 hover:bg-white hover:bg-opacity-5 hover:no-underline active:text-slate-200 active:bg-white active:bg-opacity-5 active:border-slate-200;
}

.btn-outlined--recorder-mobile {
  @apply btn-outlined btn-icon !w-[32px] !h-[32px]  text-base;
}

.btn-outlined-reverse--recorder-mobile {
  @apply btn-outlined-reverse btn-icon !w-[32px] !h-[32px] text-base;
}

.recorder-mobile-menubar--reversed .btn-outlined--recorder-mobile {
  @apply btn-outlined-reverse btn-icon;
}

/* These should be squarish buttons with icons. */
.btn-icon {
  @apply text-2xl w-[44px] h-[44px] px-0 py-0 rounded-lg flex items-center justify-center;
}

.btn-icon--md {
  @apply text-xl w-[32px] h-[32px];
}

.btn-icon--sm {
  @apply text-base w-[24px] h-[24px];
}

.btn-icon.btn-icon--grape {
  @apply bg-white text-grape-700 hover:bg-neutral-100;
}

.btn-icon.btn-icon--noborder {
  @apply border-0 hover:border-0;
}

/* Rounded version of icon buttons */
.btn-icon--circle {
  @apply rounded-full w-[32px] h-[32px] bg-opacity-80 text-xl bg-gray-800 text-white hover:text-white hover:bg-opacity-100 hover:shadow-sm hover:bg-gray-900 duration-150 focus:bg-opacity-100 focus:bg-gray-900 focus:outline focus:outline-2 focus:outline-lightBlue-500 active:bg-opacity-100 active:bg-gray-950;
}

/* Recorder Styled Buttons */
.btn-recorder {
  @apply box-border block w-full px-4 py-4 rounded-lg text-white text-center text-sm font-bold tracking-wide no-underline duration-100 disabled:cursor-not-allowed focus:outline-lightBlue-500 hover:text-white hover:no-underline;
}

.btn-recorder-neutral {
  @apply btn-recorder bg-granite-700 border border-granite-500 hover:bg-granite-800 active:bg-granite-800 focus:bg-granite-800 disabled:bg-granite-800;
}

.btn-recorder-primary {
  @apply btn-recorder bg-green-700 border border-green-500 hover:bg-green-800 active:bg-green-800 focus:bg-green-800 disabled:bg-green-800;
}

.btn-recorder-red {
  @apply btn-recorder bg-red-600 border border-red-400 hover:bg-red-700 active:bg-red-700 focus:bg-red-700 disabled:bg-red-700;
}