.avatars {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: theme(fontFamily.sans) !important;
  font-style: normal;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: theme('colors.neutral.500');
  color: theme(colors.white);
  text-shadow: none;
}

.avatars-thumb {
  font-size: theme(fontSize.xl);
  @apply size-11 flex flex-col items-center justify-center shrink-0;
}


.avatars-live-interview {
  background-color: theme(colors.neutral.600);
}

.avatars-mini {
  font-size: theme(fontSize.sm);
  @apply size-8 flex flex-col items-center justify-center shrink-0;
}

.avatars-18 {
  font-size: 8px;
  height: 18px;
  line-height: 18px;
  width: 18px;
}

.avatars-20 {
  font-size: theme(fontSize.2xs);
  @apply size-5 flex flex-col items-center justify-center shrink-0;
}

.avatars-22 {
  font-size: theme(fontSize.xs);
  height: 22px;
  line-height: 22px;
  width: 22px;
}

/* Note: The standard dimensions of size-6 is 24px, not 25px */
.avatars-25 {
  font-size: theme(fontSize.xs);
  @apply size-6 flex flex-col items-center justify-center shrink-0;
}

/* Note: The standard dimensions of size-8 is 32px, not 30px */
.avatars-30 {
  font-size: theme(fontSize.sm);
  @apply size-8 flex flex-col items-center justify-center shrink-0;
}

/* Note: The standard dimensions of size-9 is 36px, not 35px */
.avatars-35 {
  font-size: theme(fontSize.base);
  @apply size-9 flex flex-col items-center justify-center shrink-0;
}

.avatars-40 {
  font-size: theme(fontSize.lg);
  @apply size-10 flex flex-col items-center justify-center shrink-0;
}

/* Note: The standard dimensions of size-11 is 44px, not 45px */
.avatars-45 {
  font-size: theme(fontSize.xl);
  @apply size-11 flex flex-col items-center justify-center shrink-0;
}

/* Note: The standard dimensions of size-14 is 56px, not 50px */
.avatars-50 {
  font-size: theme(fontSize.2xl);
  @apply size-14 flex flex-col items-center justify-center shrink-0;
}

/* Note: The standard dimensions of size-16 is 64px, not 59px */
.avatars-59 {
  font-size: theme(fontSize.3xl);
  @apply size-16 flex flex-col items-center justify-center shrink-0;
}

.avatars-70 {
  font-size: 32px;
  height: 70px;
  line-height: 70px;
  width: 70px;
}

/* Note: The standard dimensions of size-20 is 80px, not 75px */
.avatars-75 {
  font-size: theme(fontSize.4xl);
  @apply size-20 flex flex-col items-center justify-center shrink-0;
}

.avatars-90 {
  font-size: 38px;
  height: 90px;
  line-height: 90px;
  width: 90px;
}