section.profile-link-setting-parent-container.unchecked .profile-link-setting-container .checkbox-label {
  text-decoration: line-through;
}

.profile-link-setting-container.unchecked .checkbox-label {
  text-decoration: line-through;
}

section.profile-link-setting-parent-container.unchecked .profile-link-setting-container input[type=checkbox] {
  /*pointer-events: none;*/
}

.profile-link-setting-container {
  display: flex;
  border-bottom: 1px solid theme(colors.neutral.300);
}

.profile-link-setting-container .checkbox-container {
  width: 18px;
  min-width: 18px;
  max-width: 18px;
}

.profile-link-setting-container > div {
  padding: 8px;
}

.profile-link-setting-container .checkbox-container.n1 {
  background-color: theme(colors.slate.100); border-left: 1px solid theme(colors.slate.300); border-right: 1px solid theme(colors.slate.300);
}

.profile-link-setting-container .checkbox-container.n2 {
  background-color: theme(colors.white); border-left: none; border-right: 1px dotted theme(colors.neutral.300);
}

.profile-link-setting-container .checkbox-label {

}

.profile-link-setting-container .checkbox-label.n1 {
  @apply text-sm text-slate-600 font-bold;
}

.profile-link-setting-container .checkbox-label.n2 {
  @apply text-sm text-neutral-700 font-bold;
}

.profile-link-setting-container .checkbox-label.n3 {
  @apply text-sm text-neutral-700 font-normal;
}
