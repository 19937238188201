ul.more-tags-list {
  list-style-type: none;
  margin-left: 0px;
  margin-bottom: 0px;
}

ul.more-tags-list li {
  margin-left: 0px;
}

ul.more-tags-list li:hover {
  background-color: theme(colors.neutral.100);
}

ul.more-tags-list li div {
  padding: 5px 10px;
}

ul.more-tags-list li a.tag-remove {
  float: right;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px;
}

.tag-item {
  @apply ml-2.5 mb-1 py-0.5 px-2 text-sm shadow-xs rounded bg-white;

}

.tag-item.more {
  background-color: theme(colors.neutral.100); border: 1px solid theme(colors.white);
  box-shadow: none;
  padding: 5px;
  padding-top: 2px; padding-bottom: 2px;
}

.tag-item.not-sendable {
  background-color: theme(colors.red.50); color: theme(colors.red.950); border-color: theme(colors.red.300);

  > a {
    color: theme(colors.red.950);
  }
}

.tag-item a.tag-remove {
  font-size: theme(fontSize.sm); padding: 5px; margin-left: 3px;
}

.tag-item.disabled a.tag-remove, .more-tags-list li.disabled a.tag-remove {
  display: none;
}

.tag-item span.lock, .more-tags-list li span.lock {
  display: none;
}

.tag-item.disabled span.lock, .more-tags-list li.disabled span.lock {
  display: inline-block;
}

.ui-tooltip-team-page-more .qtip-content {
  max-height: 250px;
  min-width: 250px;
  overflow-y: auto;
}

#edit-communication-dialog .tag-item, #change-status-dialog .tag-item {
  margin-left: 0px;
  margin-right: 8px;
  margin-bottom: 4px; margin-top: 4px;
}