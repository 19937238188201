/* Clearfix */
.clearfix {
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}

.clearfix::after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
}
.clearfix {
    display: inline-block;
}
.clearfix {
    display: block;
}

.overflow-wrap-break {
  /*overflow-wrap: break-word;*/
  /*word-break: break-all;*/
}

.wrapfix {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.vidcruiter-description {
  p {
    overflow-wrap: anywhere;
  }
}

.vidcruiter-description > ul {
  word-break: break-word;
}

/* Cursors */
.pointer { cursor: pointer; }

a.no-decoration:hover {
  text-decoration: none;
}

/* Display */
.hide {
  display: none;
}
.force-hide {
  display: none !important;
}
.hide-important {
  display:none!important;
}
@media (max-width : 668px) {
  .hide-if-small {
    display: none;
  }
}
  .show {
  display: block;
}

.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

/* Positioning */
.left, .pull-left {
  float: left;
}
.right, .pull-right {
  float: right;
}
.affix {
  position: fixed;
}
[class*="span"].hide {
  display: none;
}
[class*="span"].pull-right {
  float: right;
}

/* Overflow */
.overflow-y-hidden {
  overflow-y: hidden !important;
}

/* Border Radius */
.circle-base {
    border-radius: 999px; 
}
.rounded-less {
  border-radius: 3px;
}
.rounded-top {
  border-radius: 0px 0px 5px 5px;
}
.vidrounded-less-top,
.rounded-top {
  border-radius: 5px 5px 0px 0px;
}
.vidrounded-top {
  border-radius: 10px 10px 0px 0px;
}
.vidrounded-bottom {
  border-radius: 0px 0px 10px 10px;
}
.vidrounded {
  border-radius: 10px 10px 10px 10px;
}
.vidrounded-extra {
  border-radius: 100px 100px 100px 100px;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  -moz-border-radius-topleft: 100px;
  border-top-left-radius: 100px;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  -moz-border-radius-topright: 100px;
  border-top-right-radius: 100px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  -moz-border-radius-bottomleft: 100px;
  border-bottom-left-radius: 100px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  -moz-border-radius-bottomright: 100px;
  border-bottom-right-radius: 100px;
}
.ui-corner-all-less,
.ui-corner-top-less,
.ui-corner-left-less,
.ui-corner-tl-less {
  -moz-border-radius-topleft: 5px;
  border-top-left-radius: 5px;
}
.ui-corner-all-less,
.ui-corner-top-less,
.ui-corner-right-less,
.ui-corner-tr-less {
  -moz-border-radius-topright: 5px;
  border-top-right-radius: 5px;
}
.ui-corner-all-less,
.ui-corner-bottom-less,
.ui-corner-left-less,
.ui-corner-bl-less {
  -moz-border-radius-bottomleft: 5px;
  border-bottom-left-radius: 5px;
}
.ui-corner-all-less,
.ui-corner-bottom-less,
.ui-corner-right-less,
.ui-corner-br-less {
  -moz-border-radius-bottomright: 5px;
  border-bottom-right-radius: 5px;
}

.rounded-v3-all, .rounded-v3-top, .rounded-v3-left, .rounded-v3-tl { -moz-border-radius-topleft: 4px; border-top-left-radius: 4px; }
.rounded-v3-all, .rounded-v3-top, .rounded-v3-right, .rounded-v3-tr { -moz-border-radius-topright: 4px; border-top-right-radius: 4px; }
.rounded-v3-all, .rounded-v3-bottom, .rounded-v3-left, .rounded-v3-bl { -moz-border-radius-bottomleft: 4px; border-bottom-left-radius: 4px; }
.rounded-v3-all, .rounded-v3-bottom, .rounded-v3-right, .rounded-v3-br { -moz-border-radius-bottomright: 4px; border-bottom-right-radius: 4px; }

/* borders */
.border-top {
  border-top: 1px solid theme(colors.neutral.300);
}

.border-all {
  border: 1px solid theme(colors.neutral.300);
}

/* Text Align */
.wrap-words {
  word-wrap: break-word; /* old name */
  overflow-wrap: break-word;
}

/* Text Styling */
a.darker {
  color: theme(colors.sky.800) !important;
}

a.no-underline:hover {
  text-decoration: none;
}

.vidcruiter-form-content p.header, .green-header-text {
  color: theme(colors.vidgreen);
  font-family: theme(fontFamily.sans);
  @apply text-xl font-bold;
}
p.smallest, span.smallest, a.smallest, th.smallest, td.smallest {
  @apply text-2xs;
}
p.smaller, span.smaller, a.smaller, th.smaller, td.smaller {
  @apply text-xs;
}
.small {
  @apply text-sm;
}
.big {
  @apply text-base;
}
.dark-help-text {
  @apply text-sky-900;
}
.gray {
  @apply text-neutral-600;
}
.dark-gray {
  @apply text-neutral-800;
}
.light-gray {
  @apply text-neutral-500;
}
.red {
  @apply text-red-800;
}
.green {
  @apply text-green-700;
}
.blue {
  @apply text-blue-500;
}
.dark-blue {
  color: #3E5E8D;
}
.black {
  color: theme(colors.black);
}
.bold {
  font-weight: bold;
}
.normal {
  font-weight: normal;
}
.uppercase {
  text-transform: uppercase;
}
.no-text-shadow {
  text-shadow: none;
}
.gray-link {
  @apply text-neutral-500 text-xs p-0.5;
}

.gray-link:hover {
  @apply bg-neutral-500 text-white no-underline;
}

.dark-gray-link {
  @apply text-neutral-800 text-xs p-0.5;
}

.dark-gray-link:hover {
  @apply bg-neutral-800 text-white no-underline;
}

.red-link {
  @apply text-red-800 text-xs p-0.5;
}

.red-link:hover {
  @apply bg-red-800 text-white no-underline;
}

.no-resize {
  resize: none;
}

/* Margins */
.margin-auto {
  margin: auto;
}
.margin-top {
  margin-top: 10px;
}

.no-margin-bottom {
  margin-bottom: 0px;
}
.no-bottom-margin, .margin-bottom-0 {
  margin-bottom: 0px;
}
.no-top-margin, .margin-top-0 {
  margin-top: 0px;
}
.no-left-margin, .margin-left-0 {
  margin-left: 0px;
}
.no-right-margin {
  margin-right: 0px;
}
.no-top-margin {
  margin-top: 0px;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-left-30 {
  margin-left: 30px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-left-15 {
  margin-left: 15px;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-right-5 {
  margin-right: 5px;
}
.margin-right-15 {
 margin-right: 15px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-right-20 {
  margin-right: 20px;
}

.margin-left-5 {
  margin-left: 5px;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.dotted-connector {
  float: left; width: 20px; height: 15px; border-left: 1px dotted theme(colors.neutral.300); border-bottom: 1px dotted theme(colors.neutral.300);
}

/* Borders */
.border-left-none {
  border-left: none;
}

.no-border { border: none; }

/* Padding */
.no-top-padding {
  padding-top: 0px;
}
.padded {
  padding: 10px !important;
}
.padded-less {
  padding: 5px !important;
}
.padded-table td {
  padding: 5px;
}
.no-left-padding {
  padding-left: 0px;
}
.padding-10 {
  padding: 10px;
}
.padding-15 {
  padding: 15px;
}
.padding-20 {
  padding: 20px;
}

.padding-30 {
  padding: 30px;
}
.padding-left-20 {
  padding-left: 20px;
}
.padding-left-30 {
  padding-left: 30px;
}
.padding-right-20 {
  padding-left: 20px;
}
.padding-right-30 {
  padding-left: 30px;
}
.padding-left-10 {
  padding-left: 10px;
}
.padding-right-20 {
  padding-right: 20px;
}
.padding-right-10 {
  padding-right: 10px;
}
.padding-right-5 {
  padding-right: 5px;
}
.padding-top-10 {
  padding-top: 10px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.padding-bottom-10 {
  padding-bottom: 10px;
}
.padding-top-5 {
  padding-top: 5px;
}
.padding-top-50 {
  padding-top: 50px;
}
.padding-bottom-0 {
  padding-bottom: 0px;
}

/* Z-Index */
.z-index-top {
  z-index: 9999999 !important;
}

/* Sizing (stadards widths used in the app) */
.width-100 {
  width: 100%;
}

.width-920 {
  width: 920px;
}

.width-960 {
  width: 960px;
}

/* Shadows */
.inner-shadow {
  box-shadow: inset 0 0 5px theme(colors.neutral.500);
}
.slight-shadow {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}
.shadow-reversed {
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1);
}
.top-shadow {
  box-shadow: 0 -4px 5px -4px rgba(0, 0, 0, 0.5);
}
.shadow-more {
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.6);
}


/* Gradients */

.gradient-v3, .box-v3-row, .force-v3-small-rows .box-v3-container.-open .box-v3-row, .v3-table tr.v3-hoverable td {
  background: theme(colors.white);
  @apply bg-gradient-to-b from-white to-neutral-100;
}

.white-box {
  border: 2px solid white;
  padding: 5px; 
  margin-right: 10px;
  background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(251,251,251,1) 49%,rgba(252,252,252,1) 50%,rgba(255,255,255,1) 100%);
  @apply shadow-xs;
}


/* Textures */
.texture-v3-paper { background-color: theme(colors.neutral.50); }

.box-sizing { /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
      box-sizing: border-box;         /* Opera/IE 8+ */

}

.number-circle {
  width: 22px;
  line-height: 22px;
  border-radius: 50%;
  text-align: center;
  font-size: theme(fontSize.sm);
  background-color: theme(colors.vidgreen);
  color: theme(colors.white);
  display: inline-block;
  font-weight: bold;
}

.number-circle.-gray {
  background-color: theme(colors.neutral.200);
  color: theme(colors.neutral.800);
}

video.flip-h, canvas.flip-h, video.pipeNormal, video.pipeSmallNormal {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.video:has(> .youtube-embed) {
  @apply flex justify-center;
}

.youtube-embed {
  aspect-ratio: 16 / 9;
  width: 100% !important;
}
