/* Place all the styles related to the people/business_processes controller here.
 * They will automatically be included in application.css.
 */

.applicant-dashboard-entry-steps .applicant-dashboard-step:last-child {
  border-bottom: none;
}

.applicant-dashboard-step {
  display: flex; align-items: center; justify-content: center; border-bottom: 1px dotted theme(colors.neutral.300); margin-bottom: 10px; padding-bottom: 10px;
}

.applicant-dashboard-step td.date-label {
  text-align: right; font-size: theme(fontSize.xs); color: theme(colors.neutral.700); font-weight: bold;
}

.applicant-dashboard-step td.date-text {
  font-size: theme(fontSize.xs); color: theme(colors.neutral.700); font-weight:normal; text-align:left;
}

@media (max-width : 768px) {
  .applicant-dashboard-step td.date-label {
    text-align: left;
  }
  .applicant-dashboard-entry .status-label {
    align-self: start;
  }

  .applicant-dashboard-step {
    flex-direction: column;
    align-items: start;
  }
}
