@import "../config/config_vidcruiter";
@import "../sources/people/business_processes";

html, body {
  color: theme(colors.neutral.700);
  font-family: theme(fontFamily.sans);
  text-rendering: optimizelegibility;
  line-height: 18px;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  /* @apply bg-stone-200; */
  background-color: #e3e1dd; /* TODO: Add this to custom TW colours */
}

html {
  font-size: 16px;
  text-size-adjust: 100%;
}

/* Only use responsive font-sizes for .backend-layout */
@media screen(xl) {
  html:has(body.backend-layout) {
    font-size: 18px;
  }
}

/*
  Alternatively, we could use responsive font sizing everywhere but disable it in specific
  areas, such as .vidcruiter-custom-design, by overriding it to use the original 16px base font
*/
/* @media screen(2xl) {
  html {
    font-size: 18px;
  }
  html:has(body.vidcruiter-custom-design) {
    font-size: 16px;
  }
} */

body#tinymce {
  /* 
   * Set default font-size for TinyMCE descriptive text fields.
   * This is mostly just for emails, as font-size is overriden and set to 16px by .vidcruiter-form
   */
  font-size: 12px;
  background-image: none !important;
  background-color: white;
  box-sizing: border-box;
}

td.first-left {
  border-left: 1px solid theme(colors.neutral.300);
}

a {
  outline: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
}

/* Links */
a:focus {
  outline: 2px solid var(--focused-color);
}
a:hover,
a:active {
  outline: 0;
}
a {
  outline-offset: 2px;
  color: theme(colors.sky.700);
  text-decoration: none;
}
a:hover {
  color: theme(colors.sky.800);
  text-decoration: underline;
}


sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  /* Responsive images (ensure images don't scale beyond their parents) */

  max-width: 100%;
  /* Part 1: Set a maxium relative to the parent */
  /* IE7-8 need help adjusting responsive images */

  height: auto;
  /* Part 2: Scale the height according to the width, otherwise you get stretching */

  vertical-align: middle;
  border: 0;
}

button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}
button,
input {
  line-height: normal;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  appearance: button;
  cursor: pointer;
}
label,
select,
button,
input[type="button"],
input[type="reset"],
input[type="submit"],
input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
}

label:has(input[type="checkbox"]:disabled) {
  cursor: not-allowed;
}

label.error, p.error-label {
  color: theme(colors.red.700);
  font-size: theme(fontSize.sm);
  @apply pb-2.5;
  &:empty {
    @apply hidden;
  }
}
input[type="search"] {
  box-sizing: content-box;
  appearance: textfield;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  appearance: none;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
@media print {
  * {
    text-shadow: none !important;
    color: theme(colors.black) !important;
    background: transparent !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid theme(colors.neutral.400);
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page  {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

[class*="span"] {
  float: left;
  min-height: 1px;
  margin-left: 20px;
}

p {
  margin: 0 0 10px;
  @apply text-sm;
}
small {
  font-size: 85%;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
cite {
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-family: inherit;
  font-weight: bold;
  line-height: 20px;
  color: inherit;
  text-rendering: optimizelegibility;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: theme(colors.neutral.400);
}
h1,
h2,
h3 {
  @apply leading-10;
}
h1 {
  font-size: theme(fontSize.4xl);
}
h2 {
  font-size: theme(fontSize.3xl);
}
h3 {
  font-size: theme(fontSize.2xl);
}
h4 {
  font-size: theme(fontSize.lg);
}
h5 {
  font-size: theme(fontSize.sm);
}
h6 {
  font-size: theme(fontSize.xs);
}
h1 small {
  font-size: theme(fontSize.2xl);
}
h2 small {
  font-size: theme(fontSize.lg);
}
h3 small {
  font-size: theme(fontSize.sm);
}
h4 small {
  font-size: theme(fontSize.sm);
}


ul,
ol {
  padding: 0;
  margin: 0 0 10px 25px;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}
li {
  @apply leading-5;
}
ul.unstyled,
ol.unstyled {
  margin-left: 0;
  list-style: none;
}
ul.inline,
ol.inline {
  margin-left: 0;
  list-style: none;
}
ul.inline  > li,
ol.inline  > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
dl {
  margin-bottom: 20px;
}
dt,
dd {
  line-height: 20px;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 10px;
}

hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid theme(colors.neutral.300);
  border-bottom: 1px solid theme(colors.white);
}
abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted theme(colors.neutral.400);
}
abbr.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
blockquote {
  padding: 0 0 0 15px;
  margin: 0 0 20px;
  border-left: 5px solid theme(colors.neutral.200);
}
blockquote p {
  margin-bottom: 0;
  font-size: theme(fontSize.base);
  font-weight: 300;
  line-height: 25px;
}
blockquote small {
  display: block;
  line-height: 20px;
  color: theme(colors.neutral.400);
}
blockquote small:before {
  content: '\2014 \00A0';
}
blockquote.pull-right {
  float: right;
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid theme(colors.neutral.200);
  border-left: 0;
}
blockquote.pull-right p,
blockquote.pull-right small {
  text-align: right;
}
blockquote.pull-right small:before {
  content: '';
}
blockquote.pull-right small:after {
  content: '\00A0 \2014';
}
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
}
address {
  display: block;
  margin-bottom: 20px;
  font-style: normal;
  line-height: 20px;
}
code,
pre {
  padding: 0 3px 2px;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: theme(fontSize.xs);
  color: theme(colors.neutral.700);
  border-radius: 3px;
}
code {
  padding: 2px 4px;
  color: theme(colors.red.600);
  background-color: theme(colors.neutral.50);
  border: 1px solid theme(colors.neutral.200);
  white-space: nowrap;
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: theme(fontSize.sm);
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
  background-color: theme(colors.neutral.100);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
pre.prettyprint {
  margin-bottom: 20px;
}
pre code {
  padding: 0;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border: 0;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
form {
  margin: 0 0 20px;
}
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  text-decoration: none;
  text-transform: none;
  color: theme(colors.neutral.800);
  font-size: theme(fontSize.sm);
  font-weight: bold;
  margin-bottom: 20px;
}

legend small {
  font-size: theme(fontSize.base);
  color: theme(colors.neutral.400);
}

label,
input,
button,
select,
textarea {
  @apply text-sm leading-normal font-normal;
}
input,
button,
select,
textarea {
  font-family: theme(fontFamily.sans);
}
label {
  display: block;
  margin-bottom: 5px;
}
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input, .editable-input {
  display: inline-block;
  /* height: 20px; */
  /* padding: 4px 6px; */
  /* margin-bottom: 10px; */
  line-height: 20px;
  color: theme(colors.neutral.600);
  border-radius: 4px;
  vertical-align: middle;
  @apply box-border text-sm px-2 py-1.5;
}
select {
  @apply py-1 pr-5 xl:pr-5 appearance-none truncate;
  background: url('icons/fas-chevron-down.svg') no-repeat;
  background-size: 0.625rem;
  background-position: right 0.5rem top 50%;
}
input,
textarea,
.uneditable-input, .editable-input {
  @apply w-[206px] xl:w-[275px];
}
textarea {
  height: auto;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input, .editable-input {
  background-color: theme(colors.white);
  border: 1px solid theme(colors.neutral.300);
  /* box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); */
  transition: border linear .2s, box-shadow linear .2s;
}
textarea:focus,
div.selected-flag:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus, .editable-input:focus {
  border-color: var(--focused-color);
  outline: 1px solid var(--focused-color);
  /* box-shadow: inset 0 1px 1px rgba(var(--focused-color),.075), 0 0 8px rgba(var(--focused-color),.8); */
}
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  /* IE7 */
  /* IE8-9 */

  line-height: normal;
}

label > input[type="radio"],
label > input[type="checkbox"] {
  margin: 0px 0 0;
}

input[type="file"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
  width: auto;
}
select,
input[type="file"] {
  /* height: 30px; */
  /* In IE7, the height of the select element cannot be changed by height, only font-size */
  /* For IE7, add top margin to align select with labels */

  line-height: 30px;
}
select {
  /* width: 220px; */
  width: 285px;
  border: 1px solid theme(colors.neutral.300);
  background-color: theme(colors.white);
}
select[multiple],
select[size] {
  height: auto;
}
select:focus,
span:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus, .custom-ui-container:focus {
  outline: 2px solid var(--focused-color);
}

.vidcruiter-form ::placeholder,
input::placeholder,
textarea::placeholder
{
  color: theme(colors.neutral.500);
}

input[class*="span"],
select[class*="span"],
textarea[class*="span"],
.uneditable-input[class*="span"] {
  float: none;
  margin-left: 0;
}
.input-append input[class*="span"],
.input-append .uneditable-input[class*="span"],
.input-prepend input[class*="span"],
.input-prepend .uneditable-input[class*="span"] {
  display: inline-block;
}
input,
textarea,
.uneditable-input {
  margin-left: 0;
}

input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  cursor: not-allowed;
  background-color: theme(colors.neutral.200);
}
input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"][readonly],
input[type="checkbox"][readonly] {
  background-color: transparent;
}
input:focus:invalid,
textarea:focus:invalid,
select:focus:invalid {
  color: theme(colors.red.700);
  border-color: theme(colors.red.500);
}
input:focus:invalid:focus,
textarea:focus:invalid:focus,
select:focus:invalid:focus {
  border-color: theme(colors.red.500);
  box-shadow: 0 0 6px theme(colors.red.200);
}
input.disable-invalid-styles:focus:invalid:focus{
  border-color: var(--focused-color);
  color: #555555;
  outline: 1px solid var(--focused-color);
  box-shadow:  inset 0 1px 1px rgba(var(--focused-color),.075), 0 0 8px rgba(var(--focused-color),.8);
}
table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}

table td[class*="span"],
table th[class*="span"] {
  display: table-cell;
  float: none;
  margin-left: 0;
}

#pjax-container {
  display: block;
}

@media print {
  a[href]:after {
    content: none !important;
  }
}
a {
  color: theme(colors.sky.700);
}
blockquote,
div.blockquote {
  background: theme(colors.neutral.100);
  border: 1px solid theme(colors.neutral.300);
  border-left: 8px solid theme(colors.neutral.300);
  border-radius: 4px;
  box-shadow: inset 1px 1px 2px 2px theme(colors.neutral.200);
  padding: 20px 10px;
}
select,
input[type="file"] {
  /* height: 28px; */
  line-height: 28px;
}
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  color: theme(colors.neutral.600);
  line-height: 19px;
  @apply text-sm;
}
.input-append,
.input-prepend {
  display: inline-block;
  margin-bottom: 0px;
}
.btn-mini .caret,
.btn-small .caret {
  margin-top: 5px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 36px;
}
h4,
h5,
h6 {
  line-height: 18px;
}
h6 {
  color: theme(colors.neutral.400);
  text-transform: uppercase;
  @apply text-sm;
}

h6.v3, .-v3-children h6 {
  text-decoration: none;
  text-transform: none;
  color: #003333; /* TODO: no matching TW color */
  @apply text-sm;
}

h6.v3-large {
  @apply text-base;
}

.bold {font-weight: bold;}
.italic {font-style: italic;}
.not-bold { font-weight: normal; }

#autoplay-not-supported a {
  text-decoration: none;
}
#autoplay-not-supported a:hover {
  text-decoration: none;
}

#autoplay-not-supported a h6 {
  color: black;
  text-decoration: underline;
}

#autoplay-not-supported a:hover h6 {
  color: green;
}

.question-page-id-checkbox {
  margin-left: 20px !important;
}

.question-page-label {
  margin-left: 10px !important;

}

.timer-expired-link, .usupported-integration-fields-link {
  text-decoration: none !important;
}

.dropdown-menu-search-filter {
  margin-top: 2px;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 0px !important;
  border-radius: 1px;
  border-color: theme(colors.neutral.400);
  background: theme(colors.white);
  width: 180px;
  padding-right: 20px !important;
}

/* use to make elements visible to screen reader only */
.sr-only-2 {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}


.vidcruiter-input .k-widget.k-datepicker {
  width: 360px;
}

.vidcruiter-input .k-widget.k-datepicker .k-picker-wrap {
  height: 30px;
}

.vidcruiter-input .k-widget.k-datepicker .k-picker-wrap > .k-input {
  /* cursor: not-allowed; */
  height: 100%;
}

.vidcruiter-input .k-widget.k-datepicker .k-picker-wrap > .k-select {
  width: 50px;
  background-color: theme(colors.gray.200);
}

.vidcruiter-input .k-widget.k-datepicker .k-picker-wrap .k-icon {
  width: 100%;
  height: 100%;
}

.field-selector-container {
  @apply text-sm;
}

.vidcruiter-input .field-calendar-container {
  display: flex; align-items: center; justify-items: center;
}

.vidcruiter-input .field-calendar-container.-small {
  flex-direction: row;
  flex-wrap: wrap;

  &._main {
    flex: 0 1 100%;
  }

  &.clear-date {
    margin-left: 0px;
  }
}

.vidcruiter-form .vidcruiter-field-date .vidcruiter-input .field-calendar-container.-small .clear-date {
  margin-left: 0px;
}

.vidcruiter-input .field-calendar-container ._main {
  display: flex; align-items: center; justify-items: center;
}

.vidcruiter-input .field-calendar-container ._main a.vidtooltip {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .vidcruiter-input .field-calendar-container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .vidcruiter-input .field-calendar-container ._main {
    flex: 0 1 100%;
  }

  .vidcruiter-input .field-calendar-container ._main a.vidtooltip {
    margin-right: 5px;
  }

  .vidcruiter-input .field-calendar-container > a.clear-date {
    margin-top: 5px;
  }

  .vidcruiter-input .k-widget.k-datepicker {
    width: 100%;
    height: 30px;
  }

  .vidcruiter-input .k-widget.k-datepicker .k-picker-wrap > .k-input {
    height: 30px;
  }
}

.calendar-dashboard-entry-more-info li:not(:last-child).-with-status {
  border-bottom: 1px solid theme(colors.neutral.300);
  padding-bottom: 5px;
}
