@import "../../config/config_vidcruiter";

html.vidRecorder-open,body.vidRecorder-open {
  touch-action: none;
}

.vidRecorder-open {
  height: 100vh;
  width: 100vw;
  margin:0;
  padding:0;
  /* required to prevent rogue scrollbars */
  overflow: hidden;

  /* This was added to prevent scrolling, and a gap at the bottom in iOS after a rotate. It seems to work as-is, but a wrapp might be needed
  See this solution -> https://stackoverflow.com/questions/62717621/white-space-at-page-bottom-after-device-rotation-in-ios-safari */
}


.fancybox-video-response .fancybox-skin {
  background-color: transparent;
  background: none;
}

.fancybox-video-response .fancybox-inner {
  box-sizing: border-box;
}

.fancybox-video-response * {
  touch-action: pan-x pan-y;
}

.responsive-recorder-ui {
  ::-webkit-scrollbar {width: 11px;}

  scrollbar-width: thin;
  scrollbar-color: theme(colors.neutral.400) theme(colors.neutral.100);

  ::-webkit-scrollbar-track {
    background: theme(colors.neutral.100);
  }

  ::-webkit-scrollbar-thumb {
    background-color: theme(colors.neutral.400);
    border-radius: 6px;
    border: 3px solid theme(colors.neutral.100);
  }
}

.responsive-recorder-ui .main {
  width: 100%; height: 100%; display: flex;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  padding-bottom: 20px;
  min-height: 0; /* Allows the content to shrink with Flex */
}

.responsive-recorder-ui .main .description-container {
  width: 100%; height: 100%; margin-right: 10px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  min-width: 0; /* Allow content to shrink with Flex */
}

#vidSegway-container .description-container {
  margin-top: 0px; margin-bottom: 10px; min-height: 0; border: 1px solid theme(colors.neutral.300); width: 100%;flex: 1;overflow-y: auto;text-align: center;display: flex;flex-direction: column;align-items: center;justify-content: center;
}

#vidSegway-container .description-container .description {
  padding-left: 30px; padding-right: 30px;
}

.responsive-recorder-ui .main .description {
  width: 100%; height: 100%; 
  overflow-y: scroll; 
  overflow-y: auto; 
  overflow-x: auto; 
  border: none; padding: 0px; margin-right: 10px;
  box-sizing: border-box;
}

.responsive-recorder-ui .vidRecorder-stats {
  display: flex; justify-content: center; align-items: center;
}

.responsive-recorder-ui .main .recorder {
  margin-right: 10px;
  display:  flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: theme(colors.zinc.700);
  border-radius: 8px;
  padding: 8px;
  height: 100%;
  height: fit-content;
}

.responsive-recorder-ui *:focus {
  outline: 2px solid var(--focused-color);
}

.responsive-recorder-ui .mediaelement-description-player {
  border: 3px solid theme(colors.white);
}

.responsive-recorder-ui .mediaelement-description-player:focus {
  border: 2px solid var(--focused-color);
  border-radius:4px;
}

.responsive-recorder-ui .OT_publisher {
  border-radius: 15px;
}

#hdfvr-content #pipeVrec-hdfvr-content video {
  border-radius: 20px !important;
}

.responsive-recorder-ui.responsive-recorder-ui--audio-only {
  #pipeSwitchCamContainer-hdfvr-content { display: none !important; }
  #pipeVideoInput-hdfvr-content { display: none !important; }
  #pipeAudioOnly-hdfvr-content { display: none !important; }
}

.video-response-button-label-large { display:initial; }
.video-response-button-label-small { display:none; }

.fancybox-lock .fancybox-overlay-fixed.fancybox-overlay-fixed--no-scrollbars {
	overflow: hidden;
}

@media (max-width : 668px) {
  .video-response-button-label-large {
    display: none;
  }
  .video-response-button-label-small {
    display: initial;
  }

  .fancybox-video-response .fancybox-skin {
    border-radius: 0px;
  }

  #vidSegway-container .description-container .description {
    padding-left: 15px; padding-right: 15px;
  }
}
#vidResponse-container .mejs__container, #vidResponse-container .mediaelement-player-container {
  border-radius: 8px;
}

#vidResponse-container.-small .responsive-recorder-ui .main {
  flex-direction: column;
}

#vidResponse-container.-small .responsive-recorder-ui .main .description-container {
  min-height: 0; /* Allow proper resizing for Flex. */
  margin-right: 0px;
  margin-bottom: 10px;
}

#vidResponse-container.-small .responsive-recorder-ui .main .recorder {
}

#vidResponse-container .recorder-mobile-menubar {
  @apply bg-white text-black pb-1;
}

#vidResponse-container .recorder-mobile-menubar--reversed {
  @apply bg-zinc-900 text-white;
}

#vidRecorder-confirmation-recorder #vidRecorder-recorder {
  margin-bottom: 36px;
}

#vidResponse-container.-small {
  @apply bg-zinc-900;
}

#vidResponse-container #vidReview-video-container {
  @apply p-2;
}

.info-chip--recorder-recording {
  z-index: 5; bottom: 12px; left: 12px;
}

.info-chip--recorder-recorder-answer {
  @apply absolute z-10 top-3 left-4;
}

#vidResponse-container.-small .info-chip--recorder-recorder-answer {
  @apply top-0 left-0;
}

#vidResponse-container.-small .info-chip--recorder-recorder-answer .info-chip {
  @apply rounded-md;
  border-bottom-left-radius: 0px; border-top-right-radius: 0px;
}

#vidResponse-container.-small .info-chip--recorder-recording {
  bottom: 0px; left: 0px; border-top-left-radius: 0px; border-bottom-right-radius: 0px;
  @apply text-xs;
}

#vidResponse-container.-small #vidReview-video-container {
  @apply p-4 bg-zinc-900;
}

#vidResponse-container.-small .responsive-recorder-ui .main .recorder .vidRecorder-stats {
  margin-bottom: auto;
  flex-grow: 1;
}

#vidResponse-container.-small .responsive-recorder-ui .main {
  padding-left: 0px;
  padding-right: 0px;
} 

#vidResponse-container.-small .responsive-recorder-ui .main .description {
  margin-right: 0px;
}

#vidResponse-container.-small .responsive-recorder-ui .main .description-container {
  border-radius: 0px;
}

#vidResponse-container.-small .responsive-recorder-ui .main .recorder #vidRecorder-controls {
  margin-top: auto;
}

.responsive-recorder-ui .-hide-large { display: none !important; }
#vidResponse-container.-small .responsive-recorder-ui .-hide-large { display: initial !important; }

.responsive-recorder-ui .-hide-small { display: initial !important; }
#vidResponse-container.-small .responsive-recorder-ui .-hide-small { display: none !important; }

.responsive-recorder-alert {
  @apply font-semibold text-left bg-yellow-100 box-border text-yellow-900 rounded-md p-1 pl-3 pr-3 mb-2 flex items-center justify-start w-full;
}

.responsive-recorder-alert .responsive-recorder-alert-icon {
  @apply text-yellow-500 mr-2 text-xl;
}

.responsive-recorder-alert.responsive-recorder-alert--error {
  @apply bg-red-100 text-red-900;
}

.responsive-recorder-alert.responsive-recorder-alert--error .responsive-recorder-alert-icon {
  @apply text-red-900;
}

.responsive-recorder-alert.responsive-recorder-alert--info {
  @apply bg-blue-100 text-blue-900;
}

.responsive-recorder-alert.responsive-recorder-alert--info .responsive-recorder-alert-icon {
  @apply text-blue-500;
}

#vidResponse-container .video-description-player-container {
  padding-left: 40px; padding-right: 40px; padding-top: 10px;
}

#vidResponse-container .video-description-player-container .mejs__mediaelement {
  @apply rounded-lg;
}

#vidResponse-container .video-description-player-container .mejs__mediaelement .mediaelementplayer-wrapper {
  @apply rounded-lg;
}

#vidResponse-container.-small .video-description-player-container {
  padding-left: 20px; padding-right: 20px; padding-top: 0px;
}

#vidcruiter-video-cam-available {z-index: 1; width: 100%; height: 100%; color: theme(colors.white);}
#hdfvr-content.-responsive { height: 100% !important; width: 100% !important; position: relative; }
#hdfvr-content.-responsive #pipeVrec-hdfvr-content { height: 100% !important; width: 100% !important; }
#hdfvr-content.-responsive #pipeVrec-hdfvr-content video { height: 100% !important; width: 100% !important; min-height: auto !important; }
#hdfvr-content.-responsive #pipeVrec-hdfvr-content #pipeMenu-hdfvr-content { height: 100% !important; width: 100% !important; }
#hdfvr-content.-responsive #pipeError-hdfvr-content { height: 100% !important; width: 100% !important; border-radius: 15px !important; }

#hdfvr-content.-responsive #pipeRecordRTC-hdfvr-content { height: 100% !important; width: 100% !important; background: none !important; background-color: none !important;  border-radius: 15px !important; }
#hdfvr-content.-responsive #pipeStartRecording-hdfvr-content { display: none !important; }

#vidcruiter-video-cam-available #hdfvr-content #pipeSwitchCamContainer-hdfvr-content {
  bottom: 10px;
  left: initial !important;
  right: 10px;
  z-index: 99999999;
}

#vidcruiter-video-cam-available #hdfvr-content #pipeSwitchCamContainer-hdfvr-content .pipeBtn {
  background-color: white;
  border-radius: 4px;
  padding: 1px 5px;
  max-height: 21px;
}

/* Remove the default Pipe mic indicator */
#hdfvr-content.-responsive #pipeMicContainer-hdfvr-content {  display: none !important; }

#vidcruiter-hide-video-overlay > div {z-index: 4; border-radius: 15px; left: 0px; top: 0px; background-image: url('transparent_black_more.png'); position: absolute;}

.vidcruiter-video-countdown > div {z-index: 6; left: 0px; top: 0px; background: invisible; position: absolute; display: flex; align-items: center; justify-content: center;}
.vidcruiter-video-countdown > div > .vidcruiter-video-countdown-container {
  width: 40%;
  border-radius: 50%;
  background: rgba(0,0,0,0.4);
  color: theme(colors.white);

  position: relative;
  display: flex;
  align-items: center;
  max-width: 10vw;


  @media (max-width : 668px) {
    width: 70%;
    max-width: 20vw;
  }
}

.vidcruiter-video-countdown > div > .vidcruiter-video-countdown-container::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.vidcruiter-video-countdown > div > .vidcruiter-video-countdown-container > .vidcruiter-video-countdown-content {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 102px;
  font-size: 5vw;
}

.vidcruiter-video-countdown > div > .vidcruiter-video-countdown-container > .vidcruiter-video-countdown-content .connecting {
  font-size: 15px;

  @media (max-width : 668px) {
    font-size: 13px;
  }
}

#vidResponse-container.-small .responsive-recorder-ui .vidcruiter-video-countdown > div > .vidcruiter-video-countdown-container > .vidcruiter-video-countdown-content {
  font-size: 60px;
  font-size: 10vw;
}

#vidResponse-container.-with-iframe .responsive-recorder-ui .main .description {
  overflow-y: hidden;
}

#vidResponse-container.-with-iframe .responsive-recorder-ui .main .description #vidDescription-content {
  display: flex;
  flex-direction: column;
}

.responsive-recorder-ui .vidRecorder-stats .stat-container {
  @apply p-4 text-white text-sm font-bold rounded-lg flex-1 mb-0 mt-1;
}

@media (max-width : 668px) {
  .responsive-recorder-ui .vidRecorder-stats .stat-container {}
}

.responsive-recorder-ui #pipeMsgOverlay-hdfvr-content {
  left: 0px !important;
  top: 0px !important;
  width: 100% !important;
}

.video-response-timer {
  display:flex;
  margin-bottom: 10px;
  color: white;
  align-items: center; justify-content: left; border-radius: 20px; padding: 3px; padding-left: 10px; padding-right: 10px;

  @apply box-border flex w-full items-center;
}

.video-response-timer .video-response-timer-content-container {
  @apply flex-grow;
}

.video-response-timer .video-response-timer-content {
  @apply text-xl font-bold;
}

.video-response-timer .video-response-timer-description {
  @apply font-normal opacity-80 text-lg; 
}

.recorder-audio-recorder-label {
  @apply mt-1 mb-3 text-3xl text-left text-white normal-case;
}

@media (max-width : 668px) {
  .video-response-timer {
    @apply flex flex-col mb-0;
  }

  .recorder-audio-recorder-label {
    @apply mt-1 mb-1 text-sm;
  }  

  .video-response-timer .video-response-timer-content {
    @apply text-sm font-normal mb-1 flex-grow;
  }

  .video-response-timer .video-response-timer-description {
    display: none;
  }

  #vidRecorder-review-timer {
    background-color: theme(colors.zinc.700);
    @apply flex-grow rounded-lg ml-1 flex w-full items-center justify-center;
  }

  #vidRecorder-timer-container {
    background-color: theme(colors.zinc.700);
    @apply flex-grow rounded-lg ml-1 flex w-full items-center justify-center;
  }

  #vidRecorder-recorder-timer-container {
    @apply flex flex-row-reverse items-center mb-4 justify-center;
  }

  .responsive-recorder-ui .main .recorder {
    @apply bg-inherit w-full pr-4 pl-4;
  }

  .responsive-recorder-ui .main {
    padding-bottom: 4px;
  }
}

.video-response-timer .countdown_row {
  display: none !important;
}

.video-response-timer span.fa-clock { font-size: 18px; color: white; }

.video-response-timer > div.content { width: 100%; font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  color: white; margin-left:5px; }

.video-response-timer > div.video-response-timer-time { border: none; box-shadow: none; color: white; background: none; padding: 0px 0px 0px 0px; font-size: 14px; }
.video-response-timer > div.progress { margin-bottom: 0px; height: 14px; border-radius: 15px; min-width: 180px; margin-left: 10px; background-color: #bbbbbb; }

.video-response-timer .progress .bar {
  background-color: theme(colors.sky.700);
  background-image:none;
}

.video-response-timer.vid-response-timer-answer-time .progress .bar {
  background-color: theme(colors.green.500);
  background-image:none;
}

.video-response-timer.vid-response-timer-segway-time .content { display: none; }
.video-response-timer.vid-response-timer-segway-time > div.progress { flex: 1; }
.video-response-timer.vid-response-timer-segway-time > div.video-response-timer-time { margin-left: 5px; }


#vidSegway-container { height: 100%; box-sizing: border-box; display: flex; width: 100%; flex-direction: column; align-items: center; justify-content: center; padding-right: 10px; padding-left: 10px;}
#vidSegway-container.hide {display: none;}

.OT_video-poster {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDcxIDQ2NCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxsaW5lYXJHcmFkaWVudCBpZD0iZ3JhZGllbnQiIHgxPSIwIiB4Mj0iMCIgeTE9IjAiIHkyPSIxIj4KICAgICAgICA8c3RvcCBvZmZzZXQ9IjY2LjY2JSIgc3RvcC1jb2xvcj0id2hpdGUiIC8+CiAgICAgICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSJ3aGl0ZSIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogICAgPHBhdGggZmlsbD0idXJsKCNncmFkaWVudCkiCiAgICAgICAgICBkPSJNNzkgMzA4YzE0LjI1LTYuNSA1NC4yNS0xOS43NSA3MS0yOSA5LTMuMjUgMjUtMjEgMjUtMjFzMy43NS0xMyAzLTIyYy0xLjc1LTYuNzUtMTUtNDMtMTUtNDMtMi41IDMtNC43NDEgMy4yNTktNyAxLTMuMjUtNy41LTIwLjUtNDQuNS0xNi01NyAxLjI1LTcuNSAxMC02IDEwLTYtMTEuMjUtMzMuNzUtOC02Ny04LTY3cy4wNzMtNy4zNDYgNi0xNWMtMy40OC42MzctOSA0LTkgNCAyLjU2My0xMS43MjcgMTUtMjEgMTUtMjEgLjE0OC0uMzEyLTEuMzIxLTEuNDU0LTEwIDEgMS41LTIuNzggMTYuNjc1LTguNjU0IDMwLTExIDMuNzg3LTkuMzYxIDEyLjc4Mi0xNy4zOTggMjItMjItMi4zNjUgMy4xMzMtMyA2LTMgNnMxNS42NDctOC4wODggNDEtNmMtMTkuNzUgMi0yNCA2LTI0IDZzNzQuNS0xMC43NSAxMDQgMzdjNy41IDkuNSAyNC43NSA1NS43NSAxMCA4OSAzLjc1LTEuNSA0LjUtNC41IDkgMSAuMjUgMTQuNzUtMTEuNSA2My0xOSA2Mi0yLjc1IDEtNC0zLTQtMy0xMC43NSAyOS41LTE0IDM4LTE0IDM4LTIgNC4yNS0zLjc1IDE4LjUtMSAyMiAxLjI1IDQuNSAyMyAyMyAyMyAyM2wxMjcgNTNjMzcgMzUgMjMgMTM1IDIzIDEzNWwtNDY4IDFzLTMtOTYuNzUgMTQtMTIwYzUuMjUtNi4yNSAyMS43NS0xOS43NSA2NS0zNnoiLz4KPC9zdmc+Cg==) !important;
}
