div.dt-container .dt-search, div.dt-container .dt-length {
    @apply flex items-center gap-2;
  
    label {
      @apply mb-0;
    }
  }
  div.dt-container .dt-info {
    @apply text-sm;
  }
  div.dt-container .dt-search input.dt-input {
    @apply w-52;
  }
  div.dt-container .dt-length select.dt-input {
    @apply w-20;
  }

/* DataTables Buttons */
#reports-view div.dt-buttons > .dt-button,
#reports-view div.dt-buttons > div.dt-button-split .dt-button,
#reports-view div.dt-container .dt-paging .dt-paging-button {
  @apply btn btn-mini btn-v3 btn-secondary-v3;
}

#reports-view div.dt-container .dt-paging .dt-paging-button {
  border: 1px solid theme(colors.white);
  border-color: transparent;
  background-color: transparent;
  @apply shadow-none;
}

#reports-view div.dt-buttons > .dt-button:hover,
#reports-view div.dt-buttons > div.dt-button-split .dt-button:hover,
#reports-view div.dt-container .dt-paging .dt-paging-button:hover,
#reports-view div.dt-container .dt-paging .dt-paging-button.current {
  text-decoration: none;
  background-position: 0 3rem;
  transition: background-position 0.1s linear;  
  background: none;
  border: 1px solid theme(colors.neutral.300);
  color: theme(colors.neutral.900);
}

table.dataTable > tbody > tr > td.details-control {
  background: url('chevron-down-solid.svg') no-repeat center center;
  cursor: pointer;
  background-size: 1rem;
}

table.dataTable > tbody > tr.shown > td.details-control {
  background: url('chevron-up-solid.svg') no-repeat center center;
  background-size: 1rem;
}

table.dataTable.hover > tbody > tr.report-details-row:hover > *, table.dataTable.display > tbody > tr.report-details-row:hover > * {
  @apply shadow-none;
}

table.dataTable > tbody > tr.report-details-row > th, table.dataTable > tbody > tr.report-details-row > td {
  @apply p-1 pb-0 bg-white shadow-none;
}

table.dataTable > tbody > tr.report-details-row > th,
table.dataTable > tbody > tr.report-details-row > td,
table.dataTable > tbody > tr.report-details-row:hover > th,
table.dataTable > tbody > tr.report-details-row:hover > td {
  @apply p-1 pb-0 bg-white shadow-none;
}

#reports-view table.dataTable > thead > tr > th {
  @apply text-xs;
}

#reports-view table.dataTable > tbody > tr > td {
  @apply text-sm;
}