/* 
	This File should include all of the Variable declarations like colours/etc.
*/

:root {
  --color-default: theme(colors.vidgreen);
  --color-default-hover: theme(colors.green.700); /* old: #418440 */

  --color-dark-gray: theme(colors.neutral.700);

  --focused-color: theme(colors.sky.500);

  --color-gray-border: theme(colors.neutral.300);

  --color-lightblue-highlight-border: #3a6581;
  --color-lightblue-highlight-text: #3a6581;
  --color-lightblue-highlight-background: #e2edf4;

  --color-background-lightgray: theme(colors.neutral.100);
  --color-current-step: theme(colors.amber.600);
}
