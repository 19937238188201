.iti .country-list .country.highlight {
  background-color: rgba(128, 128, 128, 0.4);
}

.vidcruiter-form .iti.iti--allow-dropdown input,
.vidcruiter-form .iti.iti--allow-dropdown input[type=text], 
.vidcruiter-form .iti.iti--allow-dropdown input[type=tel], 
.vidcruiter-form .iti.iti--separate-dial-code input, 
.vidcruiter-form .iti.iti--separate-dial-code input[type=text],
.vidcruiter-form form #phone_call_out_number, 
.vidcruiter-form .iti.iti--separate-dial-code input[type=tel] {
  padding-left: 52px;
  width: 358px;
  box-sizing: border-box;
  height: auto;
}
.sms-recipient-template .iti input[type=tel],
.sms-recipients-list .iti input[type=tel]{
  @apply h-8;
}

@media (max-width: 768px) {
  .vidcruiter-form .iti {
    width: 100%;
  }

  .vidcruiter-form .iti .iti__country {
    min-width: 300px;
  }

  .vidcruiter-form .iti.iti--allow-dropdown input, .vidcruiter-form .iti.iti--allow-dropdown input[type=text], .vidcruiter-form .iti.iti--allow-dropdown input[type=tel], .vidcruiter-form .iti.iti--separate-dial-code input, .vidcruiter-form .iti.iti--separate-dial-code input[type=text], .vidcruiter-form .iti.iti--separate-dial-code input[type=tel] {
    width: 100%;
    height: 31px;
  }
}
