/*
 * This is an entrypoint file that will be compiled into application.css.
 */

/* Custom CSS files */
@import "../config/config_vidcruiter";
@import "../sources/base";
@import "../sources/module";
@import "../sources/state";
@import "../sources/old_stuff";
@import "../sources/util";
@import "../sources/icons";
@import "../sources/forms";
@import "../sources/vendors/tinymce_overrides";
@import "../sources/mediaelementplayer";
@import "../sources/vidcruiter_30";
@import "../sources/components";
@import "../sources/overrides";
@import "../sources/vendors/froala_overrides";

/* Vendor CSS files */
@import "../sources/jquery.meow.css";
@import "../sources/jquery.qtip2.css";
@import "../sources/prettyLoader.css";
@import "../sources/jquery.countdown.css";
@import "../sources/icheck/square/green.css";
@import "../sources/vendors/jquery/plugins/fancybox/jquery.fancybox.css";
@import "../sources/vendors/jquery.dataTables.css";
@import "../sources/vendors/jquery.dataTables.rowGroup.css";
@import "../sources/vendors/buttons.dataTables.css";
@import "../sources/vendors/dataTables_overrides.css";
@import "../sources/vendors/jquery/jquery-filestyle.css";
@import "../sources/vendors/jquery/jquery-confirm.css";
@import "../sources/jquery-ui-1.8.24.custom.css";
@import "../sources/vendors/uppy.160.css";
@import "../sources/vendors/pipe.css";
@import "../sources/speed.css";
@import "../sources/jquery.bootstrap-touchspin.min.css";
@import "../sources/fullcalendar.min.css";
@import "../sources/vendors/intlTelInput/intlTelInput.css";
@import "../sources/vendors/tom-select/tom-select.css";
@import "../sources/webfonts.css";
@import "froala-editor/css/froala_editor.css";
@import "froala-editor/css/froala_style.css";
@import "froala-editor/css/plugins.pkgd.css";

.ui-icon {

  display: inline-block;
  vertical-align: middle;
}

.fix-css-cache {
  display: none;
}

#editor-mode-control-bar {
  @apply shadow sticky top-0 w-full bg-white text-center z-[9999] mb-5;
}

#editor-mode-control-bar.-relative {
  @apply relative !important;
  @apply mb-6;
}