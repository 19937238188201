.prettyLoader {
	position:absolute;
	z-index:999992;
}

.prettyLoaderContent {
	position: absolute;
	left: 10px;
	top: 10px;
	background: url(/images/prettyLoader.png) top left no-repeat;
	height:23px;
	width:23px;
	padding-top: 7px;
	padding-left: 7px;
}

.prettyLoaderContent img {
	display:block;
}

.pl_ie6 {
	background-image: url(/images/prettyLoader.gif);
}