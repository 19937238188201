
.bootstrap-touchspin input[type="text"].percentage-field, .vidcruiter-form .vidcruiter-field .vidcruiter-input input[type="text"].percentage-field {
  float: left;
  height: 22px;
  max-height: 22px;
  padding: 4px;
  font-weight: bold;
  width: 48px;
  font-size: theme(fontSize.base);
  margin-bottom: 0px;
}

.bootstrap-touchspin .bootstrap-touchspin-postfix, .bootstrap-touchspin .bootstrap-touchspin-prefix {
  float: left;
  display: inline-block;
  font-weight: bold;
  padding: 5px;
  padding-left: 3px; padding-right: 3px;
}

.email-edit-single-line p {
  margin-bottom: 0px;
}

.is-fullscreen .fp-logo {
  bottom: 40px !important;
}

.note-text {
  color: theme(colors.neutral.500);
}

.jfilestyle-no-margin .jfilestyle {
  margin-bottom: 0px;
}